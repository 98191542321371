import { Component, OnInit, ViewEncapsulation, Input, Inject } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, FormArray} from '@angular/forms';
import { ProdService } from '../../services/prod.service';
import { CommonService } from '../../services/common.service';
import { SyncService } from '../../services/sync.service';
import { Gallery, GalleryItem, ImageItem, ThumbnailsPosition, ImageSize } from '@ngx-gallery/core';
import { map } from 'rxjs/operators';
import { Meta, Title } from '@angular/platform-browser';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KosarbaComponent } from '../../components/kosarba/kosarba.component';
import { NumberFormatPipe } from '../../number.pipe';
import { UserService } from '../../services/user.service';
import { Location } from '@angular/common'
import { Observable } from 'rxjs';
import { ThrowStmt } from '@angular/compiler';
import { DOCUMENT } from '@angular/common';

declare let ga: Function;

@Component({
  selector: 'app-adatlap',
  templateUrl: './adatlap.component.html',
  styleUrls: ['./adatlap.component.sass', '../../components/katalogus/katalogus.component.sass'],
  encapsulation: ViewEncapsulation.None,
  providers: [NumberFormatPipe]
})
export class AdatlapComponent implements OnInit {

	kosarbaform = new FormGroup({
		darab: new FormControl('', []),
	});  
	
	params: any;
 	prod: any;
  	szavak: any;
  	gal: GalleryItem[];
  	localStorage: Storage;
	lang: any = "hu";
	adatlap: any;
	akc_afa: any;
	sima_afa: any;
	prodkat: any;
	siteurl: any;
	prodkisz: any;
	prodcsom: any;
	prodev: any;
	limit: any;
	db: any = 1;
	prodkapcs: any;
	fokep: any;
	arak: any;
	akc: any;
	seotitle: any;
	userInfo: any;
	shopcode: any;
	user_tipus: any;
	min: number = 100000;
	regi_ar: number;
	tab1: boolean = true;
	tab2: boolean = false;
	tab3: boolean = false;
	van_szuro: boolean = false;
	lap: number = 1;
	back_link: string = "";
  
  	constructor(@Inject(DOCUMENT) private document: Document, private location: Location, public user: UserService, private route: Router, private formatPipe: NumberFormatPipe, public dialog: MatDialog,private title: Title, private meta: Meta,public gallery: Gallery, public sync: SyncService, private router: ActivatedRoute,private sanitizer: DomSanitizer, private prodService: ProdService, private commonService: CommonService) {
    	this.router.paramMap.subscribe(params => {
      		this.getData();
    	});
		this.localStorage = window.localStorage;
		this.user.userInfo$.subscribe((userInfo:any) => {
			this.userInfo = userInfo;
			this.user_tipus = this.userInfo.user_tipus;
		});
	}

	back(): void {
		this.location.back()
	}
	  
	opentab(num){
		if(num === 1){
			this.tab1 = true;
			this.tab2 = false;
			this.tab3 = false;
		}
		if(num === 2){
			this.tab2 = true;
			this.tab1 = false;
			this.tab3 = false;
		}
		if(num === 3){
			this.tab3 = true;
			this.tab2 = false;
			this.tab1 = false;
		}
	}

  	get isLocalStorageSupported(): boolean {
    	return !!this.localStorage
  	}
  	ngOnInit(): void {
		this.commonService.getword(this.lang,"ft-kosarba-menny_kedv-eredetiar-ar-akcios_szalag-uj_szalag-rendelheto-nem_rendelheto-besz_alatt-uvegtol").subscribe((data: {}) => {
			this.szavak = data;
		});
		this.commonService.shopcode().subscribe((data: any) => {
			this.shopcode = data.shop_code;
			if(this.localStorage.getItem(this.shopcode+"_u") != null){
				this.userInfo = JSON.parse(this.localStorage.getItem(this.shopcode+"_u"));
				this.user_tipus = this.userInfo.users_tipus;
			}
			else{
				this.user_tipus = 'privat';
			}
			if(this.localStorage.getItem(this.shopcode+"_szuro") != null){
				this.van_szuro = true;
			}
			if(this.localStorage.getItem(this.shopcode+"_num") != null){
				this.lap = Number(this.localStorage.getItem(this.shopcode+"_num"));
			}
			this.commonService.previousUrl$.subscribe((previousUrl: string) => {
				if (previousUrl !== undefined && previousUrl !== 'undefined') {
					let prev_link = previousUrl.split("/");
					prev_link.splice(-1, 1)
					for (let i = 0; i < prev_link.length; i++) {
						if (i === 0) {
							this.back_link = prev_link[i];
						}
						else {
							this.back_link = this.back_link + "/" + prev_link[i];
						}
					}
					this.back_link = this.back_link + "/" + this.lap;
				}
				else {
					this.back_link = "/italok/abc/1";
				}
			});
		});
		this.commonService.menuroww("46",this.lang).subscribe((data: {}) => {
			this.adatlap = data[0];
		});
		this.commonService.siteurl().subscribe((data: any) => {
			this.siteurl = data.siteurl;
		});
		this.commonService.seotitle().subscribe((data: any) => {
			this.seotitle = data.title;
		});
	}
	  
	plus(){
		this.db = this.db+this.prod.termek_termekek_kisz_db;
		this.arCalc();
	}

	minus(){
		if(this.db > this.prod.termek_termekek_kisz_db && this.db > 1){
			this.db = this.db-this.prod.termek_termekek_kisz_db;
			this.arCalc();
		}
	}

	setdb(event){
		if(Number(event) > 1 && Number(event) > this.prod.termek_termekek_kisz_db){
			let div = Number(event) % this.prod.termek_termekek_kisz_db;
			let rem = Number(event) / this.prod.termek_termekek_kisz_db;
			if(div === 0){
				this.db = Number(event);
			}
			else{
				this.db = Math.round(rem) * this.prod.termek_termekek_kisz_db;
			}
		}
		else{
			this.db = this.prod.termek_termekek_kisz_db;
		}
		this.arCalc();
	}

	arCalc(){
		if(this.user_tipus === 'ceg'){
			if(this.prod.ara_ceg != '' || this.prod.ara_ceg > 0){

			}
			else{
				for(let item of this.arak){
					if(this.db >= item.db){
						if(item.db < this.min){
							this.min = item.db;
						}
						this.prod.ara_magan = item.ar;
						this.sima_afa = this.prodService.netto(this.prod.ara_magan);
					}
					if(this.db < this.min){
						this.prod.ara_magan = this.regi_ar;
						this.sima_afa = this.prodService.netto(this.prod.ara_magan);
					}
					let kisz = 0;
					if (typeof this.prod.termek_termekek_kisz != 'number') {
						kisz = this.prod.termek_termekek_kisz.replace(",",'.');
					}
					else{
						kisz = this.prod.termek_termekek_kisz;
					}
					kisz = kisz * 100;
					let egyseg = this.prod.ara_magan / kisz;
					this.prod.egysegar = Math.round(egyseg * 100);
				}
			}
		}
	}

	kosarba(id,title,img,cikkszam,ara_magan,ara_ceg,lak_ar,lak_akcio){
		this.dialog.open(KosarbaComponent, {
			data: {
				title: title,
				img: img,
				id: id,
				darab: this.db,
				users_tipus: this.user_tipus,
				cikkszam: cikkszam,
				ara_magan: ara_magan,
				ara_ceg: ara_ceg,
				lak_ar: lak_ar,
				lak_akcio: lak_akcio
			}
		});
	}

  	withCustomGalleryConfig() {

    	// 2. Get a lightbox gallery ref
    	const lightboxGalleryRef = this.gallery.ref('anotherLightbox');

    	// (Optional) Set custom gallery config to this lightbox
   		lightboxGalleryRef.setConfig({
     		imageSize: ImageSize.Cover,
			thumbPosition: ThumbnailsPosition.Top
    	});

    	// 3. Load the items into the lightbox
    	lightboxGalleryRef.load(this.gal);
  	}

  	getData() {
    	this.router.paramMap.subscribe((params:any) => {
      		this.params = params.params;
		});
		this.commonService.shopcode().subscribe((data: any) => {
			this.shopcode = data.shop_code;
			if(this.localStorage.getItem(this.shopcode+"_u") != null){
				this.userInfo = JSON.parse(this.localStorage.getItem(this.shopcode+"_u"));
				this.user_tipus = this.userInfo.users_tipus;
			}
			else{
				this.user_tipus = 'privat';
			}
			this.prodService.prodrow(this.params.id,this.lang, this.user_tipus).subscribe((data: any) => {
				this.prod = data[0];
				if(this.prod.letezik === "true"){
					this.db = this.prod.termek_termekek_kisz_db;
					if(this.prod.termek_termekek_limit === 1){
						this.limit = "igen";
					}
					else{
						this.limit = "nem";
					}
					if(this.user_tipus === 'ceg'){
						if(this.prod.ara_ceg != '' || this.prod.ara_ceg > 0){
							this.akc = Math.round((1 - (this.prod.ara_ceg / this.prod.ara_magan)) * 100);
							this.regi_ar = this.prod.ara_ceg;
						}
						else{
							this.regi_ar = this.prod.ara_magan;
						}
						this.akc_afa = this.prodService.netto(this.prod.ara_ceg);
						this.sima_afa = this.prodService.netto(this.prod.ara_magan);
					}
					if(this.user_tipus === 'privat'){
						if(this.prod.lak_akcio != '' || this.prod.lak_akcio > 0){
							this.akc = Math.round((1 - (this.prod.lak_akcio / this.prod.lak_ar)) * 100);
							this.regi_ar = this.prod.lak_akcio;
						}
						else{
							this.regi_ar = this.prod.lak_ar;
						}
						this.akc_afa = this.prodService.netto(this.prod.lak_akcio);
						this.sima_afa = this.prodService.netto(this.prod.lak_ar);
					}
					this.prodService.prodgal(this.prod.termek_termekek_id,this.lang).subscribe((data: any) => {
						this.gal = data.map((item:any) =>
							new ImageItem({ src: item.img, thumb: item.img })
						);
						let img = this.gal[0].data.src.split("/");
						this.fokep = img[4];
						let kepnev = this.siteurl+this.gal[0].data.src;
						//let url = this.siteurl+this.adatlap.alias+"/"+this.prod.termek_alias;
						let url = this.document.location.href;
						this.commonService.seo(this.seotitle,'website',this.prod.termek_termekek_nev,this.prod.meta_desc,url,kepnev);
						this.commonService.schema("adatlap","hu",this.prod.termek_alias).subscribe((data2: any) => {
							this.commonService.insertSchema(data2);
						});
						this.withCustomGalleryConfig();
					});

					this.prodService.prodcsom(this.prod.termek_termekek_id).subscribe((data: any) => {
						this.prodcsom = data;
					});
					this.prodService.prodkisz(this.prod.termek_termekek_id).subscribe((data: any) => {
						this.prodkisz = data;	
					});
					this.prodService.prodev(this.prod.termek_termekek_id).subscribe((data: any) => {
						this.prodev = data;	
					});	
					this.prodService.prodkapcs(this.prod.termek_termekek_id, this.lang).subscribe((data: any) => {
						this.prodkapcs = data;	
					});	
					this.prodService.prodkat(this.prod.termek_termekek_id, this.lang).subscribe((data: any) => {
						this.prodkat = data;	
						/*ga('ecommerce:addItem', {
							'id': this.prod.termek_termekek_id,                     // Transaction ID. Required.
							'name': this.prod.termek_termekek_nev,    // Product name. Required.
							'sku': this.prod.cikkszam,                 // SKU/code.
							'category': this.prodkat.szint1_title,         // Category or variation.
							'price': this.regi_ar,                 // Unit price.
							'quantity': 1                   // Quantity.
						});
						ga('ecommerce:send');*/
					});	
					if(this.user_tipus === 'ceg'){
						this.prodService.prodar(this.prod.termek_termekek_id).subscribe((data: any) => {
							this.arak = data;	
						});	
					}
					else{
						this.arak = false;
					}
				}
				else{
					//this.route.navigate(["/404"]);
				}
			});
		});
	}
}
