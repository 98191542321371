import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ModuleWithProviders, ANALYZE_FOR_ENTRY_COMPONENTS } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { DynamicHTMLModule } from './dynamic-html'
import { Ng5SliderModule } from 'ng5-slider';

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { HomeComponent } from './components/home/home.component';
import { FooterComponent } from './components/footer/footer.component';
import { SlideComponent } from './components/slide/slide.component';
import { KatlistComponent } from './components/katlist/katlist.component';
import { KeresComponent } from './components/keres/keres.component';
import { ProdlistComponent } from './components/prodlist/prodlist.component';
import { HirlevelComponent } from './components/hirlevel/hirlevel.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { CikkComponent } from './components/cikk/cikk.component';
import { AccordionComponent } from './components/accordion/accordion.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { KapcsolatComponent } from './components/kapcsolat/kapcsolat.component';
import { KatalogusComponent } from './components/katalogus/katalogus.component';
import { AdatlapComponent } from './components/adatlap/adatlap.component';
import { KosarComponent } from './components/kosar/kosar.component';
import { CartComponent } from './components/cart/cart.component';

import { SlickCarouselModule } from 'ngx-slick-carousel';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatDialogModule} from '@angular/material/dialog';
import { AgmCoreModule } from '@agm/core';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { GalleryModule } from '@ngx-gallery/core';
import { LightboxModule } from  '@ngx-gallery/lightbox';
import 'hammerjs';
import { SearchComponent } from './components/search/search.component';
import { BlogComponent } from './components/blog/blog.component';
import { BlogcikkComponent } from './components/blogcikk/blogcikk.component';
import { BlogmodulComponent } from './components/blogmodul/blogmodul.component';
import { CimkesingleComponent } from './components/cimkesingle/cimkesingle.component';
import { SzuroComponent } from './components/szuro/szuro.component';
import { StripTagsPipe } from './strip.pipe';
import { NyitoComponent } from './components/nyito/nyito.component';
import { RegComponent } from './components/reg/reg.component';
import { LoginComponent } from './components/login/login.component';
import { JwPaginationModule } from 'jw-angular-pagination';
import { PaginationComponent } from './components/pagination/pagination.component';
import { AdatmodComponent } from './components/adatmod/adatmod.component';
import { NewpwComponent } from './components/newpw/newpw.component';
import { KosarbaComponent } from './components/kosarba/kosarba.component';
import { PenztarComponent } from './components/penztar/penztar.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxMatNativeDateModule, NgxMatDateAdapter, NgxMatNativeDateAdapter } from '@angular-material-components/datetime-picker';
import { MAT_DATE_LOCALE, DateAdapter } from '@angular/material/core';
import { CustomDateAdapter } from './custom-date-adapter';
import { NumberFormatPipe }     from './number.pipe';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { LostpwComponent } from './components/lostpw/lostpw.component';
import { EgyedikeresekComponent } from './components/egyedikeresek/egyedikeresek.component';
import { CookieService } from 'ngx-cookie-service';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    FooterComponent,
    SlideComponent,
    KatlistComponent,
    KeresComponent,
    ProdlistComponent,
    HirlevelComponent,
    DialogComponent,
    CikkComponent,
    AccordionComponent,
    GalleryComponent,
    KapcsolatComponent,
    KatalogusComponent,
    AdatlapComponent,
    KosarComponent,
    CartComponent,
    SearchComponent,
    BlogComponent,
    BlogcikkComponent,
    BlogmodulComponent,
    CimkesingleComponent,
	SzuroComponent,
	StripTagsPipe,
	NyitoComponent,
	RegComponent,
	LoginComponent,
	PaginationComponent,
	AdatmodComponent,
	NewpwComponent,
	KosarbaComponent,
	PenztarComponent,
	NumberFormatPipe,
	NotfoundComponent,
	LostpwComponent,
	EgyedikeresekComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SlickCarouselModule,
    Ng2SearchPipeModule,
	FormsModule,
	Ng5SliderModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
	MatDialogModule,
	JwPaginationModule,
	NgxMaskModule.forRoot(),
    DynamicHTMLModule.forRoot({
      components: [
        { component: AccordionComponent, selector: 'app-accordion' },
        { component: GalleryComponent, selector: 'app-gallery' },
      ]
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAqSqn7CiAUtwOHgoFYPLwD3ZmeSA-amqw'
    }),
    RecaptchaModule,
    RecaptchaFormsModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    GalleryModule,
	LightboxModule,
	MatNativeDateModule,
	NgxMatDatetimePickerModule,
	NgxMatTimepickerModule,
	MatDatepickerModule,
	NgxMatNativeDateModule
  ],
  providers: [
	  StripTagsPipe,
	  CookieService, 
	  MatDatepickerModule, 
	  MatNativeDateModule, 
	  NgxMatDatetimePickerModule, 
	  NgxMatTimepickerModule,
	  NumberFormatPipe,
	  { provide: MAT_DATE_LOCALE, useValue: 'hu' },
	  {provide: NgxMatDateAdapter, useClass: CustomDateAdapter }
	],
	exports: [ NumberFormatPipe],
  bootstrap: [AppComponent]
})
export class AppModule { }

