<span class="kosarba_check icon-check-1">
	A kosárba sikeresen belekerült(ek) a következő termék(ek):
	<mat-dialog-actions align="right">
		<button mat-button mat-dialog-close>X</button>
	</mat-dialog-actions>
</span>
<div class="kosarba_info">
	<div class="left">
		<picture *ngIf="data.title">
			<source type="image/webp" srcset="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/product/{{data.img}}.webp">
			<source type="image/jpg" srcset="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/product/{{data.img}}">
			<img src="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/product/{{data.img}}" alt="{{data.title}}"/>
		</picture>
		<span *ngIf="data.title"><b><i>{{data.title}}</i></b></span>
	</div>
	<div class="right">
		<mat-dialog-actions>
			<button (click)="tovabb_kosar()" mat-button mat-dialog-close *ngIf="kosar_menu" routerLink="/{{kosar_menu.alias}}"><span>Tovább a kosárhoz</span></button>
			<button (click)="tovabb_kosar2()"  mat-button mat-dialog-close><span>Tovább válogatok</span></button>
		</mat-dialog-actions>
	</div>
</div>