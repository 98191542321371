import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { CikkComponent } from './components/cikk/cikk.component';
import { KapcsolatComponent } from './components/kapcsolat/kapcsolat.component';
import { KatalogusComponent } from './components/katalogus/katalogus.component';
import { AdatlapComponent } from './components/adatlap/adatlap.component';
import { KosarComponent } from './components/kosar/kosar.component';
import { SearchComponent } from './components/search/search.component';
import { BlogComponent } from './components/blog/blog.component';
import { BlogcikkComponent } from './components/blogcikk/blogcikk.component';
import { CimkesingleComponent } from './components/cimkesingle/cimkesingle.component';
import { RegComponent } from './components/reg/reg.component';
import { LoginComponent } from './components/login/login.component';
import { AdatmodComponent } from './components/adatmod/adatmod.component';
import { NewpwComponent } from './components/newpw/newpw.component';
import { LostpwComponent } from './components/lostpw/lostpw.component';
import { PenztarComponent } from './components/penztar/penztar.component';
import { NotfoundComponent } from './components/notfound/notfound.component'
import { EgyedikeresekComponent } from './components/egyedikeresek/egyedikeresek.component'

const routes: Routes = [
  { path: '', component: HomeComponent, data: { home: 'true', szuro: 'false' } },
  { path: 'test', component: CikkComponent, data: { home: 'false', szuro: 'false' } },
  { path: 'kapcsolat', component: KapcsolatComponent, data: { home: 'false', szuro: 'false' } },
  { path: 'italok', component: KatalogusComponent, data: { home: 'false', szuro: 'true' } },
  { path: 'italok/:fokat', component: KatalogusComponent, data: { home: 'false', szuro: 'true' } },
  { path: 'italok/:fokat/:alkat', component: KatalogusComponent, data: { home: 'false', szuro: 'true' } },
  { path: 'italok/:fokat/:alkat/:order', component: KatalogusComponent, data: { home: 'false', szuro: 'true' } },
  { path: 'italok/:fokat/:alkat/:order/:lap', component: KatalogusComponent, data: { home: 'false', szuro: 'true' } },
  { path: 'shop', component: KatalogusComponent, data: { home: 'false', szuro: 'false' } },
  { path: 'adataim', component: AdatmodComponent, data: { home: 'false', szuro: 'false' } },
  { path: 'uj-jelszo', component: NewpwComponent, data: { home: 'false', szuro: 'false' } },
  { path: 'elfelejtett-jelszo', component: LostpwComponent, data: { home: 'false', szuro: 'false' } },
  { path: 'shop/:fokat', component: KatalogusComponent, data: { home: 'false', szuro: 'false' } },
  { path: 'shop/:fokat/:id', component: AdatlapComponent, data: { home: 'false', szuro: 'true' } },
  { path: 'blog', component: BlogComponent, data: { home: 'false', szuro: 'false' } },
  { path: 'cimke', component: BlogComponent, data: { home: 'false', szuro: 'false' } },
  { path: 'bejelentkezes', component: LoginComponent, data: { home: 'false', szuro: 'false' } },
  { path: 'penztar', component: KosarComponent, data: { home: 'false', szuro: 'false' } },
  { path: 'kosar', component: PenztarComponent, data: { home: 'false', szuro: 'false' } },
  { path: 'regisztracio', component: RegComponent, data: { home: 'false', szuro: 'false' } },
  { path: 'regisztracio/:id', component: RegComponent, data: { home: 'false', szuro: 'false' } },
  { path:'blog/:id', component: BlogcikkComponent, data: { home: 'false', szuro: 'false' }},
  { path:'cimke/:id', component: CimkesingleComponent, data: { home: 'false', szuro: 'false' }},
  { path: 'koszonjuk-megrendeleset', component: CikkComponent, data: { home: 'false', szuro: 'false' }},
  { path: 'koszono-oldal', component: CikkComponent, data: { home: 'false', szuro: 'false' }},
  { path: 'egyedi-keresek', component: EgyedikeresekComponent, data: { home: 'false', szuro: 'false' }},
  //{ path: '404', component: CikkComponent, data: { home: 'false', szuro: 'false' }},
  { path: '**', component: CikkComponent, data: { home: 'false', szuro: 'false' }}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
