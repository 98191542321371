<app-nyito *ngIf="nyito_van == true"></app-nyito>
<div *ngIf="cookie_pop == true" class="cookie_pop">
	<dynamic-html class="cookie_text" *ngIf="cookie_text" [content]="cookie_text.text"></dynamic-html>
	<div class="cookie_close" (click)="cookie_close()">Elfogadom</div>
</div>
<div *ngIf="home === true && pop_closed === false && popup" class="popup">
	<div class="cont">
		<span class="popup_x" (click)="pop_close()">x</span>
		<dynamic-html class="pop_text" *ngIf="popup" [content]="popup.text"></dynamic-html>
	</div>
</div>
<div class="header">
	<a class="logo" routerLink="/">
		<picture>
			<source width="153" height="64" type="image/webp" srcset="vision/cms/uploaded_images/main/logo.webp?v=31536000‬">
			<source width="153" height="64" type="image/png" srcset="vision/cms/uploaded_images/main/logo.png?v=31536000‬">
			<img width="153" height="64" src="vision/cms/uploaded_images/main/logo.png?v=31536000‬" alt="Tassi és Társa Kft. - Ital Kis- és Nagykereskedés"/>
		</picture>
	</a>
	<dynamic-html class="header2" *ngIf="header2" [content]="header2.text"></dynamic-html>
	<a *ngIf="kosar" class="kosar_link icon-cart" routerLink="/{{kosar.alias}}">
		<b *ngIf="kosar_ossz > 0">{{kosar_ossz}}</b>
		<span>{{kosar.title}}</span>
	</a>
	<div *ngIf="!userInfo" class="pc links">
		<a *ngIf="login" routerLink="/{{login.alias}}"><span>{{login.title}}</span></a>
		<a *ngIf="reg" routerLink="/{{reg.alias}}"><span>{{reg.title}}</span></a>
	</div>
	<div *ngIf="userInfo" class="pc logged_in links">
		Üdv, <b *ngIf="user_tipus === 'ceg' ">{{userInfo.users_cegnev1}}!</b> <b *ngIf="user_tipus === 'privat' ">{{userInfo.users_vnev1}} {{userInfo.users_knev1}}!</b>
		<div class="logged_links">
			<a *ngIf="adataim" routerLink="/{{adataim.alias}}"><span>{{adataim.title}}</span></a>
			<a (click)="logout()"><span>Kijelentkezés</span></a>
		</div>
	</div>
	<form [formGroup]="szuro" class="headsearch_modul" (ngSubmit)="headSearchSubmit()">
		<input *ngIf="szavak && home === true" type="text" lass="nonfilters" (click)="nev_ures()" formControlName="nev" required placeholder="{{szavak[1].cim}}"/>
		<input *ngIf="szavak && home === false" type="text" class="filters" (click)="nev_ures()" formControlName="nev" (ngModelChange)="onfilter()" required placeholder="{{szavak[1].cim}}"/>
		<button type="submit" name="headsearch_kuld" class="headsearch_kuld icon-search" value="1"></button>
	</form>
	<div class="menu">  
		<input #menucheck (click)="open()" type="checkbox"/>
		<div class="open">
			<span></span>
			<span></span>
			<span></span>
		</div>
		<div class="pc soc">
			<a *ngIf="face" href="{{face.facebook}}" target="_blank" class="face icon-facebook-5" rel="noopener"></a>
			<a *ngIf="insta" href="{{insta.instagram_cim}}" target="_blank" class="insta icon-instagram" rel="noopener"><span>#TimeToDrink</span></a>
		</div>
		<div class="menucont">
			<div class="mobil menutop">
				<div *ngIf="userInfo" class="logged_in links">
					Üdv, <b>{{userInfo.users_username}}!</b>
					<div class="logged_links">
						<a *ngIf="adataim" routerLink="/{{adataim.alias}}"><span>{{adataim.title}}</span></a>
						<a (click)="logout()"><span>Kijelentkezés</span></a>
					</div>
				</div>
				<div class="soc">
					<a *ngIf="face" href="{{face.facebook}}" target="_blank" class="face icon-facebook-5" rel="noopener"></a>
					<a *ngIf="insta" href="{{insta.instagram_cim}}" target="_blank" class="insta icon-instagram" rel="noopener"><span>#TimeToDrink</span></a>
				</div>
				<!--<a *ngIf="kosar" class="kosar_link icon-cart" routerLink="/{{kosar.alias}}">
					<b *ngIf="kosar_ossz > 0">{{kosar_ossz}}</b>
					<span>{{kosar.title}}</span>
				</a>!-->
				<div *ngIf="!userInfo" class="links">
					<a *ngIf="login" routerLink="/{{login.alias}}"><span>{{login.title}}</span></a>
					<a *ngIf="reg" routerLink="/{{reg.alias}}"><span>{{reg.title}}</span></a>
				</div>
			</div>
			<ul *ngIf="menu">
				<li *ngFor="let row of menu;">
					<input (click)="openSubMenu($event)" name="subOpen" [value]="row.id" [checked]="row.selected" *ngIf="row.sub_menus.length > 0" type="checkbox">
					<a *ngIf="row.target != ''" rel="noopener" target="{{row.target}}" href="{{row.alias}}"><span>{{row.title}}</span></a>
					<a *ngIf="row.target == '' && row.alias != 'italok'" routerLink="/{{row.alias}}"><span>{{row.title}}</span></a>
					<a *ngIf="row.alias == 'italok'" routerLink="/{{row.alias}}/abc/1"><span>{{row.title}}</span></a>
				</li>
			</ul>
		</div>
	</div>
</div>