
<div class="footer">
	<div class="cont">
		<div class="soc">
			<a *ngIf="face"href="{{face.facebook}}" target="_blank" class="icon-facebook-5" rel="noopener"></a>
			<a *ngIf="insta" href="{{insta.instagram_cim}}" target="_blank" class="icon-instagram" rel="noopener"></a>
		</div>
		<ul *ngIf="menu" class="fmenu">
			<li *ngFor="let row of menu;">
				<a *ngIf="row.target != ''" rel="noopener" target="{{row.target}}" href="{{row.alias}}"><span>{{row.title}}</span></a>
				<a *ngIf="row.target == ''" routerLink="/{{row.alias}}"><span>{{row.title}}</span></a>
			</li>
		</ul>
		<div class="right">
			<dynamic-html class="footer1" *ngIf="footer1" [content]="footer1.text"></dynamic-html>
			<a class="telep_link" routerLink="/{{telep.alias}}" *ngIf="telep">{{telep.title}}</a>
		</div>
	</div>	
</div>
<span *ngIf="szavak" class="footer_copy">{{szavak[0].cim}}</span>
<div *ngIf="scrolled" (click)="toTop()" class="scrolled"><i class="fa fa-angle-up" aria-hidden="true"></i></div>

