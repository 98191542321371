/**
 * @Pipe
 * @description pipe to format numeric values to argentina readable currency values
 * @input number
 * @output formatted numeric value
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ 
    name: 'numberFormat'
 })
export class NumberFormatPipe implements PipeTransform {
    transform(value: any): number {
        return this.localeString(value);
    }

    localeString(nStr) {
		let x = nStr.replaceAll(',','.');
        return x;
    }
}