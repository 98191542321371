import { Component, OnInit, ViewEncapsulation, Input  } from '@angular/core';
import { AccordionService } from '../../services/accordion.service';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class AccordionComponent implements OnInit {

  @Input() accid: any;
  acc: any;
  
  constructor(private accordionService: AccordionService) { }

  ngOnInit(): void {
    this.accordionService.getaccordion(this.accid).subscribe((data: {}) => {
      this.acc = data;
      console.log(this.acc);
    });
  }
}