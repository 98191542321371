import { Injectable, Directive } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import{Constants } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  	constructor(private http: HttpClient) {}

  	public extractData(res: Response) {
		let body = res;
		return body || { };
  	}

  	public getblog(id,limit,lang): Observable<any> {
		return this.http.get(Constants.endpoint+'blog/getblog/'+id+"/"+limit+'/'+lang).pipe(
		map(this.extractData));
  	}

  	public cimkeblog(id,lang): Observable<any> {
		return this.http.get(Constants.endpoint+'blog/cimkeblog/'+id+'/'+lang).pipe(
		map(this.extractData));
  	}

  	public getblogcikk(id,lang): Observable<any> {
		return this.http.get(Constants.endpoint+'blog/getblogcikk/'+id+'/'+lang).pipe(
		map(this.extractData));
  	}

  	public cimke(id,lang): Observable<any> {
		return this.http.get(Constants.endpoint+'blog/cimke/'+id+'/'+lang).pipe(
		map(this.extractData));
  	}

  	public cimkesingle(id,lang): Observable<any> {
		return this.http.get(Constants.endpoint+'blog/cimkesingle/'+id+'/'+lang).pipe(
		map(this.extractData));
  	}

}
