<ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
    <div ngxSlickItem *ngFor="let item of slide" class="slide">
        <picture>
            <source type="image/webp" srcset="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/slideshow/{{item.kep}}.webp">
            <source type="image/jpg" srcset="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/slideshow/{{item.kep}}">
            <img width="1064" height="377" src="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/slideshow/{{item.kep}}" alt="{{item.alt}}"/>
        </picture>
    </div>
</ngx-slick-carousel>
<picture class="slide_mobil">
	<source type="image/webp" srcset="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/slide_mobil/{{slide_mobil}}.webp">
	<source type="image/jpg" srcset="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/slide_mobil/{{slide_mobil}}">
	<img src="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/slide_mobil/{{slide_mobil}}" alt="Tassi slide mobil kep"/>
</picture>