<div [class.nyitoHide] = "nyitoSubmit === true" class="nyito">
	<div class="cont">
		<picture class="logo">
			<source width="153" height="64" type="image/webp" srcset="vision/cms/uploaded_images/main/logo.webp?v=31536000‬">
			<source width="153" height="64" type="image/png" srcset="vision/cms/uploaded_images/main/logo.png?v=31536000‬">
			<img width="153" height="64" src="vision/cms/uploaded_images/main/logo.png?v=31536000‬" alt="Palace corners"/>
		</picture>
		<div class="input">
			<div (click)="csekk1()" [class.csekked] = "elmult === true" class="csekk icon-check-1"></div>
			<span>Elmúltam 18 éves</span> 
		</div>
		<div class="input">
			<div (click)="csekk2()" [class.csekked] = "elmult === false" class="csekk icon-check-1"></div>
			<span>Nem múltam el 18 éves</span> 
		</div>
		<div (click)="submit()" class="kapcs_kuld"><span>Belépés</span></div>
	</div>
	<picture class="nyitobg">
		<source width="153" height="64" type="image/webp" srcset="vision/cms/uploaded_images/main/nyito.webp?v=31536000‬">
		<source width="153" height="64" type="image/png" srcset="vision/cms/uploaded_images/main/logo.png?v=31536000‬">
		<img width="153" height="64" src="vision/cms/uploaded_images/main/logo.png?v=31536000‬" alt="Palace corners"/>
	</picture>
</div>
