import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, FormArray} from '@angular/forms';
import { Router} from '@angular/router';
import { CommonService } from '../../services/common.service';
import { KatService } from '../../services/kat.service';
import { ProdService } from '../../services/prod.service';
import { Options, LabelType } from 'ng5-slider';
import { SyncService } from '../../services/sync.service';
import { ThrowStmt } from '@angular/compiler';

@Component({
  	selector: 'app-szuro',
  	templateUrl: './szuro.component.html',
  	styleUrls: ['./szuro.component.sass'],
  	encapsulation: ViewEncapsulation.None
})
export class SzuroComponent implements OnInit {

	constructor(public sync: SyncService, private prodService: ProdService, private katService: KatService, private router:Router, private commonService: CommonService) {
		this.localStorage = window.localStorage;
	} 
	szuroaktiv: boolean = false;
	
	m_szuro_open(){
		if(this.szuroaktiv === false){
			this.szuroaktiv = true;
		}
		else{
			this.szuroaktiv = false;
		}
	}
	
	lang: any = "hu";
	shopcode: any;
	szavak: any; 
	termek: any;
	katList: any = [];
	localStorage: any;
	evlist: any;
	csomlist: any;
	prodlength: any;
	kiszlist: any;
	price: number = 0;
  	highPrice: number = 2999990;
  	priceOptions: Options = {
    	floor: 0,
    	ceil: 2999990,
    	step: 100,
		showTicks: false,
		translate: (value: number, label: LabelType): string => {
			switch (label) {
			  case LabelType.Low:
				return this.formatter.format(value).replace(/\D00(?=\D*$)/, '');
			  case LabelType.High:
				return this.formatter.format(value).replace(/\D00(?=\D*$)/, '');
			  default:
				return this.formatter.format(value).replace(/\D00(?=\D*$)/, '');
			}
		}
	};

	formatter = new Intl.NumberFormat('hu-HU', {
		style: 'currency',
		currency: 'HUF',
	});
	  
	kor: number = 0;
  	highKor: number = 100;
  	korOptions: Options = {
    	floor: 0,
    	ceil: 100,
    	step: 1,
		showTicks: false,
		translate: (value: number, label: LabelType): string => {
			switch (label) {
			  case LabelType.Low:
				return value + ' év';
			  case LabelType.High:
				return value + ' év';
			  default:
				return value + ' év';
			}
		}
	};
	  
	fok: number = 0;
  	highFok: number = 100;
  	fokOptions: Options = {
    	floor: 0,
    	ceil: 100,
    	step: 1,
		showTicks: false,
		translate: (value: number, label: LabelType): string => {
			switch (label) {
			  case LabelType.Low:
				return value + ' %';
			  case LabelType.High:
				return value + ' %';
			  default:
				return value + ' %';
			}
		}
	};
	  
	drop_open(event){
		
	}

	szuro_kesz: any = false;
	szuro = new FormGroup({
		nev: new FormControl('', []),
		ar: new FormControl([0,2999990]),
		er_ido: new FormControl([0,100]),
		alk_fok: new FormControl([0,100]),
		limit: new FormControl('', []),
		from: new FormControl([0]),
		order: new FormControl("abc"),
		selectedCsom: new FormControl('',[]),
		selectedKisz: new FormControl('',[]),
		selectedEvjarat: new FormControl('',[])
	});
	createFormInputs() {
		this.prodService.prodlength().subscribe((data: any) => {
			this.prodlength = data.leng;
			this.szuro.addControl('evjarat',this.createCheck(this.evlist));
			this.szuro.addControl('csom',this.createCheck(this.csomlist));
			this.szuro.addControl('kisz',this.createCheck(this.kiszlist));
			this.szuro.addControl('prodlength',new FormControl([this.prodlength]));
			this.szuro_kesz = true;
			this.commonService.shopcode().subscribe((data: any) => {
				this.shopcode = data.shop_code;
				if(this.localStorage.getItem(this.shopcode+"_szuro") != null){
					this.szuro_store = JSON.parse(this.localStorage.getItem(this.shopcode+"_szuro"));

					//csomagolasok
					if(this.szuro_store.selectedCsom.length > 0){
						let selectedCsom = this.szuro_store.selectedCsom.filter(function(elem, index, self) {
							return index === self.indexOf(elem);
						})
						this.szuro.controls.selectedCsom.setValue(selectedCsom);
						for(let kat of selectedCsom){
							let el = <HTMLInputElement> document.getElementById("csom"+kat);
							el.checked = true;
							el.setAttribute("checked",'true');
							el.setAttribute('class','focheck checked');
						}
					}

					//kiszerelesek
					if(this.szuro_store.selectedKisz.length > 0){
						let selectedKisz = this.szuro_store.selectedKisz.filter(function(elem, index, self) {
							return index === self.indexOf(elem);
						})
						this.szuro.controls.selectedKisz.setValue(selectedKisz);
						for(let kat of selectedKisz){
							let el = <HTMLInputElement> document.getElementById("kisz"+kat);
							el.checked = true;
							el.setAttribute("checked",'true');
							el.setAttribute('class','focheck checked');
						}
					}

					//evjaratok
					if(this.szuro_store.selectedEvjarat.length > 0){
						let selectedEvjarat = this.szuro_store.selectedEvjarat.filter(function(elem, index, self) {
							return index === self.indexOf(elem);
						})
						this.szuro.controls.selectedEvjarat.setValue(selectedEvjarat);
						for(let kat of selectedEvjarat){
							let el = <HTMLInputElement> document.getElementById("kisz"+kat);
							el.checked = true;
							el.setAttribute("checked",'true');
							el.setAttribute('class','focheck checked');
						}
					}
				}
			});
		});
	}

	createCheck(list) {
		const arr = list.map(list => {
		  return new FormControl(list.selected || false);
		});
		return new FormArray(arr);
	}

	szuro_null(){
		this.szuro.reset({alk_fok: [0,100], er_ido: [0,100], ar: [0,2999990]});
		this.localStorage.removeItem(this.shopcode+"_szuro");
		this.router.navigate([this.termek.alias+"/abc/1"]).then(() => {
			//window.location.reload();
		});
	}

	onfilter(){
		//evjarat
		let selectedEvjarat = Array();
		if(this.szuro.controls.selectedEvjarat.value != null){
			for(let kat of this.szuro.controls.selectedEvjarat.value){
				selectedEvjarat.push(kat);
			}
		}
		let j = 0;
		for (let e of this.szuro.controls.evjarat['controls']) {
			if(e.value === true){
				selectedEvjarat.push(this.evlist[j].id);
			}
			j++;
		}

		selectedEvjarat = selectedEvjarat.filter(function(elem, index, self) {
			return index === self.indexOf(elem);
		});

		if(this.szuro.contains('selectedEvjarat')){
			this.szuro.controls.selectedEvjarat.setValue(selectedEvjarat);
		}
		else{
			this.szuro.addControl('selectedEvjarat',new FormControl(selectedEvjarat));
		}

		//Csomagolas
		let selectedCsom = Array();
		if(this.szuro.controls.selectedCsom.value != null){
			for(let kat of this.szuro.controls.selectedCsom.value){
				selectedCsom.push(kat);
			}
		}
		let k = 0;
		for (let e of this.szuro.controls.csom['controls']) {
			if(e.value === true){
				selectedCsom.push(this.csomlist[k].id);
			}
			k++;
		}

		selectedCsom = selectedCsom.filter(function(elem, index, self) {
			return index === self.indexOf(elem);
		});

		if(this.szuro.contains('selectedCsom')){
			this.szuro.controls.selectedCsom.setValue(selectedCsom);
		}
		else{
			this.szuro.addControl('selectedCsom',new FormControl(selectedCsom));
		}

		//kiszerelés
		let selectedKisz = Array();
		if(this.szuro.controls.selectedKisz.value != null){
			for(let kat of this.szuro.controls.selectedKisz.value){
				selectedKisz.push(kat);
			}
		}
		let l = 0;
		for (let e of this.szuro.controls.kisz['controls']) {
			if(e.value === true){
				selectedKisz.push(this.kiszlist[l].id);
			}
			l++;
		}

		selectedKisz = selectedKisz.filter(function(elem, index, self) {
			return index === self.indexOf(elem);
		});

		if(this.szuro.contains('selectedKisz')){
			this.szuro.controls.selectedKisz.setValue(selectedKisz);
		}
		else{
			this.szuro.addControl('selectedKisz',new FormControl(selectedKisz));
		}
		//console.log(this.szuro.value);
		this.sync.set_thread_id(this.szuro.value);
		let szuro_string = btoa(unescape(encodeURIComponent(JSON.stringify(this.szuro.value))));
		this.localStorage.removeItem(this.shopcode+"_szuro");
		this.localStorage.setItem(this.shopcode+"_szuro", JSON.stringify(this.szuro.value));
		//console.log(this.szuro.value);
	}

	onSubmit() {
		this.localStorage.removeItem(this.shopcode+"_szuro");
		this.localStorage.setItem(this.shopcode+"_szuro", JSON.stringify(this.szuro.value));
		this.sync.set_thread_id(this.szuro.value);
		this.router.navigate([this.termek.alias+"/abc/1"], { state: { szuro_submit: true } });
	}

	szuro_store: any;

	removeFilter(){
		this.localStorage.removeItem(this.shopcode+"_szuro");
		//this.szuro.reset();
		this.sync.set_thread_id(this.szuro.value);
	}

  	ngOnInit(): void {
		//this.localStorage.removeItem(this.shopcode+"_szuro");
		this.commonService.shopcode().subscribe((data: any) => {
			this.shopcode = data.shop_code;
			if(this.localStorage.getItem(this.shopcode+"_szuro") != null){
				let formItem = JSON.parse(this.localStorage.getItem(this.shopcode+"_szuro"));
				this.szuro.patchValue({
					nev: formItem.nev,
					ar: formItem.ar,
					kisz: formItem.kisz,
					evjarat: formItem.evjarat,
					csom: formItem.csom,
					er_ido: formItem.er_ido,
					alk_fok: formItem.alk_fok,
					limit: formItem.limit,
					selectedEvjarat: formItem.selectedEvjarat,
					selectedCsom: formItem.selectedCsom,
					selectedKisz: formItem.selectedKisz
				});
			}
		});
		this.commonService.shopcode().subscribe((data: any) => {
			this.shopcode = data.shop_code;
		});
		this.commonService.menuroww("17",this.lang).subscribe((data: {}) => {
			this.termek = data[0];
		});
		this.commonService.getword(this.lang,"kotelezo22-keres10-kereses-ar-pricemin-pricemax-kisz-ev-csom-erido-fok-limitkiad-szuronull").subscribe((data: {}) => {
			this.szavak = data;
		});
		this.katService.katlist(0,this.lang).subscribe((data: {}) => {
			this.katList = data;
			this.prodService.evlist().subscribe((data: {}) => {
				this.evlist = data;
				this.prodService.csomlist().subscribe((data: {}) => {
					this.csomlist = data;
					this.prodService.kiszlist().subscribe((data: {}) => {
						this.kiszlist = data;
						this.createFormInputs(); 
					});
				}); 
			});
		});
	}
}
