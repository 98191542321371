import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { CommonService } from './services/common.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit{
	
	fragment: any;

	constructor(private vp: ViewportScroller, private route: ActivatedRoute, private router: Router, private meta: Meta, private commonService: CommonService) {
	
	}

	async delay(ms: number) {
		await new Promise(resolve => setTimeout(()=>resolve(), ms)).then(any=>{
			this.vp.scrollToAnchor(this.fragment);
		});
	}

  	ngOnInit(): void {
		this.commonService.robots().subscribe((data: any) => {
			this.meta.addTags([
				{ name: 'robots', content: data.robots }
			]);
		});
    	this.meta.addTags([
      		{ name: 'dc.language', content: "hu" }
		]);
		this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
			}
			/*this.commonService.bbclone().subscribe((data: any) => {
			});*/
			this.fragment = this.route.snapshot.fragment;
			if(this.fragment === undefined){
				window.scrollTo(0, 0)
			}
			else{
				this.delay(500);
			}
        });
  	}

}
