<div *ngIf="loading" class="load">
	<img width="80" src="vision/cms/uploaded_images/main/loading.gif?v=31536000‬" alt="Tassi és Társa Kft. - Ital Kis- és Nagykereskedés megrendelés küldés"/>
</div>
<form [formGroup]="regform" class="reg" *ngIf="szavak" (ngSubmit)="onSubmit()">
	<app-penztar [final]="true"></app-penztar>
	<div class="penz_form kosar_reg_form" *ngIf="!userInfo">
		<div class="box">
			<div>
				<b>A megrendeléshez jelentkezz be!</b>
				<a class="loglink" *ngIf="login" routerLink="/{{login.alias}}"><span>{{login.title}}</span></a>
			</div>
		</div>
		<div class="box">
			<div>
				<b>Még nem vásároltál nálunk?</b>
				<a class="reglink" *ngIf="reg" routerLink="/{{reg.alias}}"><span>{{reg.title}}</span></a>
			</div>
		</div>
		<br/><br/><br/>
	</div>
	<div class="penz_form" [class.deny]= "mod_deny" [class.deny2]= "mod_deny2" *ngIf="userInfo">
		<h2 *ngIf="szavak1">{{szavak1[0].cim}}</h2>
		<div class="reg_cont">
			<div *ngIf="alias === 'maganszemely'" class="form-group col-xs-12 col-sm-6 col-md-6">
				<div *ngIf="submitted && f.veznev.errors" class="invalid-feedback">
					<div *ngIf="f.veznev.errors.required">{{szavak[3].cim}} {{szavak[2].cim}}</div>
				</div>
				<input *ngIf="szavak" type="text" formControlName="veznev" required placeholder="{{szavak[3].cim}}*"/>
				<label *ngIf="szavak">{{szavak[3].cim}}*</label>
			</div>
			<div *ngIf="alias === 'maganszemely'" class="form-group col-xs-12 col-sm-6 col-md-6">
				<div *ngIf="submitted && f.kernev.errors" class="invalid-feedback">
					<div *ngIf="f.kernev.errors.required">{{szavak[4].cim}} {{szavak[2].cim}}</div>
				</div>
				<input *ngIf="szavak" type="text" formControlName="kernev" required placeholder="{{szavak[4].cim}}*"/>
				<label *ngIf="szavak">{{szavak[4].cim}}*</label>
			</div>
			<div *ngIf="alias === 'viszontelado'" class="form-group col-xs-12 col-sm-6 col-md-6">
				<div *ngIf="submitted && f.cegnev.errors" class="invalid-feedback">
					<div *ngIf="f.cegnev.errors.required">{{szavak[5].cim}} {{szavak[2].cim}}</div>
				</div>
				<input *ngIf="szavak" type="text" formControlName="cegnev" id="cegnev" required placeholder="{{szavak[5].cim}}*"/>
				<label *ngIf="szavak">{{szavak[5].cim}}*</label>
			</div>
			<div *ngIf="alias === 'viszontelado'" class="form-group col-xs-12 col-sm-6 col-md-6">
				<div *ngIf="submitted && f.kapcs.errors" class="invalid-feedback">
					<div *ngIf="f.kapcs.errors.required">{{szavak[6].cim}} {{szavak[2].cim}}</div>
				</div>
				<input *ngIf="szavak" type="text" formControlName="kapcs" required placeholder="{{szavak[6].cim}}*"/>
				<label *ngIf="szavak">{{szavak[6].cim}}*</label>
			</div>
			<div class="form-group col-xs-12 col-sm-6 col-md-6">
				<div *ngIf="submitted && f.tel.errors" class="invalid-feedback">
					<div *ngIf="f.tel.errors.required">{{szavak[7].cim}} {{szavak[2].cim}}</div>
				</div>
				<input *ngIf="szavak" type="text" formControlName="tel" required placeholder="{{szavak[7].cim}}*"/>
				<label *ngIf="szavak">{{szavak[7].cim}}*</label>
			</div>
			<div class="form-group col-xs-12 col-sm-6 col-md-6">
				<div *ngIf="!submitted && f.email.errors && f.email.touched" class="invalid-feedback">
					<div *ngIf="f.email.errors.required">{{szavak[2].cim}}</div>
					<div *ngIf="f.email.errors.email">{{szavak[11].cim}} {{szavak[8].cim}}</div>
				</div>
				<input *ngIf="szavak" type="email" formControlName="email" required placeholder="{{szavak[8].cim}}*"/>
				<label *ngIf="szavak">{{szavak[8].cim}}*</label>
			</div>
			<div *ngIf="alias === 'viszontelado'" class="form-group col-xs-12 col-sm-12 col-md-12">
				<div *ngIf="submitted && f.adoszam.errors" class="invalid-feedback">
					<div *ngIf="f.adoszam.errors.required">{{szavak[15].cim}} {{szavak[2].cim}}</div>
				</div>
				<input *ngIf="szavak" type="text" formControlName="adoszam" required placeholder="{{szavak[15].cim}}*"/>
				<label *ngIf="szavak">{{szavak[15].cim}}*</label>
			</div>
			<br/><br/><br/>
			<h2 *ngIf="szavak">{{szavak[18].cim}}</h2>
			<div *ngIf="alias === 'maganszemely'" class="form-group col-xs-12 col-sm-6 col-md-6">
				<div *ngIf="submitted && f.szam_veznev.errors" class="invalid-feedback">
					<div *ngIf="f.szam_veznev.errors.required">{{szavak[3].cim}} {{szavak[2].cim}}</div>
				</div>
				<input *ngIf="szavak" type="text" formControlName="szam_veznev" required placeholder="{{szavak[3].cim}}*"/>
				<label *ngIf="szavak">{{szavak[3].cim}}*</label>
			</div>
			<div *ngIf="alias === 'maganszemely'" class="form-group col-xs-12 col-sm-6 col-md-6">
				<div *ngIf="submitted && f.szam_kernev.errors" class="invalid-feedback">
					<div *ngIf="f.szam_kernev.errors.required">{{szavak[4].cim}} {{szavak[2].cim}}</div>
				</div>
				<input *ngIf="szavak" type="text" formControlName="szam_kernev" required placeholder="{{szavak[4].cim}}*"/>
				<label *ngIf="szavak">{{szavak[4].cim}}*</label>
			</div>
			<div *ngIf="alias === 'viszontelado'" class="form-group col-xs-12 col-sm-6 col-md-6">
				<div *ngIf="submitted && f.szam_cegnev.errors" class="invalid-feedback">
					<div *ngIf="f.szam_cegnev.errors.required">{{szavak[5].cim}} {{szavak[2].cim}}</div>
				</div>
				<input *ngIf="szavak" type="text" formControlName="szam_cegnev" id="szam_cegnev" required placeholder="{{szavak[5].cim}}*"/>
				<label *ngIf="szavak">{{szavak[5].cim}}*</label>
			</div>
			<div *ngIf="alias === 'viszontelado'" class="form-group col-xs-12 col-sm-6 col-md-6">
				<div *ngIf="submitted && f.szam_ado.errors" class="invalid-feedback">
					<div *ngIf="f.szam_ado.errors.required">{{szavak[15].cim}} {{szavak[2].cim}}</div>
				</div>
				<input *ngIf="szavak" type="text" formControlName="szam_ado" required placeholder="{{szavak[15].cim}}*"/>
				<label *ngIf="szavak">{{szavak[15].cim}}*</label>
			</div>
			<div class="cim form-group col-xs-12 col-sm-6 col-md-6">
				<div class="form-group col-xs-12 col-sm-4 col-md-4">
					<div *ngIf="submitted && f.szam_irsz.errors" class="invalid-feedback">
						<div *ngIf="f.szam_irsz.errors.required">{{szavak[19].cim}} {{szavak[2].cim}}</div>
					</div>
					<input *ngIf="szavak" type="text" formControlName="szam_irsz" id="szam_irsz" required placeholder="{{szavak[19].cim}}*"/>
					<label *ngIf="szavak">{{szavak[19].cim}}*</label>
				</div>
				<div class="form-group col-xs-12 col-sm-8 col-md-8">
					<div *ngIf="submitted && f.szam_varos.errors" class="invalid-feedback">
						<div *ngIf="f.szam_varos.errors.required">{{szavak[20].cim}} {{szavak[2].cim}}</div>
					</div>
					<input *ngIf="szavak" type="text" formControlName="szam_varos" id="szam_varos" required placeholder="{{szavak[20].cim}}*"/>
					<label *ngIf="szavak">{{szavak[20].cim}}*</label>
				</div>
			</div>
			<div class="form-group col-xs-12 col-sm-6 col-md-6">
				<div *ngIf="submitted && f.szam_utca.errors" class="invalid-feedback">
					<div *ngIf="f.szam_utca.errors.required">{{szavak[21].cim}} {{szavak[2].cim}}</div>
				</div>
				<input *ngIf="szavak" type="text" formControlName="szam_utca" id="szam_utca" required placeholder="{{szavak[21].cim}}*"/>
				<label *ngIf="szavak">{{szavak[21].cim}}*</label>
			</div>
			<br/><br/><br/>
			<h2 *ngIf="szavak">{{szavak[22].cim}}</h2>
			<div *ngIf="alias === 'maganszemely'" class="magan_szall_cont">
				<div class="form-group col-xs-12 col-sm-6 col-md-6">
					<div *ngIf="submitted && f.szall_veznev.errors" class="invalid-feedback">
						<div *ngIf="f.szall_veznev.errors.required">{{szavak[3].cim}} {{szavak[2].cim}}</div>
					</div>
					<input *ngIf="szavak" type="text" formControlName="szall_veznev" required placeholder="{{szavak[3].cim}}*"/>
					<label *ngIf="szavak">{{szavak[3].cim}}*</label>
				</div>
				<div class="form-group col-xs-12 col-sm-6 col-md-6">
					<div *ngIf="submitted && f.szall_kernev.errors" class="invalid-feedback">
						<div *ngIf="f.szall_kernev.errors.required">{{szavak[4].cim}} {{szavak[2].cim}}</div>
					</div>
					<input *ngIf="szavak" type="text" formControlName="szall_kernev" required placeholder="{{szavak[4].cim}}*"/>
					<label *ngIf="szavak">{{szavak[4].cim}}*</label>
				</div>
				<div class="cim form-group col-xs-12 col-sm-6 col-md-6">
					<div class="form-group col-xs-12 col-sm-4 col-md-4">
						<div *ngIf="submitted && f.szall_irsz.errors" class="invalid-feedback">
							<div *ngIf="f.szall_irsz.errors.required">{{szavak[19].cim}} {{szavak[2].cim}}</div>
						</div>
						<input *ngIf="szavak" type="text" formControlName="szall_irsz" id="szall_irsz" required placeholder="{{szavak[19].cim}}*"/>
						<label *ngIf="szavak">{{szavak[19].cim}}*</label>
					</div>
					<div class="form-group col-xs-12 col-sm-8 col-md-8">
						<div *ngIf="submitted && f.szall_varos.errors" class="invalid-feedback">
							<div *ngIf="f.szall_varos.errors.required">{{szavak[20].cim}} {{szavak[2].cim}}</div>
						</div>
						<input *ngIf="szavak" type="text" formControlName="szall_varos" id="szall_varos" required placeholder="{{szavak[20].cim}}*"/>
						<label *ngIf="szavak">{{szavak[20].cim}}*</label>
					</div>
				</div>
				<div class="form-group col-xs-12 col-sm-6 col-md-6">
					<div *ngIf="submitted && f.szall_utca.errors" class="invalid-feedback">
						<div *ngIf="f.szall_utca.errors.required">{{szavak[21].cim}} {{szavak[2].cim}}</div>
					</div>
					<input *ngIf="szavak" type="text" formControlName="szall_utca" id="szall_utca" required placeholder="{{szavak[21].cim}}*"/>
					<label *ngIf="szavak">{{szavak[21].cim}}*</label>
				</div>
			</div>
			<div *ngIf="alias === 'viszontelado'" class="ceg_szall_cont">
				<mat-form-field *ngIf="foxpost === false" appearance="fill">
					<mat-label>Válasszon szállítási címet</mat-label>
					<mat-select *ngIf="foxpost === false" (ngModelChange)="cegSzall()" required id="ceg_szall_cim" formControlName="ceg_szall_cim">
					<mat-select *ngIf="foxpost === true" (ngModelChange)="cegSzall()" id="ceg_szall_cim" formControlName="ceg_szall_cim"></mat-select>
						<mat-option *ngIf="userInfo.szall_1_varos != ''" value="1">
							{{userInfo.szall_1_varos}} {{userInfo.szall_1_irsz}} {{userInfo.szall_1_utca}}
						</mat-option>
						<mat-option *ngIf="userInfo.szall_2_varos != ''" value="2">
							{{userInfo.szall_2_varos}} {{userInfo.szall_2_irsz}} {{userInfo.szall_2_utca}}
						</mat-option>
						<mat-option *ngIf="userInfo.szall_3_varos != ''" value="3">
							{{userInfo.szall_3_varos}} {{userInfo.szall_3_irsz}} {{userInfo.szall_3_utca}}
						</mat-option>
						<mat-option *ngIf="userInfo.szall_4_varos != ''" value="4">
							{{userInfo.szall_4_varos}} {{userInfo.szall_4_irsz}} {{userInfo.szall_4_utca}}
						</mat-option>
						<mat-option *ngIf="userInfo.szall_5_varos != ''" value="5">
							{{userInfo.szall_5_varos}} {{userInfo.szall_5_irsz}} {{userInfo.szall_5_utca}}
						</mat-option>
					</mat-select>
				</mat-form-field>
				<div class="dotted_sep"></div>
				<div [class.hide]="ceg_szall_hide === true" class="selected_szall_cont">
					<div class="form-group col-xs-12 col-sm-12 col-md-12">
						<div *ngIf="submitted && f.szall_cegnev.errors" class="invalid-feedback">
							<div *ngIf="f.szall_cegnev.errors.required">{{szavak[5].cim}} {{szavak[2].cim}}</div>
						</div>
						<input *ngIf="szavak" type="text" required formControlName="szall_cegnev" id="szall_cegnev" placeholder="{{szavak[5].cim}}*"/>
						<label *ngIf="szavak">{{szavak[5].cim}}*</label>
					</div>
					<div class="cim form-group col-xs-12 col-sm-6 col-md-6">
						<div class="form-group col-xs-12 col-sm-4 col-md-4">
							<div *ngIf="submitted && f.szall_irsz.errors" class="invalid-feedback">
								<div *ngIf="f.szall_irsz.errors.required">{{szavak[19].cim}} {{szavak[2].cim}}</div>
							</div>
							<input *ngIf="szavak" type="text" required formControlName="szall_irsz" id="szall_irsz" placeholder="{{szavak[19].cim}}*"/>
							<label *ngIf="szavak">{{szavak[19].cim}}*</label>
						</div>
						<div class="form-group col-xs-12 col-sm-8 col-md-8">
							<div *ngIf="submitted && f.szall_varos.errors" class="invalid-feedback">
								<div *ngIf="f.szall_varos.errors.required">{{szavak[20].cim}} {{szavak[2].cim}}</div>
							</div>
							<input *ngIf="szavak" type="text" required formControlName="szall_varos" id="szall_varos" placeholder="{{szavak[20].cim}}*"/>
							<label *ngIf="szavak">{{szavak[20].cim}}*</label>
						</div>
					</div>
					<div class="form-group col-xs-12 col-sm-6 col-md-6">
						<div *ngIf="submitted && f.szall_utca.errors" class="invalid-feedback">
							<div *ngIf="f.szall_utca.errors.required">{{szavak[21].cim}} {{szavak[2].cim}}</div>
						</div>
						<input *ngIf="szavak" type="text" required formControlName="szall_utca" id="szall_utca" placeholder="{{szavak[21].cim}}*"/>
						<label *ngIf="szavak">{{szavak[21].cim}}*</label>
					</div>
				</div>
			</div>
			<br/><br/>
			<div *ngIf="fizmodlist" [class.viszontfiz]="alias === 'viszontelado'"class="fiz_szall_cont">
				<h2 *ngIf="szavak1">{{szavak1[4].cim}}</h2>
				<div *ngFor="let item of fizmodlist" [class.csakEloreUtalas]="csakEloreUtalas === true && (item.id != 3)"  class="fizmod radio_szall checkbox col-xs-12 col-sm-12 col-md-12">
					<div class="check">
						<input type="radio" (click)="checkFizmod(item.id)" id="{{item.id}}" class="fizmod" formControlName="fizmod" value="{{item.id}}" />
						<span class="icon-check-1"></span>
					</div>
					<div class="fiz_szall_text">
						<span class="label_text" [innerHTML]="item.nev"></span>
					</div>
				</div>
				<div *ngIf="regform.controls.szall_utalas.value === 1" class="radio_szall checkbox col-xs-12 col-sm-12 col-md-12">
					<div class="check">
						<input type="radio" formControlName="fizmod" value="6" />
						<span class="icon-check-1"></span>
					</div>
					<div class="fiz_szall_text">
						<span class="label_text">Utólagos utalás lehetséges: {{regform.controls.szall_utalas_nap.value}} napra</span>
					</div>
				</div>
			</div>	
			<br/><br/><br/>
			<div *ngIf="szallmodlist" class="fiz_szall_cont szall_div">
				<div class="fiz_szall_cont">
					<h2 *ngIf="szavak1">{{szavak1[5].cim}}</h2>
					<div *ngFor="let item of szallmodlist" [class.disallowFoxpost]="allowFoxpost === false && ((item.id === 9 || item.id === '9' || item.id === 10 || item.id === '10')) " 
						class="radio_szall checkbox col-xs-12 col-sm-12 col-md-12">
						<div class="check">
							<input  type="radio" id="{{item.id}}" class="szallmod" (click)="checkSzall(item.ar, item.id)" formControlName="szallmod" value="{{item.id}}" />
							<span class="icon-check-1"></span>
						</div>
						<div class="fiz_szall_text">
							<span [innerHTML]="item.nev" class="label_text">
								
							</span>
							<label class="szall_leiras">{{item.leiras}}</label>
						</div>
						<div *ngIf="(item.id === 9 || item.id === '9')" [class.foxpostHide]="foxpost === false" class="foxpostCont">
							<br/>
							<iframe frameborder="0" loading="lazy" src="https://cdn.foxpost.hu/apt-finder/v1/app/?discount=1" width="100%" height="800px"></iframe>
						</div>
					</div>
				</div>
			</div>
			<br/><br/><br/>
			<div *ngIf="szall_ki === true  && szavak1" class="ceg_naptar">
				<h2>{{szavak1[1].cim}}</h2>
				<span>{{szavak1[2].cim}}</span>
				<div class="date_select">
					<mat-form-field>
						<input matInput formControlName="szall_time" (ngModelChange)="datePrice()" [ngxMatDatetimePicker]="picker" placeholder="{{szavak1[3].cim}}"
						   [min]="minDate" [max]="maxDate" >
						<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
						<ngx-mat-datetime-picker [disableMinute]="true" [hideTime]="true" #picker [dateClass]="setClass()" [showSpinners]="true" [showSeconds]="false"
						   [stepHour]="1" [defaultTime]="[9, 30, 00]" [stepMinute]="30"
						   [touchUi]="false" [enableMeridian]="false" 
						   [disableMinute]="false" [hideTime]="true">
						</ngx-mat-datetime-picker>
					 </mat-form-field> 
				</div>
				<br/><br/><br/>
			</div>
			<p *ngIf="szavak" class="form-group textarea-grup col-xs-12 col-sm-12 col-md-12">
				<textarea placeholder="Megjegyzés" formControlName="megjegyzes" class="form-control"></textarea>
			</p>
			<br/><br/><br/>
			<div class="kosar_ossz">
				<span *ngIf="szavak1">
					{{szavak1[6].cim}} <b>{{ossz | number:'1.0-3' | numberFormat}} <span>{{szavak1[8].cim}}</span></b>
				</span>
				<span *ngIf="szavak1">
					{{szavak1[7].cim}}
					<b>{{szall_ar | number:'1.0-3' | numberFormat}} <span>{{szavak1[8].cim}}</span></b>
				</span>
				<span *ngIf="szavak1">
					{{szavak1[9].cim}}
					<b>{{vegossz | number:'1.0-3' | numberFormat}} <span>{{szavak1[8].cim}}</span></b>
				</span>
			</div>
			<div class="aszf_group">
				<div class="checkbox col-xs-12 col-sm-12 col-md-12">
					<div class="check">
						<input required type="checkbox" [ngClass]="{'invalidated': submitted}" required formControlName="any" class="radio" value="1">
						<span class="icon-check-1"></span>
					</div>
					<label *ngIf="szavak && adat_ny" class="label-text">{{szavak[25].cim}} <a routerLink="/{{adat_ny.alias}}">{{adat_ny.title}}ot*</a></label>
				</div>
				<div class="checkbox col-xs-12 col-sm-12 col-md-12">
					<div class="check">
						<input required type="checkbox" [ngClass]="{'invalidated': submitted}" required formControlName="aszf" class="radio" value="1">
						<span class="icon-check-1"></span>
					</div>
					<label *ngIf="szavak && aszf" class="label-text">{{szavak[25].cim}} <a routerLink="/{{aszf.alias}}">{{aszf.title}}et*</a></label>
				</div>
				<div class="checkbox col-xs-12 col-sm-12 col-md-12">
					<div class="check">
						<input type="checkbox" [ngClass]="{'invalidated': submitted}" formControlName="hozzaj" class="radio" value="1">
						<span class="icon-check-1"></span>
					</div>
					<dynamic-html *ngIf="hozza" class="label-text hozza_text" [content]="hozza.text"></dynamic-html>
				</div>
			</div>
			<button type="submit" name="kapcs_kuld" *ngIf="szavak && loading == false" class="kapcs_kuld" value="{{szavak[25].cim}}"><span>Megrendelés</span></button>
			<br/><br/><br/>
		</div>
	</div>
</form>













































<!--
<div class="kosar_wrapper">
	<h1 *ngIf="szavak" class="cikk_title">// {{szavak[7].cim}}</h1>
	<div class="content_kosar">
        <form [formGroup]="kosarform" class="penztar" (ngSubmit)="onSubmit()">
            <div *ngIf="kosar" id="kosar_lista">
                <div class="kosar_top">
                    <span *ngIf="szavak" class="vasar">{{szavak[0].cim}}</span>
                    <span *ngIf="szavak" class="brutto">{{szavak[1].cim}}</span>
                    <span *ngIf="szavak" class="mennyiseg">{{szavak[2].cim}}</span>
                    <span *ngIf="szavak" class="osszesen">{{szavak[3].cim}}</span>
                    <span *ngIf="szavak" class="torles">{{szavak[4].cim}}</span>
                </div>
                <div *ngFor="let item of kosar" class="prod_cart_row">
                    <div class="termek_neve">
                        <div class="kosar_gal">
                            <picture>
                                <source type="image/webp" srcset="{{item.fokep}}.webp">
                                <source type="image/jpg" srcset="{{item.fokep}}">
                                <img src="{{item.fokep}}" alt="{{item.nev}}"/>
                            </picture>
                        </div>
                        <span><b>{{item.nev}} {{item.meret}}</b></span>
                    </div>
                    <div class="termek_brutto">{{item.ar | number:'1.0-3'}} <span *ngIf="szavak">{{szavak[5].cim}}</span></div>
                    <div class="kosar_db">
                        <button (click)="minus(item.id,item.meret)" type="button">-</button>
                        <input type="text" class="spinner-content" value="{{item.darab}}" />
                        <button (click)="plus(item.id,item.meret)" type="button">+</button>
                    </div>
                    <div class="termek_row_ara"><span class="current_vegossz_ar">{{item.ar * item.darab | number:'1.0-3'}}</span> <span *ngIf="szavak"> {{szavak[5].cim}}</span></div>
                    <div class="btn remove-btn" (click)="remove(item.id,item.meret)"><i class="fa fa-times" aria-hidden="true"></i></div>
                </div>
                <span *ngIf="szavak" class="vegossz">{{szavak[6].cim}} 
                    <b>
                        <span class="vegossz_ar top">{{vegossz | number:'1.0-3'}}</span> 
                        <span *ngIf="szavak"> {{szavak[5].cim}}</span>
                    </b>
                </span>
            </div>
            <div class="input-groups kosar">
				<div class="input-groups-cont">
                    <h2 *ngIf="szavak">{{szavak[10].cim}}</h2>
                    <div class="form-group col-xs-12 col-sm-6 col-md-6">
                        <div *ngIf="submitted && f.veznev1.errors" class="invalid-feedback">
                            <div *ngIf="f.veznev1.errors.required">{{szavak[12].cim}} {{szavak[11].cim}}</div>
                        </div>
                        <input *ngIf="szavak" type="text" formControlName="veznev1" required placeholder="{{szavak[12].cim}}"/>
                        <label *ngIf="szavak">{{szavak[12].cim}}</label>
                    </div>
                    <div class="form-group col-xs-12 col-sm-6 col-md-6">
                        <div *ngIf="submitted && f.kernev1.errors" class="invalid-feedback">
                            <div *ngIf="f.kernev1.errors.required">{{szavak[13].cim}} {{szavak[11].cim}}</div>
                        </div>
                        <input *ngIf="szavak" type="text" formControlName="kernev1" required placeholder="{{szavak[13].cim}}"/>
                        <label *ngIf="szavak">{{szavak[13].cim}}</label>
                    </div>
                    <div class="form-group col-xs-12 col-sm-6 col-md-6">
                        <div *ngIf="submitted && f.mobil1.errors" class="invalid-feedback">
                            <div *ngIf="f.mobil1.errors.required">{{szavak[14].cim}} {{szavak[11].cim}}</div>
                        </div>
                        <input *ngIf="szavak" type="text" formControlName="mobil1" required placeholder="{{szavak[14].cim}}"/>
                        <label *ngIf="szavak">{{szavak[14].cim}}</label>
                    </div>
                    <div class="form-group col-xs-12 col-sm-6 col-md-6">
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">{{szavak[15].cim}} {{szavak[11].cim}}</div>
                            <div *ngIf="f.email.errors.email">{{szavak[15].cim}} {{szavak[16].cim}}</div>
                        </div>
                        <input *ngIf="szavak" type="email" formControlName="email" required placeholder="{{szavak[15].cim}}"/>
                        <label *ngIf="szavak">{{szavak[15].cim}}</label>
                    </div>
                    <div class="form-group col-xs-12 col-sm-6 col-md-6">
                        <div *ngIf="submitted && f.cegnev1.errors" class="invalid-feedback">
                            <div *ngIf="f.cegnev1.errors.required">{{szavak[17].cim}} {{szavak[11].cim}}</div>
                        </div>
                        <input *ngIf="szavak" type="text" formControlName="cegnev1" required placeholder="{{szavak[17].cim}}"/>
                        <label *ngIf="szavak">{{szavak[17].cim}}</label>
                    </div>
                    <div class="form-group col-xs-12 col-sm-6 col-md-6">
                        <div *ngIf="submitted && f.ado1.errors" class="invalid-feedback">
                            <div *ngIf="f.ado1.errors.required">{{szavak[18].cim}} {{szavak[11].cim}}</div>
                        </div>
                        <input *ngIf="szavak" type="text" formControlName="ado1" required placeholder="{{szavak[18].cim}}"/>
                        <label *ngIf="szavak">{{szavak[18].cim}}</label>
                    </div>
                    <div class="form-group col-xs-12 col-sm-6 col-md-6">
                        <div *ngIf="submitted && f.users_irsz1.errors" class="invalid-feedback">
                            <div *ngIf="f.users_irsz1.errors.required">{{szavak[19].cim}} {{szavak[11].cim}}</div>
                        </div>
                        <input *ngIf="szavak" type="text" formControlName="users_irsz1" required placeholder="{{szavak[19].cim}}"/>
                        <label *ngIf="szavak">{{szavak[19].cim}}</label>
                    </div>
                    <div class="form-group col-xs-12 col-sm-6 col-md-6">
                        <div *ngIf="submitted && f.users_telep1.errors" class="invalid-feedback">
                            <div *ngIf="f.users_telep1.errors.required">{{szavak[20].cim}} {{szavak[11].cim}}</div>
                        </div>
                        <input *ngIf="szavak" type="text" formControlName="users_telep1" required placeholder="{{szavak[20].cim}}"/>
                        <label *ngIf="szavak">{{szavak[20].cim}}</label>
                    </div>
                    <div class="form-group col-xs-12 col-sm-6 col-md-6">
                        <div *ngIf="submitted && f.utca1.errors" class="invalid-feedback">
                            <div *ngIf="f.utca1.errors.required">{{szavak[21].cim}} {{szavak[11].cim}}</div>
                        </div>
                        <input *ngIf="szavak" type="text" formControlName="utca1" required placeholder="{{szavak[21].cim}}"/>
                        <label *ngIf="szavak">{{szavak[21].cim}}</label>
                    </div>
                    <div class="form-group col-xs-12 col-sm-6 col-md-6">
                        <div *ngIf="submitted && f.hsz1.errors" class="invalid-feedback">
                            <div *ngIf="f.hsz1.errors.required">{{szavak[22].cim}} {{szavak[11].cim}}</div>
                        </div>
                        <input *ngIf="szavak" type="text" formControlName="hsz1" required placeholder="{{szavak[22].cim}}"/>
                        <label *ngIf="szavak">{{szavak[22].cim}}</label>
                    </div>
                    <h2 *ngIf="szavak" class="szall_head">{{szavak[23].cim}}</h2>
					<div class="checkbox same_check">
						<div class="check">
						    <input type="checkbox" formControlName="azonos" value="1" (click)="azonos()">
							<span class="icon-check-1"></span>
						</div>
						<label *ngIf="szavak">{{szavak[24].cim}}</label>
                    </div>
                    <div class="form-group col-xs-12 col-sm-6 col-md-6">
                        <div *ngIf="submitted && f.veznev2.errors" class="invalid-feedback">
                            <div *ngIf="f.veznev2.errors.required">{{szavak[12].cim}} {{szavak[11].cim}}</div>
                        </div>
                        <input *ngIf="szavak" type="text" formControlName="veznev2" required placeholder="{{szavak[12].cim}}"/>
                        <label *ngIf="szavak">{{szavak[12].cim}}</label>
                    </div>
                    <div class="form-group col-xs-12 col-sm-6 col-md-6">
                        <div *ngIf="submitted && f.kernev2.errors" class="invalid-feedback">
                            <div *ngIf="f.kernev2.errors.required">{{szavak[13].cim}} {{szavak[11].cim}}</div>
                        </div>
                        <input *ngIf="szavak" type="text" formControlName="kernev2" required placeholder="{{szavak[13].cim}}"/>
                        <label *ngIf="szavak">{{szavak[13].cim}}</label>
                    </div>
                    <div class="form-group col-xs-12 col-sm-6 col-md-6">
                        <div *ngIf="submitted && f.cegnev2.errors" class="invalid-feedback">
                            <div *ngIf="f.cegnev2.errors.required">{{szavak[17].cim}} {{szavak[11].cim}}</div>
                        </div>
                        <input *ngIf="szavak" type="text" formControlName="cegnev2" required placeholder="{{szavak[17].cim}}"/>
                        <label *ngIf="szavak">{{szavak[17].cim}}</label>
                    </div>
                    <div class="form-group col-xs-12 col-sm-6 col-md-6">
                        <div *ngIf="submitted && f.users_irsz2.errors" class="invalid-feedback">
                            <div *ngIf="f.users_irsz2.errors.required">{{szavak[19].cim}} {{szavak[11].cim}}</div>
                        </div>
                        <input *ngIf="szavak" type="text" formControlName="users_irsz2" required placeholder="{{szavak[19].cim}}"/>
                        <label *ngIf="szavak">{{szavak[19].cim}}</label>
                    </div>
                    <div class="form-group col-xs-12 col-sm-6 col-md-6">
                        <div *ngIf="submitted && f.users_telep2.errors" class="invalid-feedback">
                            <div *ngIf="f.users_telep2.errors.required">{{szavak[20].cim}} {{szavak[11].cim}}</div>
                        </div>
                        <input *ngIf="szavak" type="text" formControlName="users_telep2" required placeholder="{{szavak[20].cim}}"/>
                        <label *ngIf="szavak">{{szavak[20].cim}}</label>
                    </div>
                    <div class="form-group col-xs-12 col-sm-6 col-md-6">
                        <div *ngIf="submitted && f.utca2.errors" class="invalid-feedback">
                            <div *ngIf="f.utca2.errors.required">{{szavak[21].cim}} {{szavak[11].cim}}</div>
                        </div>
                        <input *ngIf="szavak" type="text" formControlName="utca2" required placeholder="{{szavak[21].cim}}"/>
                        <label *ngIf="szavak">{{szavak[21].cim}}</label>
                    </div>
                    <div class="form-group col-xs-12 col-sm-6 col-md-6">
                        <div *ngIf="submitted && f.hsz2.errors" class="invalid-feedback">
                            <div *ngIf="f.hsz2.errors.required">{{szavak[22].cim}} {{szavak[11].cim}}</div>
                        </div>
                        <input *ngIf="szavak" type="text" formControlName="hsz2" required placeholder="{{szavak[22].cim}}"/>
                        <label *ngIf="szavak">{{szavak[22].cim}}</label>
                    </div>
                    <div *ngIf="fizmodlist" class="fiz_szall_cont">
						<h2 *ngIf="szavak">{{szavak[25].cim}}</h2>
						<div *ngFor="let item of fizmodlist" class="radio_szall checkbox col-xs-12 col-sm-12 col-md-12">
							<div class="check">
								<input type="radio" formControlName="fizmod" value="{{item.id}}" />
								<span class="icon-check-1"></span>
							</div>
							<div class="fiz_szall_text">
								<span class="label_text">{{item.nev}}</span>
								<label class="szall_leiras">{{item.label}}</label>
							</div>
						</div>
                    </div>	
                    <div *ngIf="szallmodlist" class="fiz_szall_cont szall_div">
						<div class="fiz_szall_cont">
						<h2 *ngIf="szavak">{{szavak[26].cim}}</h2>
							<div *ngFor="let item of szallmodlist"class="radio_szall checkbox col-xs-12 col-sm-12 col-md-12">
								<div class="check">
									<input  type="radio" (click)="checkSzall(item.ar)" formControlName="szallmod" value="{{item.id}}" />
									<span class="icon-check-1"></span>
								</div>
								<div class="fiz_szall_text">
									<span class="label_text">
                                        {{item.nev}}
                                        <span *ngIf="item.ara > 0" class='kkm'>{{item.ara}}</span>
									</span>
									<label class="szall_leiras">{{item.leiras}}</label>
								</div>
							</div>
						</div>
                    </div>
                    <h2 *ngIf="szavak">{{szavak[27].cim}}</h2>
					<p class="form-group textarea-grup col-xs-12 col-sm-12 col-md-12">
						<textarea formControlName="megjegyzes" class="form-control"></textarea>
					</p>
					<p *ngIf="szavak" class="form-group col-xs-12 col-sm-12 col-md-12 kupon_cont">
						<input placeholder="{{szavak[28].cim}}" type="text" class="kupon form-control" formControlName="kuponkod" value="">
						<button type="button" class="kupon_kuld" (click)="getkupon()"><span *ngIf="szavak">{{szavak[29].cim}}</span></button>
					</p>
					<div id="kosar_lista" class="kosar2">
						<span *ngIf="szavak && vegossz && vegossz > 0" class="kos_ossz">{{szavak[30].cim}} <b><span class="kos_ossz_ar">{{vegossz | number:'1.0-3'}}</span> {{szavak[5].cim}}</b></span>
						<span *ngIf="szavak && szallar && szallar > 0 && shopConf.shop_ingyenes > vegossz" class="szall_dij">{{szavak[31].cim}} <b><span class="szall_ar">{{szallar | number:'1.0-3'}}</span> {{szavak[5].cim}}</b></span>
                        <span *ngIf="kupon && kupon.hiba === ''" class="kupon_ossz szall_dij">{{kupon.label}}</span>
                        <span *ngIf="kupon && kupon.hiba != ''" class="kupon_ossz szall_dij">{{kupon.hiba}}</span>
						<span *ngIf="szavak && vegosszall && vegosszall > 0" class="vegossz">{{szavak[32].cim}} <b><span class="vegossz_ar szall_term_ossz">{{vegosszall | number:'1.0-3'}}</span> {{szavak[5].cim}}</b></span>
                    </div>
                    <div class="csekk2 checkbox col-xs-12 col-sm-12 col-md-12">
						<div class="check">
							<input required type="checkbox" formControlName="afsz2" value="1">
							<span class="icon-check-1"></span>
						</div>
						<label *ngIf="szavak" class="label-text">{{szavak[33].cim}} <a rel="noopener" *ngIf="afsz2menu" routerLink="/{{afsz2menu.alias}}" target="_blank">{{szavak[34].cim}}</a></label>
					</div>				
					<div class="csekk2 checkbox col-xs-12 col-sm-12 col-md-12">
						<div class="check">
							<input required type="checkbox" formControlName="afsz" value="1">
							<span class="icon-check-1"></span>
						</div>
						<label *ngIf="szavak" class="label-text">{{szavak[33].cim}} <a rel="noopener" *ngIf="afszmenu" routerLink="/{{afszmenu.alias}}" target="_blank">{{szavak[35].cim}}</a></label>
					</div>
					<div class="csekk2 checkbox col-xs-12 col-sm-12 col-md-12">
						<div class="check">
							<input type="checkbox" formControlName="hirl" value="1">
							<span class="icon-check-1"></span>
						</div>
						<label *ngIf="szavak" class="label-text">{{szavak[36].cim}}</label>
                    </div>
                    <re-captcha (resolved)="resolved($event)" *ngIf="captcha" siteKey="captcha.site_key"></re-captcha>
                    <button *ngIf="szavak" type="submit" class="btn btn-default megr_kuld">{{szavak[37].cim}}</button>		
                </div>
            </div>
        </form>
    </div>
</div>!-->