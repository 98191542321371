
<div class="blog">
	<div class="cont">
		<h1 *ngIf="menu" class="cikk_title">{{menu.title}}</h1>
		<ul *ngIf="blog" class="blogcont">
			<li *ngFor="let item of blog" class="entry col-xs-12 col-sm-6 col-md-4">		
				<a title="{{item.title}}" routerLink='/{{item.alias}}'>
					<picture>
						<source type="image/webp" srcset="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/blog/{{item.kep}}.webp">
						<source type="image/jpg" srcset="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/blog/{{item.kep}}">
						<img width="448" height="337" title="{{item.kep_title}}" src="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/blog/{{item.kep}}" alt="{{item.alt}}"/>
					</picture>
					<div class="info">
						<div class="cim">{{item.title}}</div>
						<span class="tov">Elolvasom</span>
						<div class="date">{{item.date}}</div>
					</div>
				</a>
			</li>
		</ul>
	</div>
</div>
<ul class="cimkek" *ngIf="cimke">
    <li *ngFor="let item of cimke">
        <a routerLink="/{{item.alias}}">
            {{item.title}}
        </a>
    </li>
</ul>
