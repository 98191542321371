import { Injectable, Directive, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import{Constants } from '../constants';
import { CanonService } from './canon.service';
import { Meta, Title } from '@angular/platform-browser';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
	
	static scriptType = 'application/json+ld';
	private previousUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public previousUrl$: Observable<string> = this.previousUrl.asObservable();

  	constructor(@Inject(DOCUMENT) private _document: Document, private sanitizer: DomSanitizer, private canon: CanonService, private http: HttpClient, private oldaltitle: Title, private meta: Meta) {}

  	public extractData(res: Response) {
		let body = res;
		return body || { };
  	}

	setPreviousUrl(previousUrl: string) {
		this.previousUrl.next(previousUrl);
	}

  	public getword(lang,szavak): Observable<any> {
		return this.http.get(Constants.endpoint+'common/getword/'+lang+"/"+szavak).pipe(
		map(this.extractData));
	}

	public bbclone(): Observable<any> {
		return this.http.get(Constants.endpoint+'common/bbclone/1').pipe(
		map(this.extractData));
	}

	public getmarka(lang): Observable<any> {
		return this.http.get(Constants.endpoint+'common/getmarka/'+lang).pipe(
		map(this.extractData));
	}

  	public kapcskuld(token,values,lang): Observable<any> {
		return this.http.get(Constants.endpoint+'common/kapcskuld/'+token+"/"+values+'/'+lang).pipe(
		map(this.extractData));
	}

	public egyedikuld(token,values,lang): Observable<any> {
		return this.http.get(Constants.endpoint+'common/egyedikuld/'+token+"/"+values+'/'+lang).pipe(
		map(this.extractData));
	}

	public schema(type,lang,alias): Observable<any> {
		let alias_coded = btoa(unescape(encodeURIComponent(JSON.stringify(alias))));
		//console.log(alias_coded);
		return this.http.get(Constants.endpoint+'common/schema/'+type+"/"+lang+"/"+alias_coded).pipe(
		map(this.extractData));
	}

	public removeStructuredData(): void {
		const els = [];
		[ 'structured-data-website', 'structured-data-org' ].forEach(c => {
			els.push(...Array.from(this._document.head.getElementsByClassName(c)));
		});
		els.forEach(el => this._document.head.removeChild(el));
	}
	
	public insertSchema(schema: Record<string, any>, className = 'structured-data-website'): void {
		let script;
		let shouldAppend = false;
		if (this._document.head.getElementsByClassName(className).length) {
			script = this._document.head.getElementsByClassName(className)[0];
		} else {
			script = this._document.createElement('script');
			shouldAppend = true;
		}
		script.setAttribute('class', className);
		script.type = CommonService.scriptType;
		script.text = JSON.stringify(schema);
		if (shouldAppend) {
			this._document.head.appendChild(script);
		}
	}

	seo(publisher,siteType,title,desc,url, image){
		this.canon.setCanonicalURL(url);
		this.meta.updateTag(
			{ name: 'og:type', content: siteType }
		);
		this.meta.updateTag(
			{ name: 'og:title', content: title }
		);
		this.meta.updateTag(
			{ name: 'twitter:title', content: title }
		);
		this.meta.updateTag(
			{ name: 'dc.publisher', publisher }
		);
		this.meta.updateTag(
			{ name: 'dc.title', content: title }
		);
		this.oldaltitle.setTitle(title);
		this.meta.updateTag(
			{ name: 'og:description', content: desc }
		);
		this.meta.updateTag(
			{ name: 'twitter:description', content: desc }
		);
		this.meta.updateTag(
			{ name: 'dc.description', content: desc }
		);
		this.meta.updateTag(
			{ name: 'description', content: desc }
		);
		this.meta.updateTag(
			{ name: 'og:url', content: url }
		);
		this.meta.updateTag(
			{ name: 'og:image', content: image }
		);
		this.meta.updateTag(
			{ name: 'twitter:image', content: image }
		)
	}
	  
	public szuro(values,lang): Observable<any> {
		return this.http.get(Constants.endpoint+'common/szuro/'+values+'/'+lang).pipe(
		map(this.extractData));
  	}
  
  	public ajanlatkuld(token,values,lang): Observable<any> {
		return this.http.get(Constants.endpoint+'common/ajanlatkuld/'+token+"/"+values+'/'+lang).pipe(
		map(this.extractData));
	}
  
 	public webp(): Observable<any> {
		return this.http.get(Constants.endpoint+'common/webp/1').pipe(
		map(this.extractData));
	}
  
  	public menuroww(ids,lang): Observable<any> {
		return this.http.get(Constants.endpoint+'common/menurow/'+ids+'/'+lang).pipe(
		map(this.extractData));
  	}
  
  	public face(): Observable<any> {
		return this.http.get(Constants.endpoint+'common/face/1').pipe(
		map(this.extractData));
  	}

  	public insta(): Observable<any> {
		return this.http.get(Constants.endpoint+'common/insta/1').pipe(
		map(this.extractData));
	}

	public youtube(): Observable<any> {
		return this.http.get(Constants.endpoint+'common/youtube/1').pipe(
		map(this.extractData));
	}
	
	public langs(): Observable<any> {
		return this.http.get(Constants.endpoint+'common/langs/1').pipe(
		map(this.extractData));
	}

  	public shopcode(): Observable<any> {
		return this.http.get(Constants.endpoint+'common/shopcode/1').pipe(
		map(this.extractData));
  	}

  	public captcha(): Observable<any> {
		return this.http.get(Constants.endpoint+'common/captcha/1').pipe(
		map(this.extractData));
  	}

  	public desc(): Observable<any> {
		return this.http.get(Constants.endpoint+'common/desc/1').pipe(
		map(this.extractData));
  	}

  	public keys(): Observable<any> {
		return this.http.get(Constants.endpoint+'common/keys/1').pipe(
		map(this.extractData));
  	}

  	public title(): Observable<any> {
		return this.http.get(Constants.endpoint+'common/title/1').pipe(
		map(this.extractData));
  	}

  	public seotitle(): Observable<any> {
		return this.http.get(Constants.endpoint+'common/seotitle/1').pipe(
		map(this.extractData));
  	}

  	public siteurl(): Observable<any> {
		return this.http.get(Constants.endpoint+'common/siteurl/1').pipe(
		map(this.extractData));
  	}

  	public robots(): Observable<any> {
		return this.http.get(Constants.endpoint+'common/robots/1').pipe(
		map(this.extractData));
  	}
	  
	public getmodul(lang,poz): Observable<any> {
		return this.http.get(Constants.endpoint+'module/getmodule/'+lang+"/"+poz).pipe(
		map(this.extractData));
  	}

  	public favicon(): Observable<any> {
		return this.http.get(Constants.endpoint+'common/favicon/1').pipe(
		map(this.extractData));
  	}
  
  	public map(): Observable<any> {
		return this.http.get(Constants.endpoint+'common/map/1').pipe(
		map(this.extractData));
	}
}

