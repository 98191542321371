<form [formGroup]="regform" class="reg" *ngIf="szavak" (ngSubmit)="onSubmitPW()">
	<h1 *ngIf="!uid">{{reg.title}}</h1>
	<div class="reg_sep" *ngIf="!uid">
		<div class="list">
			<a *ngIf="reg" routerLink="/{{reg.alias}}"><span>{{reg.title}}</span></a>
		</div>
	</div>
	<div class="reg_cont" *ngIf="uid">
		<h1 *ngIf="newpw">{{newpw.title}}</h1>
		<div class="input_cont">
			<div class="form-group col-xs-12 col-sm-12 col-md-12">
				<div *ngIf="submitted && f.pw.errors" class="invalid-feedback">
					<div *ngIf="f.pw.errors.required">{{szavak[2].cim}}</div>
					<div *ngIf="f.pw.errors.minlength" class="minpw">
						<span *ngIf="f.pw.errors.passwordStrength">/</span>
						{{szavak[12].cim}}
					</div>
				</div>
				<div *ngIf="submitted && f.pw.errors" class="invalid-feedback verror">
					<div *ngIf="f.pw.errors.passwordStrength">{{szavak[13].cim}} </div>
				</div>
				<input *ngIf="szavak" autocomplete="none" type="password" formControlName="pw" required placeholder="{{szavak[9].cim}}*"/>
				<label *ngIf="szavak">{{szavak[9].cim}}*</label>
			</div>
			<div class="form-group col-xs-12 col-sm-12 col-md-12">
				<div *ngIf="submitted && f.pw2.errors" class="invalid-feedback">
					<div *ngIf="f.pw2.errors.required">{{szavak[2].cim}}</div>
					<div class="minpw" *ngIf="f.pw2.errors.minlength">
						<span *ngIf="f.pw2.errors.passwordStrength">/</span>
						{{szavak[12].cim}}
					</div>
				</div>
				<div *ngIf="submitted && f.pw2.errors" class="invalid-feedback verror">
					<div *ngIf="f.pw2.errors.passwordStrength">{{szavak[13].cim}} </div>
				</div>
				<div class="invalid-feedback match" *ngIf="submitted && mustMatch() === false">
					<div *nfIf="f.pw2.errors"><span *ngIf="f.pw2.errors.required">/</span></div>
					{{szavak[14].cim}}
				</div>
				<input *ngIf="szavak" autocomplete="none" type="password" formControlName="pw2" required placeholder="{{szavak[10].cim}}*"/>
				<label *ngIf="szavak">{{szavak[10].cim}}*</label>
			</div>
		</div>
		<button type="submit" name="kapcs_kuld" *ngIf="szavak" class="kapcs_kuld" value="Új jelszó"><span>Új jelszó</span></button>
		<br/><br/><br/>
	</div>
</form>