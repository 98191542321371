import { Component, OnInit, ViewEncapsulation, Input, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { KosarService } from '../../services/kosar.service';
import { MenuService } from '../../services/menu.service';
import { CommonService } from '../../services/common.service';
import { ProdService } from '../../services/prod.service';
import { FormGroup, FormControl, FormArray} from '@angular/forms';
import { NumberFormatPipe } from '../../number.pipe';
import { UserService } from '../../services/user.service';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogComponent } from '../../components/dialog/dialog.component';
import { GalleryItemComponent } from '@ngx-gallery/core/lib/components/gallery-item.component';
import { Router } from '@angular/router';

@Component({
  	selector: 'app-penztar',
  	templateUrl: './penztar.component.html',
	styleUrls: ['./penztar.component.sass'],
	encapsulation: ViewEncapsulation.None,
	providers: [NumberFormatPipe]
})
export class PenztarComponent implements OnInit {

	@Input() final: any = false;
	localStorage: any;  
	shopcode: any;
	kosar_id: any;
	lang: any = "hu";
	szavak: any;
	duplicates: any;
	ossz: any = 0;
	jsonempty = JSON.stringify({'szuroempty':true});
	siteurl: any;
	seotitle: any;
	favicon: any;
	menu: any;
	order = "rand";
	szall_ossz: any = 0;
	userInfo: any;
	full_ossz: any = 0;
	megt: any = 0;
	user_tipus: any;
	error: any = false;
	@ViewChild('dbinput') dbinput: ElementRef;
	isInput: boolean = false;
	inputId: any;
	inputNum: any;

	penztarform = new FormGroup({
		darab: new FormControl('', []),
	});  
	
	constructor(private renderer: Renderer2, private router:Router, private dialog: MatDialog, public user: UserService, private formatPipe: NumberFormatPipe, private prodService: ProdService, private kosarService: KosarService, private commonService: CommonService) {
		this.localStorage = window.localStorage;
		this.user.userInfo$.subscribe((userInfo:any) => {
			this.userInfo = userInfo;
			this.user_tipus = this.userInfo.users_tipus;
		});
		this.renderer.listen('window', 'click',(e:Event)=>{
			var elements = document.getElementsByName("dbinput");
			for(let el of elements){
				console.log(el);
				console.log(e.target);
				if(e.target !== el && this.isInput === true){
					this.isInput = false;
					this.refr();
				}
			}
	   	});
	}

  	ngOnInit(): void {
		this.commonService.getword(this.lang,"vasarolni-netto_ar-afa_erteke-brutto_ar-mennyiseg_db-osszesen-torles-ft-kosar_tartalma-vasar_tov-penz_tov-kulon11").subscribe((data: {}) => {
			this.szavak = data;
		});
		this.commonService.siteurl().subscribe((data: any) => {
			this.siteurl = data.siteurl;
		});
		this.commonService.favicon().subscribe((data: any) => {
			this.favicon = data.favicon;
		});
		this.commonService.seotitle().subscribe((data: any) => {
			this.seotitle = data.title;
		});

		this.commonService.menuroww("80",this.lang).subscribe((data: {}) => {
			this.menu = data[0];
			let kepnev = this.favicon;
			let url = this.siteurl+this.menu.alias;
			this.commonService.seo(this.seotitle,'website',this.menu.seotitle,this.menu.desc,url,kepnev);
		});
		this.commonService.shopcode().subscribe((data: any) => {
			this.shopcode = data.shop_code;
			if(this.localStorage.getItem(this.shopcode+"_kosar") != null){
				this.kosar_id = JSON.parse(this.localStorage.getItem(this.shopcode+"_kosar"));
			}
			if(this.localStorage.getItem(this.shopcode+"_u") != null){
				this.userInfo = JSON.parse(this.localStorage.getItem(this.shopcode+"_u"));
				this.user_tipus = this.userInfo.users_tipus;
			}
			else{
				this.user_tipus = 'privat';
			}
			if (this.kosar_id != undefined && this.kosar_id != 'undefined') {
				for (let item of this.kosar_id) {
					//this.penztarform.addControl('darab_'+item.termek_termekek_id,new FormControl([item.darab]));
					if (item.darab2 > 0) {
						item.darab = item.darab2;
					}
					if (item.termek_termekek_kisz_db === 0) {
						item.termek_termekek_kisz_db = 1;
					}
					this.prodService.prodgal(item.termek_termekek_id, this.lang).subscribe((data: any) => {
						let fokep = data[0].img.split("/")
						item.fokep = fokep[4];
					});
					if (this.user_tipus === 'ceg') {
						if (item.ara_ceg != '' || item.ara_ceg > 0) {
							item.brutto_ar = Number(item.ara_ceg);
							item.brutto_regi = Number(item.ara_magan);
							this.arCalc(item);
						}
						if (item.ara_ceg === '' || item.ara_ceg === 0) {
							item.min = 10000;
							console.log("1");
							this.prodService.prodar(item.termek_termekek_id).subscribe((data: any) => {
								item.arak = data;
								console.log(data);
								if (data.length > 0) {
									console.log("2");
									for (let itemar of item.arak) {
										if (itemar.db < item.min) {
											item.min = itemar.db;
										}
										if (item.darab >= itemar.db) {
											item.brutto_ar = itemar.ar;
										}
										if (item.darab < item.min) {
											item.brutto_ar = item.ara_magan;
										}
									}
									item.brutto_regi = item.ara_magan;
									this.arCalc(item);
									console.log(item.brutto_ar);
								}
								else {
									console.log("4");
									item.brutto_ar = item.ara_magan;
									item.brutto_regi = item.ara_magan;
									this.arCalc(item);
								}
							});
						}
					}
					if (this.user_tipus === 'privat') {
						if (item.lak_akcio != '' || item.lak_akcio > 0) {
							item.brutto_ar = Number(item.lak_akcio);
							item.brutto_regi = Number(item.lak_ar);
						}
						if (item.lak_akcio === '' || item.lak_akcio === 0) {
							item.brutto_ar = Number(item.lak_ar);
							item.brutto_regi = Number(item.lak_ar);
						}
						this.arCalc(item);
					}
				}
				this.megt = this.full_ossz - this.ossz;
			}
		});
	}

	arCalc(item){
		item.osszesen = item.brutto_ar * item.darab;
		item.full_ossz = item.brutto_regi * item.darab;
		if(item.termek_termekek_kisz_belesz === 1){
			this.szall_ossz = this.szall_ossz + item.osszesen;
		}
		item.netto_ar = this.prodService.netto(item.brutto_ar);
		this.full_ossz = this.full_ossz + item.full_ossz;
		this.ossz = this.ossz + item.osszesen;
		/*console.log("netto: "+item.netto_ar);
		console.log("brutto: "+item.brutto_ar);
		console.log("brutto regi: "+item.brutto_regi);
		console.log("item full ossz: "+item.full_ossz);
		console.log("item ossz: "+item.osszesen);
		console.log("full ossz: "+this.full_ossz);
		console.log("ossz: "+this.ossz);*/
	}

	back(){
		window.history.back();
	}

	setOssz(){
		this.localStorage.removeItem(this.shopcode+"_kosar");
		this.localStorage.setItem(this.shopcode+"_kosar", JSON.stringify(this.kosar_id));
		this.kosarService.set_kosar_id(this.kosar_id);
		
		this.localStorage.removeItem(this.shopcode+"_kosar_ossz");
		this.localStorage.setItem(this.shopcode+"_kosar_ossz", this.ossz);
		this.kosarService.set_kosar_ossz(this.ossz);

		this.localStorage.removeItem(this.shopcode+"_kosar_szall_ossz");
		this.localStorage.setItem(this.shopcode+"_kosar_szall_ossz", this.szall_ossz);
		this.kosarService.set_kosar_szall_ossz(this.szall_ossz);
	}

	del(id){
		this.ossz = 0;
		this.full_ossz = 0;
		this.szall_ossz = 0;
		this.kosar_id = this.kosar_id.filter( el => el.termek_termekek_id !== id); 
		for (let obj of this.kosar_id) {
			if(obj.termek_termekek_kisz_belesz === 1){
				this.szall_ossz = this.szall_ossz + obj.osszesen;
			}
			this.ossz = this.ossz + obj.osszesen;
			this.full_ossz = this.full_ossz + obj.full_ossz;
		}
		this.setOssz();
	}

	plus(id){
		this.ossz = 0;
		this.szall_ossz = 0;
		this.full_ossz = 0;
		for (let obj of this.kosar_id) {
			if (obj.termek_termekek_id === id){
				obj.darab = Number(obj.darab)+obj.termek_termekek_kisz_db;
				obj.darab2 = obj.darab;
				if(this.user_tipus === 'ceg'){
					if(obj.ara_ceg != '' || obj.ara_ceg > 0){
						
					}
					else{
						for(let item of obj.arak){
							if(obj.darab >= item.db){
								obj.brutto_ar = item.ar;
							}
						}
					}
				}
				if(this.user_tipus === 'privat'){
					if(obj.lak_akcio != '' || obj.lak_akcio > 0){
						obj.brutto_ar = Number(obj.lak_akcio);
						obj.brutto_regi = Number(obj.lak_ar);
					}
					if(obj.lak_akcio === '' || obj.lak_akcio === 0){
						obj.brutto_ar = Number(obj.lak_ar);
						obj.brutto_regi = Number(obj.lak_ar);
					}
				}
				obj.osszesen = obj.brutto_ar * obj.darab;
				obj.full_ossz = obj.brutto_regi * obj.darab;
			}
			if(obj.termek_termekek_kisz_belesz === 1){
				this.szall_ossz = this.szall_ossz + obj.osszesen;
			}
			this.full_ossz = this.full_ossz + obj.full_ossz;
			this.ossz = this.ossz + obj.osszesen;
		}
		this.setOssz();
	}

	minus(id){
		this.ossz = 0;
		this.szall_ossz = 0;
		this.full_ossz = 0;
		for (let obj of this.kosar_id) {
			if (obj.termek_termekek_id === id){
				if(obj.darab>1 && obj.darab > obj.termek_termekek_kisz_db){
					obj.darab = Number(obj.darab)-obj.termek_termekek_kisz_db;
					obj.darab2 = obj.darab;
					if(this.user_tipus === 'ceg'){
						if(obj.ara_ceg != '' || obj.ara_ceg > 0){
						
						}
						else{
							for(let item of obj.arak){
								if(obj.darab >= item.db){
									obj.brutto_ar = item.ar;
								}
								if(obj.darab < obj.min){
									obj.brutto_ar = obj.ara_magan;
								}
							}
						}
					}
					if(this.user_tipus === 'privat'){
						if(obj.lak_akcio != '' || obj.lak_akcio > 0){
							obj.brutto_ar = Number(obj.lak_akcio);
							obj.brutto_regi = Number(obj.lak_ar);
						}
						if(obj.lak_akcio === '' || obj.lak_akcio === 0){
							obj.brutto_ar = Number(obj.lak_ar);
							obj.brutto_regi = Number(obj.lak_ar);
						}
					}
					obj.osszesen = obj.brutto_ar * obj.darab;
					obj.full_ossz = obj.brutto_regi * obj.darab;
				}
			}
			if(obj.termek_termekek_kisz_belesz === 1){
				this.szall_ossz = this.szall_ossz + obj.osszesen;
			}
			this.ossz = this.ossz + obj.osszesen;
			this.full_ossz = this.full_ossz + obj.full_ossz;
		}
		this.setOssz();
	}

	penz_tov(){
		this.isInput = false;
		this.localStorage.removeItem(this.shopcode+"_kosar");
		this.localStorage.setItem(this.shopcode+"_kosar", JSON.stringify(this.kosar_id));
		this.kosarService.set_kosar_id(this.kosar_id);
		console.log(this.kosar_id);
		this.router.navigate(['/penztar']).then(() => {
			
		});
	}

	refr(){
		this.localStorage.removeItem(this.shopcode+"_kosar");
		this.localStorage.setItem(this.shopcode+"_kosar", JSON.stringify(this.kosar_id));
		this.kosarService.set_kosar_id(this.kosar_id);
		console.log(this.kosar_id);
		//this.router.navigate(['/kosar']).then(() => {
			window.location.reload();
		//});
	}

	setdb(event,id){
		this.isInput = true;
		this.inputNum = event;
		this.inputId = id;
		let current = this.kosar_id.filter(obj => {
			return obj.termek_termekek_id === id
		});
		if(Number(event) > 0 && Number(event) >= current[0].termek_termekek_kisz_db){
			this.ossz = 0;
			this.szall_ossz = 0;
			this.full_ossz = 0;
			//this.error = false;
			for (let obj of this.kosar_id) {
				console.log(obj);
				if (obj.termek_termekek_id === id){
					if(Number(event)>1 && Number(event) > obj.termek_termekek_kisz_db){
						let div = Number(event) % obj.termek_termekek_kisz_db;
						let rem = Number(event) / obj.termek_termekek_kisz_db;
						if(div === 0){
							obj.darab2 = Number(event);
						}
						else{
							obj.darab2 = Math.round(rem) * obj.termek_termekek_kisz_db;
						}
					}
					else{
						obj.darab2 = obj.termek_termekek_kisz_db;
					}
					if(this.user_tipus === 'ceg'){
						if(obj.ara_ceg != '' || obj.ara_ceg > 0){
							
						}
						else{
							for(let item of obj.arak){
								if(obj.darab2 >= item.db){
									obj.brutto_ar = item.ar;
								}
							}
						}
					}
					if(this.user_tipus === 'privat'){
						if(obj.lak_akcio != '' || obj.lak_akcio > 0){
							obj.brutto_ar = Number(obj.lak_akcio);
							obj.brutto_regi = Number(obj.lak_ar);
						}
						if(obj.lak_akcio === '' || obj.lak_akcio === 0){
							obj.brutto_ar = Number(obj.lak_ar);
							obj.brutto_regi = Number(obj.lak_ar);
						}
					}
					obj.osszesen = obj.brutto_ar * obj.darab2;
					obj.full_ossz = obj.brutto_regi * obj.darab2;
				}
				if(obj.termek_termekek_kisz_belesz === 1){
					this.szall_ossz = this.szall_ossz + obj.osszesen;
				}
				this.ossz = this.ossz + obj.osszesen;
				this.full_ossz = this.full_ossz + obj.full_ossz;
			}
			this.setOssz();
			console.log("ossz: "+this.ossz);
			console.log("full_ossz: "+this.full_ossz)
		}
		else{
			this.error = true;
		}
	}

}
