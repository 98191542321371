<dynamic-html class="slide_top slide_plus" *ngIf="right1" [content]="right1.text"></dynamic-html>
<div class="slide_cont">
	<app-slide></app-slide>
	<div class="right">
		<a routerLink="/italok/{{exc.alias}}/abc/1"  class="exc" *ngIf="szavak && exc">
			<span><b>{{szavak[2].cim}}</b>{{szavak[3].cim}}</span>
			<picture>
				<source width="344" height="180" type="image/webp" srcset="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/main/exc.webp?v=31536000‬">
				<source width="344" height="180" type="image/png" srcset="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/main/exc.png?v=31536000‬">
				<img width="344" height="180" src="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/main/exc.png?v=31536000‬" alt="Palace corners"/>
			</picture>
		</a>
		<a routerLink="/italok/{{akc.alias}}/abc/1"  class="akcios" *ngIf="szavak && akc">
			<span><b>{{szavak[0].cim}}</b>{{szavak[1].cim}}</span>
			<picture>
				<source width="344" height="180" type="image/webp" srcset="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/main/akcios.webp?v=31536000‬">
				<source width="344" height="180" type="image/png" srcset="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/main/akcios.png?v=31536000‬">
				<img width="344" height="180" src="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/main/akcios.png?v=31536000‬" alt="Palace corners"/>
			</picture>
		</a>
	</div>
</div>
<dynamic-html class="slide_bot slide_plus" *ngIf="right2" [content]="right2.text"></dynamic-html>
<div class="szuro_kat">
	<div class="cont">
		<app-szuro></app-szuro>
		<app-katlist [parent]="0"></app-katlist>
	</div>
</div>
<dynamic-html class="header3" *ngIf="header3" [content]="header3.text"></dynamic-html>
<div class="osztott">
	<dynamic-html class="header4" *ngIf="header4" [content]="header4.text"></dynamic-html>
	<div class="header5">
		<picture>
			<source width="229" height="429" type="image/webp" srcset="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/main/center_dekor.webp?v=31536000‬">
			<source width="229" height="429" type="image/png" srcset="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/main/center_dekor.png?v=31536000‬">
			<img width="229" height="429" src="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/main/center_dekor.png?v=31536000‬" alt="Palace corners"/>
		</picture>
		<div class="header5_cont">
			<p>Webshopunkban <strong>{{prod_count}}</strong> termékből választhatsz!</p>
		</div>
	</div>
	<dynamic-html class="header6" *ngIf="header6" [content]="header6.text"></dynamic-html>
</div>
<dynamic-html class="header7" *ngIf="header7" [content]="header7.text"></dynamic-html>
<div class="kulon_kiad">
	<div class="cont">
		<div class="top">
			<span *ngIf="szavak">{{szavak[4].cim}}</span>
			<a *ngIf="szavak && limit_kiad" class="limit_link icon-right-open" routerLink="/italok/{{limit_kiad.alias}}/abc/1" ><span>{{szavak[5].cim}}</span></a>
		</div>
		<app-prodlist [order]="order" [szuro]="jsonempty" [from]="0" [sub]="0" [kat]="184" [kiem]="1" [limit]="8" [szint]="0"></app-prodlist>
	</div>
</div>
<div class="blog">
	<div class="cont">
		<div class="top">
			<span>Blog</span>
			<a *ngIf="szavak && bloglink" class="limit_link icon-right-open" routerLink="/{{bloglink.alias}}"><span>{{szavak[6].cim}}</span></a>
		</div>
		<app-blogmodul></app-blogmodul>
	</div>
</div>