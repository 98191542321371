import { Component, OnInit, ViewEncapsulation, Directive, Renderer2, ElementRef } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { Meta, Title } from '@angular/platform-browser';
import { SyncService } from '../../services/sync.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { FormGroup, FormControl} from '@angular/forms';
import { Router} from '@angular/router';
import { ProdService } from '../../services/prod.service';
import { KatService } from '../../services/kat.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {

	localStorage: Storage;
	lang: any = "hu";
	shopcode: any;
	szavak: any;
	header3: any;
	header4: any;
	header5: any;
	header6: any;
	header7: any;
	termek: any;
	bloglink: any;
	right2: any;
	right1: any;
	jsonempty: any;
	order:any = "abc";
	desc: any;
	seotitle: any;
	favicon: any;
	siteurl: any;
	exc: any;
	prod_count: any;
	akc: any;
	limit_kiad: any;
  
  	constructor(private katService: KatService, private renderer2: Renderer2, private elementRef: ElementRef, private prodService: ProdService, private router:Router, private sanitizer: DomSanitizer, public sync: SyncService, private title: Title, private meta: Meta, private commonService: CommonService) {
		this.localStorage = window.localStorage;
	}

	get isLocalStorageSupported(): boolean {
		return !!this.localStorage
	}

	szuro = new FormGroup({
		nev: new FormControl('', []),
		kat: new FormControl('',[]),
		ar: new FormControl([16450,2999990]),
		evjarat: new FormControl('',[]),
		csom: new FormControl('',[]),
		kisz: new FormControl('',[]),
		er_ido: new FormControl([0,100]),
		alk_fok: new FormControl([0,100]),
		limit: new FormControl('', []),
		prodlength: new FormControl('',[]),
		from: new FormControl([0]),
		order: new FormControl([1]),
		selectedKat: new FormControl('',[]),
		selectedCsom: new FormControl('',[]),
		selectedKisz: new FormControl('',[]),
		selectedEvjarat: new FormControl('',[])
	});

  	ngOnInit(): void {
		this.jsonempty = JSON.stringify({'szuroempty':true});
		this.commonService.getmodul(this.lang,"right1").subscribe((data: {}) => {
			this.right1 = data[0];
		});
		this.katService.katrow(184,0,this.lang).subscribe((data: {}) => {
			this.limit_kiad = data[0];
		});
		this.katService.katrow(5,0,this.lang).subscribe((data: {}) => {
			this.exc = data[0];
		});
		this.katService.katrow(183,0,this.lang).subscribe((data: {}) => {
			this.akc = data[0];
		});
		this.commonService.getmodul(this.lang,"right2").subscribe((data: {}) => {
			this.right2 = data[0];
		});
		this.commonService.getmodul(this.lang,"header3").subscribe((data: {}) => {
			this.header3 = data[0];
		});
		this.commonService.getmodul(this.lang,"header4").subscribe((data: {}) => {
			this.header4 = data[0];
		});
		this.commonService.getmodul(this.lang,"header6").subscribe((data: {}) => {
			this.header6 = data[0];
		});
		this.commonService.getmodul(this.lang,"header7").subscribe((data: {}) => {
			this.header7 = data[0];
		});
		this.commonService.schema("home","hu","1").subscribe((data: any) => {
			this.commonService.insertSchema(data);
		});
		this.prodService.prodlength().subscribe((data: any) => {
			this.prod_count = data.leng;
		});
		this.commonService.menuroww("17-53",this.lang).subscribe((data: {}) => {
			this.termek = data[0];
			this.bloglink = data[1];
		});
		this.commonService.getword(this.lang,"akcios-ajanlataink-exc-kulonlegessegek-kulonkiad-limitlink-blog_tov").subscribe((data: {}) => {
			this.szavak = data;
		});
		this.commonService.seotitle().subscribe((data: any) => {
			this.seotitle = data.title;
			this.commonService.desc().subscribe((data: any) => {
				this.desc = data.desc;
				this.commonService.siteurl().subscribe((data: any) => {
					this.siteurl = data.siteurl;
					this.commonService.favicon().subscribe((data: any) => {
						this.favicon = data.favicon;
						this.commonService.seo(this.seotitle,'website',this.seotitle,this.desc,this.siteurl,this.favicon);
					});
				});
			});
		});
	}
}
