import { Injectable, Directive } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Constants } from '../constants';

@Injectable({
  	providedIn: 'root'
})
export class UserService {

	constructor(private http: HttpClient) {}

	public extractData(res: Response) {
	  let body = res;
	  return body || { };
	}

	private userInfo = new Subject<any>();
	userInfo$ = this.userInfo.asObservable();

  	set_userInfo(userInfo: any) {
		this.userInfo.next(userInfo);
	}
	  
	public elpw(token,data): Observable<any> {
		return this.http.get(Constants.endpoint+'user/elpw/'+token+'/'+data).pipe(
		map(this.extractData));
	}

	public login(token,values,lang): Observable<any> {
		return this.http.get(Constants.endpoint+'user/login/'+token+"/"+values+'/'+lang).pipe(
		map(this.extractData));
	}

	public logout(values): Observable<any> {
		return this.http.get(Constants.endpoint+'user/logout/'+values+'/1').pipe(
		map(this.extractData));
	}

	public getuser(id): Observable<any> {
		return this.http.get(Constants.endpoint+'user/getuser/'+id).pipe(
		map(this.extractData));
	}

	public pwmod(id,values): Observable<any> {
		return this.http.get(Constants.endpoint+'user/pwmod/'+id+'/'+values).pipe(
		map(this.extractData));
	}

	public reg(token,values,lang,up,tipus): Observable<any> {
		return this.http.get(Constants.endpoint+'user/reg/'+token+"/"+values+'/'+lang+'/'+up+'/'+tipus).pipe(
		map(this.extractData));
	}

	public adatmod(values,lang,up,tipus,uid): Observable<any> {
		return this.http.get(Constants.endpoint+'user/adatmod/'+values+'/'+lang+'/'+up+'/'+tipus+'/'+uid).pipe(
		map(this.extractData));
	}
}
