import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { SearchService } from '../../services/search.service';
import { CommonService } from '../../services/common.service';
import { SyncService } from '../../services/sync.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class SearchComponent implements OnInit {

  params: any;
  prod: any = [{}];
  szavak: any;
  prod_ossz: any = false;
  localStorage: Storage;
  kosar: any = [];
  shopcode: any;
  menu: any;
  siteurl: any;
  lang: any;
  
  constructor(private title: Title, private meta: Meta, public sync: SyncService, private router: ActivatedRoute, private searchService: SearchService, private commonService: CommonService) {
    this.router.paramMap.subscribe(params => {
      this.getData();
    });
    this.localStorage = window.localStorage;
  }

  get isLocalStorageSupported(): boolean {
    return !!this.localStorage
  }

  	ngOnInit(): void {
		this.commonService.favicon().subscribe((data: any) => {
			this.meta.updateTag(
			  { name: 'og:image', content: data.favicon }
			);
	  	});
    	this.commonService.shopcode().subscribe((data: any) => {
      		this.shopcode = data.shop_code;
     		if(this.localStorage.getItem(this.shopcode+"_cart") != null){
        		this.kosar = JSON.parse(this.localStorage.getItem(this.shopcode+"_cart"));
        		this.sync.set_thread_id(this.kosar);
			}
			if(this.localStorage.getItem(this.shopcode+"_lang") != null){
				this.lang = JSON.parse(this.localStorage.getItem(this.shopcode+"_lang"));
				this.sync.set_thread_id(this.lang);
			}
    		this.commonService.getword(this.lang,"ft-megtekint-keresett").subscribe((data: {}) => {
      			this.szavak = data;
    		});
    		this.commonService.menuroww("48",this.lang).subscribe((data: {}) => {
      			this.menu = data[0];
      			this.commonService.siteurl().subscribe((data: any) => {
        			this.siteurl = data;
        			this.title.setTitle(this.menu.title);
        			this.meta.updateTag(
          				{ name: 'description', content: this.menu.desc }
        			);
        			this.meta.updateTag(
          				{ name: 'og:title', content: this.menu.seotitle }
        			);
        			this.meta.updateTag(
          				{ name: 'og:description', content: this.menu.desc }
        			);
        			this.meta.updateTag(
          				{ name: 'dc.publisher', content: this.menu.seotitle }
        			);
        			this.meta.updateTag(
          				{ name: 'dc.title', content: this.menu.seotitle }
        			);
        			this.meta.updateTag(
          				{ name: 'dc.description', content: this.menu.desc }
        			);
        			this.meta.updateTag(
          				{ name: 'og:url', content: this.siteurl.siteurl+this.menu.alias }
        			);
      			});
    		});
		});
  	}

  	getData() {
    	this.router.paramMap.subscribe((params:any) => {
      		this.params = params.params;
		});
		this.commonService.shopcode().subscribe((data: any) => {
			this.shopcode = data.shop_code;
			if(this.localStorage.getItem(this.shopcode+"_lang") != null){
				this.lang = JSON.parse(this.localStorage.getItem(this.shopcode+"_lang"));
				this.sync.set_thread_id(this.lang);
			}
    		this.searchService.search(this.params.word,this.lang).subscribe((data: []) => {
      			this.prod = data;
			});
		});
  	}

}
