import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CikkService } from '../../services/cikk.service';
import { Router, RouterEvent, NavigationEnd} from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Meta, Title } from '@angular/platform-browser';
import { CommonService } from '../../services/common.service';
import { SyncService } from '../../services/sync.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-cikk',
  templateUrl: './cikk.component.html',
  styleUrls: ['./cikk.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class CikkComponent implements OnInit {

  	cikk: any;
  	siteurl: any;
  	localStorage: Storage;
	lang: any = "hu";
	shopcode: any;
	favicon: any;
	seotitle: any;
	aszf: boolean = false;
	imp: boolean = false;

  	constructor(public sync: SyncService,private commonService: CommonService, private title: Title, private meta: Meta, private sanitizer: DomSanitizer, private cikkService: CikkService, private router: Router) {
		this.localStorage = window.localStorage;
	}

	getData(){
		this.cikkService.getcikk(this.router.url.substr(1),this.lang).subscribe((data: {}) => {
			this.cikk = data;
			var element = document.getElementById("embed-hud");
			if(typeof(element) != 'undefined' && element != null){
    			element.parentNode.removeChild(element);
			}
			var element2 = document.getElementById("fbarat-embed");
    		if(typeof(element2) != 'undefined' && element2 != null){
    			element2.parentNode.removeChild(element2);
			}
			if(this.router.url.substr(1) === 'aszf'){
				this.aszf = true;
				var st = document.createElement("script");
				st.src = "//admin.fogyasztobarat.hu/e-api.js";
				st.type = "text/javascript";
				st.setAttribute("data-id", "MRNB7AWM");
				st.setAttribute("id", "fbarat-embed");
				st.setAttribute("data-type", "aszf");
				var s = document.getElementById("barat_script");
				console.log(s);
				s.parentNode.insertBefore(st, s);
			}
			else{
				this.aszf = false;
			}
			if(this.router.url.substr(1) === 'impresszum'){
				this.imp = true;
				var st = document.createElement("script");
				st.src = "https://www.ceginformacio.hu/js/public/widget.js";
				st.type = "text/javascript";
				st.setAttribute("id", "ceginformacio-rating-widget-io");
				var s = document.getElementById("barat_script");
				s.parentNode.insertBefore(st, s);
				/*<script>
                !function(d,s,id){
                    var js,fjs=d.getElementsByTagName(s)[0];
                    if(!d.getElementById(id)){
                        js=d.createElement(s);
                        js.id=id;
                        js.src='https://www.ceginformacio.hu/js/public/widget.js'
                        fjs.parentNode.insertBefore(js,fjs);
                    }
                }
                (document,'script','ceginformacio-rating-widget-io');
 				 </script>*/
			}
			else{
				this.imp = false;
			}
			if(this.cikk.letezik === "true"){
				let kepnev = this.favicon;
				let url = this.siteurl+this.cikk.alias;
				this.commonService.seo(this.seotitle,'article',this.cikk.seotitle,this.cikk.desc,url,kepnev);
				this.commonService.schema("cikk","hu",this.router.url.substr(1)).subscribe((data2: any) => {
					this.commonService.insertSchema(data2);
				});
			}
			else{
				//this.router.navigate(["/404"]);
			}
		});
	}

	get isLocalStorageSupported(): boolean {
		return !!this.localStorage
	}

  	ngOnInit(): void {
		this.commonService.siteurl().subscribe((data: any) => {
			this.siteurl = data.siteurl;
		});
		this.commonService.favicon().subscribe((data: any) => {
			this.favicon = data.favicon;
		});
		this.commonService.seotitle().subscribe((data: any) => {
			this.seotitle = data.title;
		});
		this.router.events.pipe(filter((event: RouterEvent) => event instanceof NavigationEnd)
		  ).subscribe(() => {
			this.getData();
		});
		if(this.router.url.substr(1) === 'aszf'){
			this.aszf = true;
			var st = document.createElement("script");
			st.src = "//admin.fogyasztobarat.hu/e-api.js";
			st.type = "text/javascript";
			st.setAttribute("data-id", "MRNB7AWM");
			st.setAttribute("id", "fbarat-embed");
			st.setAttribute("data-type", "aszf");
			var s = document.getElementById("barat_script");
			console.log(s);
			s.parentNode.insertBefore(st, s);
		}
		else{
			this.aszf = false;
		}
		if(this.router.url.substr(1) === 'impresszum'){
			this.imp = true;
			/*var st = document.createElement("script");
			st.src = "https://www.ceginformacio.hu/js/public/widget.js";
			st.type = "text/javascript";
			st.setAttribute("id", "ceginformacio-rating-widget-io");
			var s = document.getElementById("barat_script");
			s.parentNode.insertBefore(st, s);*/
			var d = document;
			var id = "ceginformacio-rating-widget-io";
			var js,fjs=d.getElementsByTagName("script")[0];
			if(!d.getElementById(id)){
				js=d.createElement("script");
				js.id=id;
				js.src='https://www.ceginformacio.hu/js/public/widget.js'
				fjs.parentNode.insertBefore(js,fjs);
			}
		}
		else{
			this.imp = false;
		}
		this.cikkService.getcikk(this.router.url.substr(1),this.lang).subscribe((data: {}) => {
			this.cikk = data;
			if(this.cikk.letezik === "true"){
				let kepnev = this.favicon;
				let url = this.siteurl+this.cikk.alias;
				this.commonService.seo(this.seotitle,'article',this.cikk.seotitle,this.cikk.desc,url,kepnev);
				this.commonService.schema("cikk","hu",this.router.url.substr(1)).subscribe((data2: any) => {
					this.commonService.insertSchema(data2);
				});
			}
			else{
				this.router.navigate(["/404"]);
			}
		});
  	}

}