<div *ngIf="prod" class="shop adat_wrapp">
	<div class="cont">
		<div *ngIf="prodkat"class="top">
			<a routerLink="/italok/abc/1">Italshop</a>
			<i *ngIf="prodkat.title" class="sep icon-right-open-mini"></i>
			<a routerLink="/italok/{{prodkat.alias}}/abc/1" *ngIf="prodkat.title" class="main_kat">{{prodkat.title}}</a>
			<i *ngIf="prodkat.szint1_title" class="sep icon-right-open-mini"></i>
			<a routerLink="/italok/{{prodkat.alias}}/{{prodkat.szint1_alias}}/abc/1" *ngIf="prodkat.szint1_title" class="main_kat">{{prodkat.szint1_title}}</a>
			<i *ngIf="prodkat.szint2_title" class="sep icon-right-open-mini"></i>
			<a routerLink="/italok/{{prodkat.alias}}/{{prodkat.szint1_alias}}/{{prodkat.szint2_alias}}/abc/1" *ngIf="prodkat.szint2_title" class="main_kat">{{prodkat.szint2_title}}</a>
			<i *ngIf="prodkat.szint3_title" class="sep icon-right-open-mini"></i>
			<a routerLink="/italok/{{prodkat.alias}}/{{prodkat.szint1_alias}}/{{prodkat.szint2_alias}}/{{prodkat.szint3_alias}}/abc/1" *ngIf="prodkat.szint3_title" class="main_kat">{{prodkat.szint3_title}}</a>
		</div>
		<div class="szuro_kat">
			<app-szuro></app-szuro>
			<div class="prod termlist">
				<div class="prodtop">
					<div class="left">
						<div *ngIf="prod.black_friday == '1'" class="black_friday">
							<picture>
								<source type="image/webp" srcset="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/main/bf.webp">
								<source type="image/jpg" srcset="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/main/bf.png">
								<img src="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/main/bf.png" alt="black friday"/>
							</picture>
						</div>
						<div *ngIf="gal" class="gal">
							<picture *ngFor="let item of gal; index as i">
								<source type="image/webp" srcset="{{item.data.src}}.webp">
								<source type="image/jpg" srcset="{{item.data.src}}">
								<img [gallery]="'anotherLightbox'" [lightbox]="i" [src]="item.data.src" alt="{{item.alt}}"/>
							</picture>
						</div>
						<form [formGroup]="kosarbaform" class="cart_add">
							<div class="cart_db">
								<span (click)="minus()">-</span>
								<input formControlName="darab" type="text" (ngModelChange)="setdb($event)" placeholder="{{db}}" [value]="db">
								<span (click)="plus()">+</span>
							</div>
							<button *ngIf="szavak && prod.termek_termekek_keszlet > 0" (click)="kosarba(prod.termek_termekek_id,prod.termek_termekek_nev, fokep, prod.cikkszam, prod.ara_magan, prod.ara_ceg, prod.lak_ar, prod.lak_akcio)"><span>{{szavak[1].cim}}</span></button>
							<button *ngIf="szavak && prod.termek_termekek_keszlet === 0" class="disabled"><span>{{szavak[1].cim}}</span></button>
							<div class="arlista" *ngIf="user_tipus === 'ceg' && arak && arak.length > 0">
								<span *ngFor="let item of arak" >
									<i>{{item.db}}</i> <b *ngIf="szavak">{{szavak[10].cim}}</b> {{item.ar | number:'1.0-3' | numberFormat}} <b *ngIf="szavak">{{szavak[0].cim}}</b>
								</span>
							</div>
						</form>
					</div>
					<div class="right">
						<div class="cont">
							<h1>{{prod.termek_termekek_nev}}</h1>
							<div *ngIf="user_tipus === 'ceg'"class="ar_cont">
								<div *ngIf="prod.ara_ceg != '' || prod.ara_ceg > 0" class="akcios">
									<div *ngIf="szavak" class="eredeti_ar">
										{{szavak[3].cim}}: <small>{{prod.ara_magan | number:'1.0-3' | numberFormat}} <span *ngIf="szavak">{{szavak[0].cim}}</span></small>
									</div>
									<div class="akcios_ar">
										<i>-{{akc}}%</i>{{prod.ara_ceg | number:'1.0-3' | numberFormat}} <span *ngIf="szavak">{{szavak[0].cim}}</span>
									</div>
									<div class="afa">
										({{akc_afa | number:'1.0-3' | numberFormat}} <span *ngIf="szavak">{{szavak[0].cim}} + ÁFA)</span>
									</div>
								</div>
								<div *ngIf="prod.ara_ceg === '' || prod.ara_ceg === 0" class="nem_akcios">
									<div class="akcios_ar sima_ar">
										{{prod.ara_magan | number:'1.0-3' | numberFormat}} <span *ngIf="szavak">{{szavak[0].cim}}</span>
									</div>
									<div class="afa">
										({{sima_afa | number:'1.0-3' | numberFormat}} <span *ngIf="szavak">{{szavak[0].cim}} + ÁFA)</span>
									</div>
								</div>
							</div>
							<div *ngIf="user_tipus === 'privat'"class="ar_cont">
								<div *ngIf="prod.lak_akcio != '' || prod.lak_akcio > 0" class="akcios">
									<div *ngIf="szavak" class="eredeti_ar">
										{{szavak[3].cim}}: <small>{{prod.lak_ar | number:'1.0-3' | numberFormat}} <span *ngIf="szavak">{{szavak[0].cim}}</span></small>
									</div>
									<div class="akcios_ar">
										<i>-{{akc}}%</i>{{prod.lak_akcio | number:'1.0-3' | numberFormat}} <span *ngIf="szavak">{{szavak[0].cim}}</span>
									</div>
									<div class="afa">
										({{akc_afa | number:'1.0-3' | numberFormat}} <span *ngIf="szavak">{{szavak[0].cim}} + ÁFA)</span>
									</div>
								</div>
								<div *ngIf="prod.lak_akcio === '' || prod.lak_akcio === 0" class="nem_akcios">
									<div class="akcios_ar sima_ar">
										{{prod.lak_ar | number:'1.0-3' | numberFormat}} <span *ngIf="szavak">{{szavak[0].cim}}</span>
									</div>
									<div class="afa">
										({{sima_afa | number:'1.0-3' | numberFormat}} <span *ngIf="szavak">{{szavak[0].cim}} + ÁFA)</span>
									</div>
								</div>
							</div>
							<div class="info">
								<!--<div class="evj"><b>Kiszerelés:</b><span *ngFor="let row of prodkisz">{{row.title}}<i>, </i></span></div>!-->
								<div class="evj"><b>Kiszerelés:</b><span>{{prod.termek_termekek_kisz}} L</span></div>
								<b>Alkoholtartalom</b>{{prod.termek_termekek_alc}}%<br/>
								<div class="evj"><b>Csomagolás</b><span *ngFor="let row of prodcsom">{{row.title}}<i>, </i></span></div>
								<div class="evj"><b>Évjárat</b><span *ngFor="let row of prodev">{{row.title}}<i>, </i></span></div>
								<b>Érlelési idő / kor</b>{{prod.termek_erido}} év<br/>
								<b>Limitált kiadás</b>{{limit}}<br/>
								<b>Egységár</b> {{prod.egysegar| number:'1.0-3' | numberFormat}} Ft/liter<br/>
								<b>Cikkszám</b> {{prod.cikkszam}}
							</div>
							<!--<div class="raktarkeszlet">
								<div *ngIf="prod.termek_termekek_statusz === 1 && szavak" class="rakt_igen"><i class="icon-check-1"></i>{{szavak[7].cim}}</div>
								<div *ngIf="prod.termek_termekek_statusz === 2 && szavak" class="rakt_nem"><i class="icon-cancel-1"></i>{{szavak[8].cim}}</div>
								<div *ngIf="prod.termek_termekek_statusz === 3 && szavak" class="rakt_besz"><i class="icon-clock-8"></i>{{szavak[9].cim}}</div>
							</div>!-->
							<div *ngIf="prod && prod.termek_termekek_leiras != ''" class="adatlap_ism">
								<div class="adatlap_ism_cont" [innerHTML]="prod.termek_termekek_leiras"></div>
							</div>
						</div>
					</div>
					<div class="tabs">
						<div class="top">
							<span [class.active]="tab1 === true" *ngIf="prod.uj_leir_nev1 !=''" (click)="opentab(1)">{{prod.uj_leir_nev1}}</span>
							<span [class.active]="tab2 === true" *ngIf="prod.uj_leir_nev2 !=''" (click)="opentab(2)">{{prod.uj_leir_nev2}}</span>
							<span [class.active]="tab3 === true" *ngIf="prod.uj_leir_nev3 !=''" (click)="opentab(3)">{{prod.uj_leir_nev3}}</span>
						</div>
						<div class="bot">
							<dynamic-html [class.active]="tab1 === true" *ngIf="prod.uj_leir_nev1 !=''" class="tab_text" [content]="prod.uj_leir_text1"></dynamic-html>
							<dynamic-html [class.active]="tab2 === true" *ngIf="prod.uj_leir_nev2 !=''" class="tab_text" [content]="prod.uj_leir_text2"></dynamic-html>
							<dynamic-html [class.active]="tab3 === true" *ngIf="prod.uj_leir_nev3 !=''" class="tab_text" [content]="prod.uj_leir_text3"></dynamic-html>
						</div>
					</div>
				</div>
				<a class="icon-left-dir term_back" *ngIf="van_szuro === true" routerLink="/italok/abc/{{lap}}">Vissza a termékekhez</a>
				<a class="icon-left-dir term_back" *ngIf="van_szuro === false && lap === 1" (click)="back()">Vissza a termékekhez</a>
				<a class="icon-left-dir term_back" *ngIf="van_szuro === false && lap > 1" routerLink="{{back_link}}">Vissza a termékekhez</a>
					<span class="prodkapcs_title" *ngIf="prodkapcs">Kapcsolódó termékek</span>
					<ul *ngIf="prodkapcs" class="prodkapcs">
						<li *ngFor="let row of prodkapcs" class="col-xs-12 col-sm-6 col-md-4">
							<div class="prodcont">
								<a routerLink="/{{row.alias}}"> 
									<div *ngIf="row.black_friday == '1'" class="black_friday">
										<picture>
											<source type="image/webp" srcset="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/main/bf.webp">
											<source type="image/jpg" srcset="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/main/bf.png">
											<img src="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/main/bf.png" alt="black friday"/>
										</picture>
									</div>
									<div *ngIf="user_tipus === 'ceg' && row.arak_count>0 && (row.ara_ceg == '' || row.ara_ceg == 0 ||  row.ara_ceg == '0')" class="menny_kedv">
										<span *ngIf="szavak">{{szavak[2].cim}}</span>
									</div>
									<div *ngIf="user_tipus === 'ceg' && szavak && row.ara_ceg != '' && row.ara_ceg > 0" class="akc_szalag">
										<span *ngIf="szavak">{{szavak[5].cim}}</span>
									</div>
									<div *ngIf="user_tipus === 'privat' && szavak && row.lak_akcio != '' && row.lak_akcio > 0" class="akc_szalag">
										<span *ngIf="szavak">{{szavak[5].cim}}</span>
									</div>
									<div *ngIf="szavak && row.uj === true" class="uj_szalag">
										<span *ngIf="szavak">{{szavak[6].cim}}</span>
									</div>
									<div class="product_image">
										<picture *ngIf="row.fokep">
											<source type="image/webp" srcset="vision/cms/uploaded_images/product/{{row.fokep}}.webp">
											<source type="image/jpg" srcset="vision/cms/uploaded_images/product/{{row.fokep}}">
											<img src="vision/cms/uploaded_images/product/{{row.fokep}}" alt="{{row.title}}"/>
										</picture>
									</div>
									<div *ngIf="row.title" class="term_nev">
										{{row.title}}
									</div>
									<dynamic-html class="term_leir" [content]="row.leir | stripTags: 'pre'"></dynamic-html>
									<div *ngIf="user_tipus === 'ceg'" class="ar_cont">
										<div *ngIf="row.ara_ceg != '' || row.ara_ceg > 0" class="akcios">
											<div *ngIf="szavak" class="eredeti_ar">
												{{szavak[3].cim}}: <small>{{row.ara_magan | number:'1.0-3' | numberFormat}} <span *ngIf="szavak">{{szavak[0].cim}}</span></small>
											</div>
											<div class="akcios_ar">
												{{row.ara_ceg | number:'1.0-3' | numberFormat}} <span *ngIf="szavak">{{szavak[0].cim}}</span>
											</div>
										</div>
										<div *ngIf="row.ara_ceg === '' || row.ara_ceg === 0" class="nem_akcios">
											<div *ngIf="szavak" class="eredeti_ar">
												{{szavak[4].cim}}:
											</div>
											<div class="akcios_ar sima_ar">
												{{row.ara_magan | number:'1.0-3' | numberFormat}} <span *ngIf="szavak">{{szavak[0].cim}}</span>
											</div>
										</div>
									</div>
									<div *ngIf="user_tipus === 'privat'" class="ar_cont">
										<div *ngIf="row.lak_akcio != '' || row.lak_akcio > 0" class="akcios">
											<div *ngIf="szavak" class="eredeti_ar">
												{{szavak[3].cim}}: <small>{{row.lak_ar | number:'1.0-3' | numberFormat}} <span *ngIf="szavak">{{szavak[0].cim}}</span></small>
											</div>
											<div class="akcios_ar">
												{{row.lak_akcio | number:'1.0-3' | numberFormat}} <span *ngIf="szavak">{{szavak[0].cim}}</span>
											</div>
										</div>
										<div *ngIf="row.lak_akcio === '' || row.lak_akcio === 0" class="nem_akcios">
											<div *ngIf="szavak" class="eredeti_ar">
												{{szavak[4].cim}}:
											</div>
											<div class="akcios_ar sima_ar">
												{{row.lak_ar | number:'1.0-3' | numberFormat}} <span *ngIf="szavak">{{szavak[0].cim}}</span>
											</div>
										</div>
									</div>
								</a>
								<div class="kosarba" *ngIf="szavak" (click)="kosarba(row.id,row.title,row.fokep, row.cikkszam, row.ara_magan, row.ara_ceg, row.lak_ar, row.lak_akcio)"><span>{{szavak[1].cim}}</span></div>
							</div>
						</li>
					</ul>
			</div>
		</div>
	</div>
</div>