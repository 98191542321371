import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { BlogService } from '../../services/blog.service';
import { CommonService } from '../../services/common.service';
import { Meta, Title } from '@angular/platform-browser';
import { SyncService } from '../../services/sync.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class BlogComponent implements OnInit {

  	blog: any = [{}];
  	szavak: any;
  	cimke: any;
  	menu: any;
  	siteurl: any;
  	localStorage: Storage;
	lang: any = "hu";
	shopcode: any;
	seotitle: any;
  
  	constructor(public sync: SyncService, private title: Title, private meta: Meta, private router: Router, private blogService: BlogService, private commonService: CommonService) {
		this.localStorage = window.localStorage;
	}

	get isLocalStorageSupported(): boolean {
		return !!this.localStorage
	}

 	ngOnInit(): void {
		this.commonService.siteurl().subscribe((data: any) => {
			this.siteurl = data.siteurl;
		});
		this.commonService.seotitle().subscribe((data: any) => {
			this.seotitle = data.title;
		});
		this.commonService.menuroww("53",this.lang).subscribe((data: {}) => {
			this.menu = data[0];
			this.blogService.getblog(this.router.url.substr(1),0,this.lang).subscribe((data2: []) => {
				this.blog = data2;
				console.log(data2);
				let kepnev = this.siteurl+"vision/cms/uploaded_images/blog/"+this.blog[0].kep;
				let url = this.siteurl+this.menu.alias;
				this.commonService.seo(this.seotitle,'website',this.menu.seotitle,this.menu.desc,url,kepnev);
				this.commonService.schema("blog","hu",this.router.url.substr(1)).subscribe((data2: any) => {
					this.commonService.insertSchema(data2);
				});
			});
		});
		this.blogService.cimke("false",this.lang).subscribe((data: []) => {
			this.cimke = data;

		});
  	}
}
