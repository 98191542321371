import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SlideService } from '../../services/slide.service';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { CommonService } from '../../services/common.service';
import { SyncService } from '../../services/sync.service';

@Component({
  selector: 'app-slide',
  templateUrl: './slide.component.html',
  styleUrls: ['./slide.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class SlideComponent implements OnInit {

  	slide: any;
  	szavak: any;
  	localStorage: Storage;
	lang: any = "hu";
	shopcode: any;
	slide_mobil: any;

  	constructor(public sync: SyncService, private slideService: SlideService, private commonService: CommonService) {
		this.localStorage = window.localStorage;
	}

	get isLocalStorageSupported(): boolean {
		return !!this.localStorage
	}

 	ngOnInit(): void {
		this.slideService.slide(this.lang).subscribe((data: {}) => {
			this.slide = data;
		});
		this.slideService.slide_mobil(this.lang).subscribe((data: any) => {
			this.slide_mobil = data[0].slide_mobil;
			console.log(data);
		});
  	}

  	slideConfig = {
   		"slidesToShow": 1,
    	"slidesToScroll": 1,
    	"dots": true,
    	"arrows": false,
    	"infinite": true,
    	"autoplay": true,
    	"autoplaySpeed": 4000
  	};

  slickInit(e) {
    
  }

}
