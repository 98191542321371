<div id="cikk">
    <h1 *ngIf="cikk" class="cikk_title">{{cikk.title}}</h1>
    <dynamic-html *ngIf="cikk" class="cont" [content]="cikk.text"></dynamic-html>
</div>
<div class="blog_bot">
	<a *ngIf="menu" class="icon-left-open" routerLink="/{{menu.alias}}"><span *ngIf="szavak">{{szavak[0].cim}}</span></a>
	<span *ngIf="cikk">{{cikk.date}}</span>
</div>
<ul class="cimkek" *ngIf="cimke">
    <li *ngFor="let item of cimke">
        <a routerLink="/{{item.alias}}">
            {{item.title}}
        </a>
    </li>
</ul>