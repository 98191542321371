import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BlogService } from '../../services/blog.service';
import { CommonService } from '../../services/common.service';
import { Meta, Title } from '@angular/platform-browser';
import { SyncService } from '../../services/sync.service';

@Component({
  selector: 'app-cimkesingle',
  templateUrl: './cimkesingle.component.html',
  styleUrls: ['./cimkesingle.component.sass']
})
export class CimkesingleComponent implements OnInit {

  	params: any;
  	blog: any = [{}];
  	szavak: any;
  	cimkek: any;
  	menu: any;
  	cimke: any;
	siteurl: any;
	localStorage: Storage;
	lang: any = "hu";
	shopcode: any;
	seotitle: any;
	letezik: any;
	
  	constructor(private rtr: Router, public sync: SyncService, private title: Title, private meta: Meta, private router: ActivatedRoute, private blogService: BlogService, private commonService: CommonService) {
		this.localStorage = window.localStorage;
		this.router.paramMap.subscribe(params => {
      		this.getData();
    	});
	}
	
	get isLocalStorageSupported(): boolean {
		return !!this.localStorage
	}

  	ngOnInit(): void {
    	this.blogService.cimke("false",this.lang).subscribe((data: []) => {
			this.cimkek = data;
		});
		this.commonService.siteurl().subscribe((data: any) => {
			this.siteurl = data.siteurl;
		});
		this.commonService.seotitle().subscribe((data: any) => {
			this.seotitle = data.title;
		});
  	}

  	getData() {
    	this.router.paramMap.subscribe((params:any) => {
      		this.params = params.params;
		});
		this.commonService.menuroww("63",this.lang).subscribe((data: {}) => {
			this.menu = data[0];
			this.blogService.cimkeblog(this.params.id,this.lang).subscribe((data: any) => {
				this.blog = data[1];
				this.letezik = data[0].letezik;
				console.log(data);
				console.log(this.blog);
				console.log(this.letezik);
				this.commonService.schema("cimke","hu",this.params.id).subscribe((data2: any) => {
					this.commonService.insertSchema(data2);
				});
				if(this.letezik === "true"){
					let kepnev = this.siteurl+"vision/cms/uploaded_images/blog/"+this.blog[0].kep;
					this.blogService.cimkesingle(this.params.id,this.lang).subscribe((data: []) => {
						this.cimke = data;
						let url = this.siteurl+this.menu.alias+"/"+this.cimke.alias;
						this.commonService.seotitle().subscribe((data: any) => {
							this.seotitle = data.title;
							this.commonService.seo(this.seotitle,'website',this.cimke.seotitle,this.cimke.desc,url,kepnev);
						});
					});
				}
				else{
					//this.rtr.navigate(["/404"]);
				}
			});
		});
  	}
}
