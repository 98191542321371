<ul *ngIf="this.parent != 0">
	<li *ngFor="let row of katList;" class="col-xs-12 col-sm-6 col-md-4">
		<a *ngIf="row.id != '1'" routerLink="/{{row.alias}}/abc/1" title="{{row.title}}">
			<picture>
				<source type="image/webp" srcset="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/category/{{row.kepnev}}.webp">
				<source type="image/jpg" srcset="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/category/{{row.kepnev}}">
				<img title="{{row.title}}" src="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/category/{{row.kepnev}}" alt="{{row.title}}"/>
			</picture>
			<div class="cim">{{row.title}}</div>
		</a>
		<a *ngIf="row.id == '1'" routerLink="/{{row.alias}}/legujabb/1" title="{{row.title}}">
			<picture>
				<source type="image/webp" srcset="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/category/{{row.kepnev}}.webp">
				<source type="image/jpg" srcset="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/category/{{row.kepnev}}">
				<img title="{{row.title}}" src="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/category/{{row.kepnev}}" alt="{{row.title}}"/>
			</picture>
			<div class="cim">{{row.title}}</div>
		</a>
	</li>
</ul>
<ul *ngIf="this.parent === 0">
	<li *ngFor="let row of katList;" class="col-xs-12 col-sm-6 col-md-4">
		<a *ngIf="row.id != '1'" routerLink="/{{row.alias}}/abc/1" title="{{row.title}}">
			<picture>
				<source type="image/webp" srcset="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/category/{{row.kepnev}}.webp">
				<source type="image/jpg" srcset="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/category/{{row.kepnev}}">
				<img title="{{row.title}}" src="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/category/{{row.kepnev}}" alt="{{row.title}}"/>
			</picture>
			<div class="cim">{{row.title}}</div>
		</a>
		<a *ngIf="row.id == '1'" routerLink="/{{row.alias}}/legujabb/1" title="{{row.title}}">
			<picture>
				<source type="image/webp" srcset="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/category/{{row.kepnev}}.webp">
				<source type="image/jpg" srcset="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/category/{{row.kepnev}}">
				<img title="{{row.title}}" src="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/category/{{row.kepnev}}" alt="{{row.title}}"/>
			</picture>
			<div class="cim">{{row.title}}</div>
		</a>
	</li>
</ul>
