<h1 *ngIf="final === false">Kosár</h1>
<h1 *ngIf="final === true">Pénztár</h1>
<div [class.nodel]="final === true" class="kosar_lista">
	<div class="top">
		<span *ngIf="szavak">{{szavak[0].cim}}</span>
		<span *ngIf="szavak">{{szavak[1].cim}}</span>
		<span *ngIf="szavak">{{szavak[2].cim}}</span>
		<span *ngIf="szavak">{{szavak[3].cim}}</span>
		<span *ngIf="szavak">{{szavak[4].cim}}</span>
		<span *ngIf="szavak">{{szavak[5].cim}}</span>
		<span *ngIf="szavak && final === false">{{szavak[6].cim}}</span>
	</div>
	<div *ngIf="kosar_id" class="mid">
		<div *ngFor="let row of kosar_id">
			<div>
				<span class="mobil" *ngIf="szavak">{{szavak[0].cim}}</span>
				<picture>
					<source type="image/webp" srcset="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/product/{{row.fokep}}.webp">
					<source type="image/jpg" srcset="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/product/{{row.fokep}}">
					<img src="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/product/{{row.fokep}}" alt="{{row.termek_termekek_nev}}"/>
				</picture>
				<span><b><i>{{row.termek_termekek_nev}}</i></b></span>
			</div>
			<div *ngIf="row.netto_ar">
				<span class="mobil" *ngIf="szavak">{{szavak[1].cim}}</span>
				{{row.netto_ar | number:'1.0-3' | numberFormat}} <span *ngIf="szavak">{{szavak[7].cim}}</span>
			</div>
			<div>
				<span class="mobil" *ngIf="szavak">{{szavak[2].cim}}</span>
				27%
			</div>
			<div *ngIf="row.brutto_ar">
				<span class="mobil" *ngIf="szavak">{{szavak[3].cim}}</span>
				<div *ngIf="row.brutto_regi != row.brutto_ar" class="regi_ar">
					{{row.brutto_regi | number:'1.0-3' | numberFormat}} <span *ngIf="szavak">{{szavak[7].cim}}</span>
				</div>
				{{row.brutto_ar | number:'1.0-3' | numberFormat}} <span *ngIf="szavak">{{szavak[7].cim}}</span>
			</div>
			<div>
				<span class="mobil" *ngIf="szavak">{{szavak[4].cim}}</span>
				<form *ngIf="final === false" [formGroup]="penztarform" class="cart_add">
					<div class="cart_db">
						<span (click)="minus(row.termek_termekek_id)">-</span>
						<input #dbinput id="dbinput" class="dbinput" name="dbinput" formControlName="darab" type="text" (ngModelChange)="setdb($event,row.termek_termekek_id)" placeholder="{{row.darab}}" value="{{row.darab}}" [value]="row.darab">
						<span (click)="plus(row.termek_termekek_id)">+</span>
					</div>
				</form>
				<div *ngIf="final === true" class="kosardb">{{row.darab}}</div>
			</div>
			<div>
				<span class="mobil" *ngIf="szavak">{{szavak[5].cim}}</span>
				<i *ngIf="row.osszesen" id="{{row.termek_termekek_id}}trmk">{{row.osszesen | number:'1.0-2' | numberFormat}}</i><span *ngIf="szavak"> {{szavak[7].cim}}</span>
			</div>
			<div>
				<span *ngIf="final === false" class="close" (click)="del(row.termek_termekek_id)">x</span>
			</div>
		</div>
	</div>
	<div *ngIf="szavak" class="bot">
		<span>{{szavak[8].cim}}</span>
		<b *ngIf="ossz"><i id="kosarossz">{{ossz | number:'1.0-3' | numberFormat}}</i> {{szavak[7].cim}}</b>
		<b class="megt" *ngIf="full_ossz != ossz">Megtakarítás:<i id="kosarossz2">{{full_ossz - ossz | number:'1.0-3' | numberFormat}}</i> {{szavak[7].cim}}</b>
	</div>
</div>
<div *ngIf="szavak" class="kosar_nav">
	<a class="back" *ngIf="final === false" routerLink="/italok/abc/1"><i>{{szavak[9].cim}}</i></a>
	<a class="refr_link" *ngIf="final === true" routerLink="/kosar"><span>Vissza a kosárba</span></a>
	<!--<a (click)="refr()" class="refr_link" routerLink="/kosar"><span>Frissítés</span></a>!-->
	<a [class.disabled]="error === true" *ngIf="final === false" (click)="penz_tov()" routerLink="/penztar"><span>{{szavak[10].cim}}</span></a>
</div>
<div *ngIf="final === false" class="kulon_kiad penztar_kiad">
	<div class="cont">
		<span *ngIf="szavak">{{szavak[11].cim}}</span>
		<app-prodlist [order]="order" [szuro]="jsonempty" [from]="0" [sub]="0" [kat]="184" [kiem]="0" [limit]="9" [szint]="0"></app-prodlist>
	</div>
</div>