import { Component, OnInit, ViewEncapsulation} from '@angular/core';
import { CikkService } from '../../services/cikk.service';
import { Router } from '@angular/router';
import { CommonService } from '../../services/common.service';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { SyncService } from '../../services/sync.service';

@Component({
	selector: 'app-egyedikeresek',
	templateUrl: './egyedikeresek.component.html',
	styleUrls: ['./egyedikeresek.component.sass', '../cikk/cikk.component.sass'],
	encapsulation: ViewEncapsulation.None
})
export class EgyedikeresekComponent implements OnInit {

  	kapcsform = new FormGroup({
    	nev: new FormControl('', [
      		Validators.required
		]),
    	email: new FormControl('',[
      		Validators.required,
      		Validators.email
    	]),
    	tel: new FormControl('',[
      		Validators.required
    	]),
    	uzenet: new FormControl('',[
     		Validators.required
    	]),
    	aszf: new FormControl('',[
      		Validators.required
		]),
		gcaptcha: new FormControl('',[
			
		]),
  	});
  
  	cikk: any;
  	submitted = false;
  	szavak: any;
  	values: any;
  	menu: any;
  	token: string = "false";
  	kapcs: any;
	siteurl: any;
	localStorage: Storage;
	lang: any = "hu";
	shopcode: any;
	error: any = true;
	favicon: any;
	seotitle: any;

  	constructor(public sync: SyncService, private title: Title, private meta: Meta, private cikkService: CikkService, private router: Router, private commonService: CommonService) {
		this.localStorage = window.localStorage;
	}

	get isLocalStorageSupported(): boolean {
		return !!this.localStorage
	}
	  
  
  	ngOnInit(): void {
		this.commonService.siteurl().subscribe((data: any) => {
			this.siteurl = data.siteurl;
		});
		this.commonService.favicon().subscribe((data: any) => {
			this.favicon = data.favicon;
		});
		this.commonService.seotitle().subscribe((data: any) => {
			this.seotitle = data.title;
		});
		this.cikkService.getcikk(this.router.url.substr(1),this.lang).subscribe((data: {}) => {
			this.cikk = data;
		});
		this.commonService.getword(this.lang,"tel-uzenet-nev-email-sikeres-close-kotelezo2-hibasmail-kuldes-elfogadom-adatvedelmitxt-cegnev").subscribe((data: {}) => {
			this.szavak = data;
		});
		this.commonService.menuroww("71-48",this.lang).subscribe((data: {}) => {
			this.kapcs = data[0];
			this.menu = data;
			let kepnev = this.favicon;
			let url = this.siteurl+this.kapcs.alias;
			this.commonService.seo(this.seotitle,'website',this.kapcs.seotitle,this.kapcs.desc,url,kepnev);
		});
  	}

  	get f() { return this.kapcsform.controls; }

  	resolved(captchaResponse: string) {
    	this.token = captchaResponse;
  	}
  
  	onSubmit() {
		this.submitted = true;
    	Object.keys(this.kapcsform.controls).forEach(key => {
			if(this.kapcsform.controls[key].errors != null){
				this.error = false;
				console.log(key);
			}
		});
    	if (this.error === false) {
      		return;
    	}
    	else{
			this.commonService.shopcode().subscribe((data: any) => {
				this.shopcode = data.shop_code;
				if(this.localStorage.getItem(this.shopcode+"_lang") != null){
					this.lang = JSON.parse(this.localStorage.getItem(this.shopcode+"_lang"));
					this.sync.set_thread_id(this.lang);
				}
      			this.commonService.egyedikuld(this.token, btoa(unescape(encodeURIComponent(JSON.stringify(this.kapcsform.value)))),this.lang).subscribe((data: any) => {
					this.router.navigate([this.menu[1].alias]);
				});
			});
    	}
  	}

}
