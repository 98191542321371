import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ThumbnailsMode } from '@ngx-gallery/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators'
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-nyito',
  templateUrl: './nyito.component.html',
  styleUrls: ['./nyito.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class NyitoComponent implements OnInit {

	elmult: any;
	nyitoSubmit: any;
	home: any;
	cVal: string;
	
	constructor(private cK: CookieService, private router:Router, private route:ActivatedRoute) { }

  	ngOnInit(): void {
		this.router.events.pipe(
			filter(event => event instanceof NavigationEnd),
			map(() => this.route),
			map(route => {
			  while (route.firstChild) route = route.firstChild
			  return route
			}),
			filter(route => route.outlet === 'primary'),mergeMap(route => route.data)).subscribe((data:any) =>{
			if(data.home === "true"){
				this.home = true;
			}
			else{
				this.home = false;
			}
		});
	}

	csekk1(){
		this.elmult = true;
	}

	csekk2(){
		this.elmult = false;
	}

	submit(){
		if(this.elmult === true){
			this.nyitoSubmit = true;
			let cE = new Date();
			cE.setDate(cE.getDate() + 1);
			this.cK.set('tassi-nyito','true',cE);
		}
		else{
			this.nyitoSubmit = false;
		}
	}

}
