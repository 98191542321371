<form [formGroup]="regform" class="reg" *ngIf="szavak" (ngSubmit)="onSubmitMod()">
	<h1 *ngIf="!userInfo">{{reg.title}}</h1>
	<div class="reg_sep" *ngIf="!userInfo">
		<div class="list">
			<a *ngIf="reg" routerLink="/{{reg.alias}}"><span>{{reg.title}}</span></a>
		</div>
	</div>
	<div class="reg_cont" *ngIf="userInfo">
		<h1 *ngIf="adataim">{{adataim.title}}</h1>
		<dynamic-html *ngIf="cikk" class="cont" [content]="cikk.text"></dynamic-html>
		<div class="input_cont">
			<div *ngIf="alias === 'maganszemely'" class="form-group col-xs-12 col-sm-12 col-md-12">
				<div *ngIf="submitted && f.veznev.errors" class="invalid-feedback">
					<div *ngIf="f.veznev.errors.required">{{szavak[3].cim}} {{szavak[2].cim}}</div>
				</div>
				<input *ngIf="szavak" type="text" formControlName="veznev" required placeholder="{{szavak[3].cim}}*"/>
				<label *ngIf="szavak">{{szavak[3].cim}}*</label>
			</div>
			<div *ngIf="alias === 'maganszemely'" class="form-group col-xs-12 col-sm-12 col-md-12">
				<div *ngIf="submitted && f.kernev.errors" class="invalid-feedback">
					<div *ngIf="f.kernev.errors.required">{{szavak[4].cim}} {{szavak[2].cim}}</div>
				</div>
				<input *ngIf="szavak" type="text" formControlName="kernev" required placeholder="{{szavak[4].cim}}*"/>
				<label *ngIf="szavak">{{szavak[4].cim}}*</label>
			</div>
			<div *ngIf="alias === 'viszontelado'" class="form-group col-xs-12 col-sm-12 col-md-12">
				<div *ngIf="submitted && f.cegnev.errors" class="invalid-feedback">
					<div *ngIf="f.cegnev.errors.required">{{szavak[5].cim}} {{szavak[2].cim}}</div>
				</div>
				<input *ngIf="szavak" type="text" formControlName="cegnev" required placeholder="{{szavak[5].cim}}*"/>
				<label *ngIf="szavak">{{szavak[5].cim}}*</label>
			</div>
			<div *ngIf="alias === 'viszontelado'" class="form-group col-xs-12 col-sm-12 col-md-12">
				<div *ngIf="submitted && f.kapcs.errors" class="invalid-feedback">
					<div *ngIf="f.kapcs.errors.required">{{szavak[6].cim}} {{szavak[2].cim}}</div>
				</div>
				<input *ngIf="szavak" type="text" formControlName="kapcs" required placeholder="{{szavak[6].cim}}*"/>
				<label *ngIf="szavak">{{szavak[6].cim}}*</label>
			</div>
			<div class="form-group col-xs-12 col-sm-12 col-md-12">
				<div *ngIf="submitted && f.tel.errors" class="invalid-feedback">
					<div *ngIf="f.tel.errors.required">{{szavak[7].cim}} {{szavak[2].cim}}</div>
				</div>
				<input *ngIf="szavak" type="text" formControlName="tel" required placeholder="{{szavak[7].cim}}*"/>
				<label *ngIf="szavak">{{szavak[7].cim}}*</label>
			</div>

			<div class="form-group col-xs-12 col-sm-12 col-md-12">
				<div *ngIf="submitted && f.email.errors" class="invalid-feedback">
					<div *ngIf="f.email.errors.required">{{szavak[2].cim}}</div>
					<div *ngIf="f.email.errors.email">{{szavak[11].cim}} {{szavak[8].cim}}</div>
				</div>
				<div *ngIf="!submitted && f.email.errors && f.email.touched" class="invalid-feedback">
					<div *ngIf="f.email.errors.required">{{szavak[2].cim}}</div>
					<div *ngIf="f.email.errors.email">{{szavak[11].cim}} {{szavak[8].cim}}</div>
				</div>
				<input *ngIf="szavak" type="email" formControlName="email" required placeholder="{{szavak[8].cim}}*"/>
				<label *ngIf="szavak">{{szavak[8].cim}}*</label>
			</div>
			<div *ngIf="alias === 'viszontelado'" class="form-group col-xs-12 col-sm-12 col-md-12">
				<div *ngIf="submitted && f.adoszam.errors" class="invalid-feedback">
					<div *ngIf="f.adoszam.errors.required">{{szavak[15].cim}} {{szavak[2].cim}}</div>
				</div>
				<input *ngIf="szavak" type="text" formControlName="adoszam" required placeholder="{{szavak[15].cim}}*"/>
				<label *ngIf="szavak">{{szavak[15].cim}}*</label>
			</div>
			<div *ngIf="alias === 'viszontelado'" class="file col-xs-12 col-sm-12 col-md-12">
				<small *ngIf="szavak">(kizárólag <b>PDF formátumban</b> tölthető fel!)</small>
				<div class="cont">
					<input type="file" formControlName="szall_0_mukodes" (change)="onFileChange($event)" />
					<label *ngIf="!regform.controls.szall_0_mukodes_src.value">{{szavak[16].cim}}*</label>
					<label *ngIf="regform.controls.szall_0_mukodes_src.value">{{regform.controls.szall_0_mukodes_src.value.name}}</label>
					<span class="up">{{szavak[17].cim}}</span>
				</div>
			</div>
			<br/><br/>
			<h2 *ngIf="szavak">{{szavak[18].cim}}</h2>
			<div *ngIf="alias === 'maganszemely'" class="form-group col-xs-12 col-sm-12 col-md-12">
				<div *ngIf="submitted && f.szam_veznev.errors" class="invalid-feedback">
					<div *ngIf="f.szam_veznev.errors.required">{{szavak[3].cim}} {{szavak[2].cim}}</div>
				</div>
				<input *ngIf="szavak" type="text" formControlName="szam_veznev" required placeholder="{{szavak[3].cim}}*"/>
				<label *ngIf="szavak">{{szavak[3].cim}}*</label>
			</div>
			<div *ngIf="alias === 'maganszemely'" class="form-group col-xs-12 col-sm-12 col-md-12">
				<div *ngIf="submitted && f.szam_kernev.errors" class="invalid-feedback">
					<div *ngIf="f.szam_kernev.errors.required">{{szavak[4].cim}} {{szavak[2].cim}}</div>
				</div>
				<input *ngIf="szavak" type="text" formControlName="szam_kernev" required placeholder="{{szavak[4].cim}}*"/>
				<label *ngIf="szavak">{{szavak[4].cim}}*</label>
			</div>
			<div *ngIf="alias === 'viszontelado'" class="form-group col-xs-12 col-sm-12 col-md-12">
				<div *ngIf="submitted && f.szam_cegnev.errors" class="invalid-feedback">
					<div *ngIf="f.szam_cegnev.errors.required">{{szavak[5].cim}} {{szavak[2].cim}}</div>
				</div>
				<input *ngIf="szavak" type="text" formControlName="szam_cegnev" required placeholder="{{szavak[5].cim}}*"/>
				<label *ngIf="szavak">{{szavak[5].cim}}*</label>
			</div>
			<div *ngIf="alias === 'viszontelado'" class="form-group col-xs-12 col-sm-12 col-md-12">
				<div *ngIf="submitted && f.szam_ado.errors" class="invalid-feedback">
					<div *ngIf="f.szam_ado.errors.required">{{szavak[15].cim}} {{szavak[2].cim}}</div>
				</div>
				<input *ngIf="szavak" type="text" formControlName="szam_ado" required placeholder="{{szavak[15].cim}}*"/>
				<label *ngIf="szavak">{{szavak[15].cim}}*</label>
			</div>
			<div class="cim form-group col-xs-12 col-sm-12 col-md-12">
				<div class="form-group col-xs-12 col-sm-4 col-md-4">
					<div *ngIf="submitted && f.szam_irsz.errors" class="invalid-feedback">
						<div *ngIf="f.szam_irsz.errors.required">{{szavak[19].cim}} {{szavak[2].cim}}</div>
					</div>
					<input *ngIf="szavak" type="text" formControlName="szam_irsz" required placeholder="{{szavak[19].cim}}*"/>
					<label *ngIf="szavak">{{szavak[19].cim}}*</label>
				</div>
				<div class="form-group col-xs-12 col-sm-8 col-md-8">
					<div *ngIf="submitted && f.szam_varos.errors" class="invalid-feedback">
						<div *ngIf="f.szam_varos.errors.required">{{szavak[20].cim}} {{szavak[2].cim}}</div>
					</div>
					<input *ngIf="szavak" type="text" formControlName="szam_varos" required placeholder="{{szavak[20].cim}}*"/>
					<label *ngIf="szavak">{{szavak[20].cim}}*</label>
				</div>
			</div>
			<div class="cim form-group col-xs-12 col-sm-12 col-md-12">
				<div *ngIf="submitted && f.szam_utca.errors" class="invalid-feedback">
					<div *ngIf="f.szam_utca.errors.required">{{szavak[21].cim}} {{szavak[2].cim}}</div>
				</div>
				<input *ngIf="szavak" type="text" formControlName="szam_utca" required placeholder="{{szavak[21].cim}}*"/>
				<label *ngIf="szavak">{{szavak[21].cim}}*</label>
			</div>
			<br/><br/>
			<h2 *ngIf="szavak">{{szavak[22].cim}}</h2>
			<div *ngIf="alias === 'maganszemely'" class="magan_szall_cont">
				<div class="form-group col-xs-12 col-sm-12 col-md-12">
					<div *ngIf="submitted && f.szall_veznev.errors" class="invalid-feedback">
						<div *ngIf="f.szall_veznev.errors.required">{{szavak[3].cim}} {{szavak[2].cim}}</div>
					</div>
					<input *ngIf="szavak" type="text" formControlName="szall_veznev" required placeholder="{{szavak[3].cim}}*"/>
					<label *ngIf="szavak">{{szavak[3].cim}}*</label>
				</div>
				<div class="form-group col-xs-12 col-sm-12 col-md-12">
					<div *ngIf="submitted && f.szall_kernev.errors" class="invalid-feedback">
						<div *ngIf="f.szall_kernev.errors.required">{{szavak[4].cim}} {{szavak[2].cim}}</div>
					</div>
					<input *ngIf="szavak" type="text" formControlName="szall_kernev" required placeholder="{{szavak[4].cim}}*"/>
					<label *ngIf="szavak">{{szavak[4].cim}}*</label>
				</div>
				<div class="cim form-group col-xs-12 col-sm-12 col-md-12">
					<div class="form-group col-xs-12 col-sm-4 col-md-4">
						<div *ngIf="submitted && f.szall_irsz.errors" class="invalid-feedback">
							<div *ngIf="f.szall_irsz.errors.required">{{szavak[19].cim}} {{szavak[2].cim}}</div>
						</div>
						<input *ngIf="szavak" type="text" formControlName="szall_irsz" required placeholder="{{szavak[19].cim}}*"/>
						<label *ngIf="szavak">{{szavak[19].cim}}*</label>
					</div>
					<div class="form-group col-xs-12 col-sm-8 col-md-8">
						<div *ngIf="submitted && f.szall_varos.errors" class="invalid-feedback">
							<div *ngIf="f.szall_varos.errors.required">{{szavak[20].cim}} {{szavak[2].cim}}</div>
						</div>
						<input *ngIf="szavak" type="text" formControlName="szall_varos" required placeholder="{{szavak[20].cim}}*"/>
						<label *ngIf="szavak">{{szavak[20].cim}}*</label>
					</div>
				</div>
				<div class="cim form-group col-xs-12 col-sm-12 col-md-12">
					<div *ngIf="submitted && f.szall_utca.errors" class="invalid-feedback">
						<div *ngIf="f.szall_utca.errors.required">{{szavak[21].cim}} {{szavak[2].cim}}</div>
					</div>
					<input *ngIf="szavak" type="text" formControlName="szall_utca" required placeholder="{{szavak[21].cim}}*"/>
					<label *ngIf="szavak">{{szavak[21].cim}}*</label>
				</div>
			</div>
			<div *ngIf="alias === 'viszontelado'" class="ceg_szall_cont">
				<h3 *ngIf="szavak" [class.open]="szall_1_show === true" (click)="openCim(1)">
					1. {{szavak[24].cim}}
				</h3>
				<div class="szall_cim_cont" >
					<div class="form-group col-xs-12 col-sm-12 col-md-12">
						<input *ngIf="szavak" type="text" formControlName="szall_1_cegnev"  placeholder="{{szavak[5].cim}}"/>
						<label *ngIf="szavak && regform.controls.szall_1_cegnev.value != ''">{{szavak[5].cim}}</label>
					</div>
					<div class="cim form-group col-xs-12 col-sm-12 col-md-12">
						<div class="form-group col-xs-12 col-sm-4 col-md-4">
							<input *ngIf="szavak" type="text" formControlName="szall_1_irsz"  placeholder="{{szavak[19].cim}}"/>
							<label *ngIf="szavak && regform.controls.szall_1_irsz.value != ''">{{szavak[19].cim}}</label>
						</div>
						<div class="form-group col-xs-12 col-sm-8 col-md-8">
							<input *ngIf="szavak" type="text" formControlName="szall_1_varos"  placeholder="{{szavak[20].cim}}"/>
							<label *ngIf="szavak && regform.controls.szall_1_varos.value != ''">{{szavak[20].cim}}</label>
						</div>
					</div>
					<div class="cim form-group col-xs-12 col-sm-12 col-md-12">
						<input *ngIf="szavak" type="text" formControlName="szall_1_utca"  placeholder="{{szavak[21].cim}}"/>
						<label *ngIf="szavak && regform.controls.szall_1_utca.value != ''">{{szavak[21].cim}}</label>
					</div>
					<div *ngIf="alias === 'viszontelado'" class="file col-xs-12 col-sm-12 col-md-12">
						<small *ngIf="szavak">(kizárólag <b>PDF formátumban</b> tölthető fel!)</small>
						<div class="cont">
							<input type="file" formControlName="szall_1_mukodes" (change)="onFileChange($event)" />
							<label *ngIf="!regform.controls.szall_1_mukodes_src.value">{{szavak[16].cim}}*</label>
							<label *ngIf="regform.controls.szall_1_mukodes_src.value">{{regform.controls.szall_1_mukodes_src.value.name}}</label>
							<span class="up">{{szavak[17].cim}}</span>
						</div>
					</div>
				</div>
				<h3 *ngIf="szavak" [class.open]="szall_2_show === true" (click)="openCim(2)">
					2. {{szavak[24].cim}}
				</h3>
				<div class="szall_cim_cont" >
					<div class="form-group col-xs-12 col-sm-12 col-md-12">
						<input *ngIf="szavak" type="text" formControlName="szall_2_cegnev"  placeholder="{{szavak[5].cim}}"/>
						<label *ngIf="szavak && regform.controls.szall_2_cegnev.value != ''">{{szavak[5].cim}}</label>
					</div>
					<div class="cim form-group col-xs-12 col-sm-12 col-md-12">
						<div class="form-group col-xs-12 col-sm-4 col-md-4">
							<input *ngIf="szavak" type="text" formControlName="szall_2_irsz"  placeholder="{{szavak[19].cim}}"/>
							<label *ngIf="szavak && regform.controls.szall_2_irsz.value != ''">{{szavak[19].cim}}</label>
						</div>
						<div class="form-group col-xs-12 col-sm-8 col-md-8">
							<input *ngIf="szavak" type="text" formControlName="szall_2_varos"  placeholder="{{szavak[20].cim}}"/>
							<label *ngIf="szavak && regform.controls.szall_2_varos.value != ''">{{szavak[20].cim}}</label>
						</div>
					</div>
					<div class="cim form-group col-xs-12 col-sm-12 col-md-12">
						<input *ngIf="szavak" type="text" formControlName="szall_2_utca"  placeholder="{{szavak[21].cim}}"/>
						<label *ngIf="szavak && regform.controls.szall_2_utca.value != ''">{{szavak[21].cim}}</label>
					</div>
					<div *ngIf="alias === 'viszontelado'" class="file col-xs-12 col-sm-12 col-md-12">
						<small *ngIf="szavak">(kizárólag <b>PDF formátumban</b> tölthető fel!)</small>
						<div class="cont">
							<input type="file" formControlName="szall_2_mukodes" (change)="onFileChange($event)" />
							<label *ngIf="!regform.controls.szall_2_mukodes_src.value">{{szavak[16].cim}}*</label>
							<label *ngIf="regform.controls.szall_2_mukodes_src.value">{{regform.controls.szall_2_mukodes_src.value.name}}</label>
							<span class="up">{{szavak[17].cim}}</span>
						</div>
					</div>
				</div>
				<h3 *ngIf="szavak" [class.open]="szall_3_show === true" (click)="openCim(3)">
					3. {{szavak[24].cim}}
				</h3>
				<div class="szall_cim_cont" >
					<div class="form-group col-xs-12 col-sm-12 col-md-12">
						<input *ngIf="szavak" type="text" formControlName="szall_3_cegnev"  placeholder="{{szavak[5].cim}}"/>
						<label *ngIf="szavak && regform.controls.szall_3_cegnev.value != ''">{{szavak[5].cim}}</label>
					</div>
					<div class="cim form-group col-xs-12 col-sm-12 col-md-12">
						<div class="form-group col-xs-12 col-sm-4 col-md-4">
							<input *ngIf="szavak" type="text" formControlName="szall_3_irsz"  placeholder="{{szavak[19].cim}}"/>
							<label *ngIf="szavak && regform.controls.szall_3_irsz.value != ''">{{szavak[19].cim}}</label>
						</div>
						<div class="form-group col-xs-12 col-sm-8 col-md-8">
							<input *ngIf="szavak" type="text" formControlName="szall_3_varos"  placeholder="{{szavak[20].cim}}"/>
							<label *ngIf="szavak && regform.controls.szall_3_varos.value != ''">{{szavak[20].cim}}</label>
						</div>
					</div>
					<div class="cim form-group col-xs-12 col-sm-12 col-md-12">

						<input *ngIf="szavak" type="text" formControlName="szall_3_utca"  placeholder="{{szavak[21].cim}}"/>
						<label *ngIf="szavak && regform.controls.szall_3_utca.value != ''">{{szavak[21].cim}}</label>
					</div>
					<div *ngIf="alias === 'viszontelado'" class="file col-xs-12 col-sm-12 col-md-12">
						<small *ngIf="szavak">(kizárólag <b>PDF formátumban</b> tölthető fel!)</small>
						<div class="cont">
							<input type="file" formControlName="szall_3_mukodes" (change)="onFileChange($event)" />
							<label *ngIf="!regform.controls.szall_3_mukodes_src.value">{{szavak[16].cim}}*</label>
							<label *ngIf="regform.controls.szall_3_mukodes_src.value">{{regform.controls.szall_3_mukodes_src.value.name}}</label>
							<span class="up">{{szavak[17].cim}}</span>
						</div>
					</div>
				</div>
				<h3 *ngIf="szavak" [class.open]="szall_4_show === true" (click)="openCim(4)">
					4. {{szavak[24].cim}}
				</h3>
				<div class="szall_cim_cont" >
					<div class="form-group col-xs-12 col-sm-12 col-md-12">
						<input *ngIf="szavak" type="text" formControlName="szall_4_cegnev"  placeholder="{{szavak[5].cim}}"/>
						<label *ngIf="szavak && regform.controls.szall_4_cegnev.value != ''">{{szavak[5].cim}}</label>
					</div>
					<div class="cim form-group col-xs-12 col-sm-12 col-md-12">
						<div class="form-group col-xs-12 col-sm-4 col-md-4">
							<input *ngIf="szavak" type="text" formControlName="szall_4_irsz"  placeholder="{{szavak[19].cim}}"/>
							<label *ngIf="szavak && regform.controls.szall_4_irsz.value != ''">{{szavak[19].cim}}</label>
						</div>
						<div class="form-group col-xs-12 col-sm-8 col-md-8">
							<input *ngIf="szavak" type="text" formControlName="szall_4_varos"  placeholder="{{szavak[20].cim}}"/>
							<label *ngIf="szavak && regform.controls.szall_4_varos.value != ''">{{szavak[20].cim}}</label>
						</div>
					</div>
					<div class="cim form-group col-xs-12 col-sm-12 col-md-12">
						<input *ngIf="szavak" type="text" formControlName="szall_4_utca"  placeholder="{{szavak[21].cim}}"/>
						<label *ngIf="szavak && regform.controls.szall_4_utca.value != ''">{{szavak[21].cim}}</label>
					</div>
					<div *ngIf="alias === 'viszontelado'" class="file col-xs-12 col-sm-12 col-md-12">
						<small *ngIf="szavak">(kizárólag <b>PDF formátumban</b> tölthető fel!)</small>
						<div class="cont">
							<input type="file" formControlName="szall_4_mukodes" (change)="onFileChange($event)" />
							<label *ngIf="!regform.controls.szall_4_mukodes_src.value">{{szavak[16].cim}}*</label>
							<label *ngIf="regform.controls.szall_4_mukodes_src.value">{{regform.controls.szall_4_mukodes_src.value.name}}</label>
							<span class="up">{{szavak[17].cim}}</span>
						</div>
					</div>
				</div>
				<h3 *ngIf="szavak" [class.open]="szall_5_show === true" (click)="openCim(5)">
					5. {{szavak[24].cim}}
				</h3>
				<div class="szall_cim_cont" >
					<div class="form-group col-xs-12 col-sm-12 col-md-12">
						<input *ngIf="szavak" type="text" formControlName="szall_5_cegnev"  placeholder="{{szavak[5].cim}}"/>
						<label *ngIf="szavak && regform.controls.szall_5_cegnev.value != ''">{{szavak[5].cim}}</label>
					</div>
					<div class="cim form-group col-xs-12 col-sm-12 col-md-12">
						<div class="form-group col-xs-12 col-sm-4 col-md-4">
							<input *ngIf="szavak" type="text" formControlName="szall_5_irsz"  placeholder="{{szavak[19].cim}}"/>
							<label *ngIf="szavak && regform.controls.szall_5_irsz.value != ''">{{szavak[19].cim}}</label>
						</div>
						<div class="form-group col-xs-12 col-sm-8 col-md-8">
							<input *ngIf="szavak" type="text" formControlName="szall_5_varos"  placeholder="{{szavak[20].cim}}"/>
							<label *ngIf="szavak && regform.controls.szall_5_varos.value != ''">{{szavak[20].cim}}</label>
						</div>
					</div>
					<div class="cim form-group col-xs-12 col-sm-12 col-md-12">
						<input *ngIf="szavak" type="text" formControlName="szall_5_utca"  placeholder="{{szavak[21].cim}}"/>
						<label *ngIf="szavak && regform.controls.szall_5_utca.value != ''">{{szavak[21].cim}}</label>
					</div>
					<div *ngIf="alias === 'viszontelado'" class="file col-xs-12 col-sm-12 col-md-12">
						<small *ngIf="szavak">(kizárólag <b>PDF formátumban</b> tölthető fel!)</small>
						<div class="cont">
							<input type="file" formControlName="szall_5_mukodes" (change)="onFileChange($event)" />
							<label *ngIf="!regform.controls.szall_5_mukodes_src.value">{{szavak[16].cim}}*</label>
							<label *ngIf="regform.controls.szall_5_mukodes_src.value">{{regform.controls.szall_5_mukodes_src.value.name}}</label>
							<span class="up">{{szavak[17].cim}}</span>
						</div>
					</div>
				</div>
			</div>
			<br/>
			<a class="kapcs_kuld" routerLink="/uj-jelszo"><span>Új jelszó</span></a>
			<button type="submit" name="kapcs_kuld" *ngIf="szavak" class="kapcs_kuld" value="{{szavak[25].cim}}"><span>Adatmódosítás</span></button>
			<br/><br/><br/>
		</div>
	</div>
</form>