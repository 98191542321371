import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { BlogService } from '../../services/blog.service';
import { CommonService } from '../../services/common.service';
import { SyncService } from '../../services/sync.service';

@Component({
  selector: 'app-blogmodul',
  templateUrl: './blogmodul.component.html',
  styleUrls: ['./blogmodul.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class BlogmodulComponent implements OnInit {

  	blog: any = [{}];
  	szavak: any;
	menu: any;
	localStorage: Storage;
	lang: any = "hu";
	shopcode: any;

  	constructor(public sync: SyncService, private blogService: BlogService, private commonService: CommonService) {
		this.localStorage = window.localStorage;
	}

	get isLocalStorageSupported(): boolean {
		return !!this.localStorage
	}

  	ngOnInit(): void {
    	this.commonService.menuroww("53", this.lang).subscribe((data: {}) => {
      		this.menu = data[0];
    	});
    	this.blogService.getblog(37,3,this.lang).subscribe((data: []) => {
			this.blog = data;
		});
  	}

}
