import { Component, OnInit, ViewEncapsulation, Input  } from '@angular/core';
import { GalleryService } from '../../services/gallery.service';
import { Gallery, GalleryItem, ImageItem, ThumbnailsPosition, ImageSize } from '@ngx-gallery/core';
import { map } from 'rxjs/operators';
import { SyncService } from '../../services/sync.service';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class GalleryComponent implements OnInit {

  	@Input() accid: any;
	gall: GalleryItem[];
	localStorage: Storage;
	lang: any;
	langs: any;
	shopcode: any;

  	constructor(private commonService: CommonService, public sync: SyncService, public gallery: Gallery, private galleryService: GalleryService) {
		this.localStorage = window.localStorage;
	}

	get isLocalStorageSupported(): boolean {
		return !!this.localStorage
	}

  	withCustomGalleryConfig() {
   		// 2. Get a lightbox gallery ref
    	const lightboxGalleryRef = this.gallery.ref('anotherLightbox');

   		// (Optional) Set custom gallery config to this lightbox
    	lightboxGalleryRef.setConfig({
      		imageSize: ImageSize.Cover,
      		thumbPosition: ThumbnailsPosition.Top
    	});

    	// 3. Load the items into the lightbox
   	 	lightboxGalleryRef.load(this.gall);
  	}

  	ngOnInit(): void {
		this.commonService.shopcode().subscribe((data: any) => {
			this.shopcode = data.shop_code;
			this.commonService.langs().subscribe((data: any) => {
				this.langs = data;
				this.langs.forEach((element:any) => {
					if(element.def === 1){
						this.lang = element.code;
					}
				});
				if(this.localStorage.getItem(this.shopcode+"_lang") != null){
					this.lang = JSON.parse(this.localStorage.getItem(this.shopcode+"_lang"));
					this.sync.set_thread_id(this.lang);
				}
				this.galleryService.getgallery(this.accid,this.lang).subscribe((data: any) => {
					this.gall = data.map((item:any) =>
						new ImageItem({ src: item.img, thumb: item.img })
					);
					this.withCustomGalleryConfig();
				});
			});
		});
  	}

}
