import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from '../../services/common.service';
import { ProdService } from '../../services/prod.service';
import { KosarService } from '../../services/kosar.service';
import { Router} from '@angular/router';

declare let ga: Function;

export interface KosarbaData {
	title: '';
	img: '';
	id: '';
	darab: '';
	ar: '';
	cikkszam: '';
	users_tipus: any;
	ara_ceg: any;
	ara_magan: any;
	lak_akcio: any;
	lak_ar: any;
}

@Component({
  	selector: 'app-kosarba',
  	templateUrl: './kosarba.component.html',
	styleUrls: ['./kosarba.component.sass'],
	encapsulation: ViewEncapsulation.None
})

export class KosarbaComponent implements OnInit {

	kosar_menu:any;
	lang:any = "hu";
	result: any;
	shopcode: any;
	localStorage: any;
	kosar_id: any = Array();
	ossz: any = 0;
	userInfo: any;
	user_tipus: any;

	constructor(private router:Router, @Inject(MAT_DIALOG_DATA) public data: KosarbaData, private commonService: CommonService, private prodService: ProdService, private kosarService: KosarService) {
		this.localStorage = window.localStorage;
	}

	tovabb_kosar(){
		this.router.navigate(['/kosar'], { state: { szuro_submit: false } }).then(() => {
			window.location.reload();
		});
	}

	tovabb_kosar2(){
		window.location.reload();
	}

  	ngOnInit(): void {
		this.commonService.shopcode().subscribe((data: any) => {
			this.shopcode = data.shop_code;
			if(this.localStorage.getItem(this.shopcode+"_kosar") != null){
				this.kosar_id = JSON.parse(this.localStorage.getItem(this.shopcode+"_kosar"));
			}
			if(this.localStorage.getItem(this.shopcode+"_u") != null){
				this.userInfo = JSON.parse(this.localStorage.getItem(this.shopcode+"_u"));
				this.user_tipus = this.userInfo.users_tipus;
			}
			else{
				this.user_tipus = 'privat';
			}
			this.prodService.prodrow(this.data.id, this.lang, this.user_tipus).subscribe((data: {}) => {
				if(this.kosarVan(this.data.id) === true){
					for(let item of this.kosar_id){
						//item.darab2 = 0;
						if(item.termek_termekek_id === this.data.id){
							item.darab = item.darab + this.data.darab;
						}
					}
				}
				else{
					data[0].darab = this.data.darab;
					this.kosar_id.push(data[0]);
				}
				for(let item2 of this.kosar_id){
					let ar = 0;
					let osszesen = 0;
					if(this.data.users_tipus === 'ceg'){
						if(item2.ara_ceg != '' || item2.ara_ceg > 0){
							ar = Number(item2.ara_ceg);
						}
						if(item2.ara_ceg === '' || item2.ara_ceg === 0){
							ar = Number(item2.ara_magan);
						}
					}
					if(this.data.users_tipus === 'privat'){
						if(item2.lak_akcio != '' || item2.lak_akcio > 0){
							ar = Number(item2.lak_akcio);
						}
						if(item2.lak_akcio === '' || item2.lak_akcio === 0){
							ar = Number(item2.lak_ar);
						}
					}
					osszesen = ar * item2.darab;
					this.ossz = this.ossz + osszesen;
				}
				let ar2 = 0;
				if(this.data.users_tipus = 'privat'){
					if(this.data.lak_akcio > 0){
						ar2 = this.data.lak_akcio;
					}
					else{
						ar2 = this.data.lak_ar;
					}
				}
				else{
					if(this.data.ara_ceg > 0){
						ar2 = this.data.ara_ceg;
					}
					else{
						ar2 = this.data.ara_magan;
					}
				}
				this.localStorage.removeItem(this.shopcode+"_kosar_ossz");
				this.localStorage.setItem(this.shopcode+"_kosar_ossz", this.ossz);
				this.kosarService.set_kosar_ossz(this.ossz);
				this.localStorage.removeItem(this.shopcode+"_kosar");
				this.localStorage.setItem(this.shopcode+"_kosar", JSON.stringify(this.kosar_id));
				this.kosarService.set_kosar_id(this.kosar_id);
				console.log(this.data);
				console.log(ar2);
				this.prodService.prodkat(this.data.id, this.lang).subscribe((data2: any) => {
					/*ga('ecommerce:addItem', {
						'id': this.data.id,                     // Transaction ID. Required.
						'name': this.data.title,    // Product name. Required.
						'sku': this.data.cikkszam,                 // SKU/code.
						'category': data2.szint1_title,         // Category or variation.
						'price': ar2,                 // Unit price.
						'quantity': this.data.darab           // Quantity.
					});
					ga('ecommerce:send');*/
				});
			});
		});
		this.commonService.menuroww("80",this.lang).subscribe((data: {}) => {
			this.kosar_menu = data[0];
		});
	  	console.log(this.data);
	}

	kosarVan(id) {
		return this.kosar_id.some(function(el) {
		  return el.termek_termekek_id === id;
		}); 
	}

}
