import { Injectable, Directive } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import{Constants } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class ProdService {
  
  	constructor(private http: HttpClient) {}

  	public extractData(res: Response) {
		let body = res;
		return body || { };
  	}
  
  	public prodlist(kat,kiem,limit,szint,lang, user): Observable<any> {
		let r = this.getRandom(9);
		//console.log(Constants.endpoint+'prod/prodlist/'+kat+'/'+kiem+'/'+limit+'/'+szint+'/'+lang+'/'+user+"/"+r);
		return this.http.get(Constants.endpoint+'prod/prodlist/'+kat+'/'+kiem+'/'+limit+'/'+szint+'/'+lang+'/'+user+"/"+r).pipe(
		map(this.extractData));
	}

	public prodszuro(from,to,szuro,user): Observable<any> {
		let r = this.getRandom(9);
		//console.log(Constants.endpoint+'prod/prodszuro/'+from+"/"+to+"/"+szuro+"/"+user+"/"+r);
		return this.http.get(Constants.endpoint+'prod/prodszuro/'+from+"/"+to+"/"+szuro+"/"+user+"/"+r).pipe(
		map(this.extractData));
	}

	public getRandom(length) {
		return Math.floor(Math.pow(10, length-1) + Math.random() * 9 * Math.pow(10, length-1));
	}
	
	public prodlengszuro(from,to,szuro,user): Observable<any> {
		let r = this.getRandom(9);
		return this.http.get(Constants.endpoint+'prod/prodlengszuro/'+from+"/"+to+"/"+szuro+"/"+user+"/"+r).pipe(
		map(this.extractData));
	}

	public netto(ar){
		let netto = (ar / 127)*100;
		return Math.round(netto);
	}
	  
	public evlist(): Observable<any> {
		return this.http.get(Constants.endpoint+'prod/evlist/1').pipe(
		map(this.extractData));
	}

	public prodlength(): Observable<any> {
		return this.http.get(Constants.endpoint+'prod/prodlength/1').pipe(
		map(this.extractData));
  	}
	  
	public csomlist(): Observable<any> {
		return this.http.get(Constants.endpoint+'prod/csomlist/1').pipe(
		map(this.extractData));
	}
	  
	public kiszlist(): Observable<any> {
		return this.http.get(Constants.endpoint+'prod/kiszlist/1').pipe(
		map(this.extractData));
	}
	  
	public prodcsom(id): Observable<any> {
		return this.http.get(Constants.endpoint+'prod/prodcsom/'+id).pipe(
		map(this.extractData));
	}
	  
	public prodkisz(id): Observable<any> {
		return this.http.get(Constants.endpoint+'prod/prodkisz/'+id).pipe(
		map(this.extractData));
	}
	  
	public prodev(id): Observable<any> {
		return this.http.get(Constants.endpoint+'prod/prodev/'+id).pipe(
		map(this.extractData));
	}

	public prodar(id): Observable<any> {
		let r = this.getRandom(9);
		return this.http.get(Constants.endpoint+'prod/prodar/'+id+"/"+r).pipe(
		map(this.extractData));
	}
	  
	public kosarba(id): Observable<any> {
		return this.http.get(Constants.endpoint+'prod/kosarba/'+id).pipe(
		map(this.extractData));
  	}

  	public prodrow(id,lang,user_tipus): Observable<any> {
		let r = this.getRandom(9);
		return this.http.get(Constants.endpoint+'prod/prodrow/'+id+'/'+lang+'/'+user_tipus+"/"+r).pipe(
		map(this.extractData));
  	}

  	public prodkat(id,lang): Observable<any> {
		return this.http.get(Constants.endpoint+'prod/prodkat/'+id+'/'+lang).pipe(
		map(this.extractData));
  	}
  
  	public prodgal(id,lang): Observable<any> {
		return this.http.get(Constants.endpoint+'prod/prodgal/'+id+'/'+lang).pipe(
		map(this.extractData));
  	}
  
  	public prodmeret(id,lang): Observable<any> {
		return this.http.get(Constants.endpoint+'prod/prodmeret/'+id+'/'+lang).pipe(
		map(this.extractData));
  	}
  
  	public prodkapcs(id,lang): Observable<any> {
		let r = this.getRandom(9);
		return this.http.get(Constants.endpoint+'prod/prodkapcs/'+id+'/'+lang+"/"+r).pipe(
		map(this.extractData));
	}
}