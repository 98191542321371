import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { ProdService } from '../../services/prod.service';
import { KatService } from '../../services/kat.service';
import { CommonService } from '../../services/common.service';
import { SyncService } from '../../services/sync.service';

@Component({
  selector: 'app-keres',
  templateUrl: './keres.component.html',
  styleUrls: ['./keres.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class KeresComponent implements OnInit  {

  	@Input() term: string;

  	filterData: any;
  	kat: any;
  	kat_ossz: any;
  	termek_ossz: any;
	szavak: any;
	localStorage: Storage;
	lang: any;
	shopcode: any;

  	constructor(public sync: SyncService,private prodService: ProdService, private katService: KatService,private commonService: CommonService) {
		this.localStorage = window.localStorage;
	}
	  
	get isLocalStorageSupported(): boolean {
		return !!this.localStorage
	}

  	ngOnInit(): void {
		this.commonService.shopcode().subscribe((data: any) => {
			this.shopcode = data.shop_code;
			if(this.localStorage.getItem(this.shopcode+"_lang") != null){
				this.lang = JSON.parse(this.localStorage.getItem(this.shopcode+"_lang"));
				this.sync.set_thread_id(this.lang);
			}
			this.commonService.getword(this.lang,"kategoriak-termekek").subscribe((data: {}) => {
				this.szavak = data;
			});
		});
  	}

  	changefilter(){
    	if(this.term != ""){
			this.commonService.shopcode().subscribe((data: any) => {
				this.shopcode = data.shop_code;
				if(this.localStorage.getItem(this.shopcode+"_lang") != null){
					this.lang = JSON.parse(this.localStorage.getItem(this.shopcode+"_lang"));
					this.sync.set_thread_id(this.lang);
				}
				this.prodService.prodlist("0","0","0","0",this.lang,"privat").subscribe((data: {}) => {
					this.filterData = data;
				});
				this.katService.katlist("0",this.lang).subscribe((data: {}) => {
					this.kat = data;
				});
			});
    	}
  	}

}
