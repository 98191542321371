import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { ProdService } from '../../services/prod.service';
import { SyncService } from '../../services/sync.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class CartComponent implements OnInit {

  	shopcode: any;
  	szavak:any;
  	penztar: any;
  	ossz_db: any = 0;
  	vegossz: any = 0;
  	localStorage: Storage;
	lang: any;
  
  	constructor(public sync: SyncService, private prodService: ProdService, private commonService: CommonService) {
		this.localStorage = window.localStorage;
    	this.sync.thread_id$.subscribe((thread_id:any) => {
      		this.ossz_db = 0;
      		this.vegossz = 0;
      		thread_id.forEach((element: any) => {
        		if(element.ar != undefined){
          			let sub_ar = element.ar * element.darab;
          			this.ossz_db = this.ossz_db + element.darab;
          			this.vegossz = this.vegossz + sub_ar;
        		}
      		});
    	});
  	}

  	ngOnInit(): void {
		this.commonService.shopcode().subscribe((data: any) => {
			this.shopcode = data.shop_code;
			if(this.localStorage.getItem(this.shopcode+"_lang") != null){
				this.lang = JSON.parse(this.localStorage.getItem(this.shopcode+"_lang"));
				this.sync.set_thread_id(this.lang);
			}
    		this.commonService.getword(this.lang,"kosaram-termek-ertekben-kosarhoz-ft").subscribe((data: {}) => {
      			this.szavak = data;
    		});
    		this.commonService.menuroww("51",this.lang).subscribe((data: {}) => {
      			this.penztar = data[0];
			});
		});	
  	}

}
