import { Component, OnInit, ViewEncapsulation, Input, Inject } from '@angular/core';
import { HirlevelService } from '../../services/hirlevel.service';
import { CommonService } from '../../services/common.service';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogComponent } from '../../components/dialog/dialog.component';
import { SyncService } from '../../services/sync.service';

@Component({
  selector: 'app-hirlevel',
  templateUrl: './hirlevel.component.html',
  styleUrls: ['./hirlevel.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class HirlevelComponent implements OnInit {

  	hirform = new FormGroup({
    	nev: new FormControl('', [
      		Validators.required
    	]),
    	email: new FormControl('',[
      		Validators.required,
      		Validators.email
    	])
  	});
  
  	szavak: any;
	submitted = false;
	localStorage: Storage;
	lang: any;
	shopcode: any;
  
  	constructor(public sync: SyncService, private hirlevelService: HirlevelService, private commonService: CommonService, private dialog: MatDialog) {
		this.localStorage = window.localStorage;
	}

	get isLocalStorageSupported(): boolean {
		return !!this.localStorage
	}

 	ngOnInit(): void {
		this.commonService.shopcode().subscribe((data: any) => {
			this.shopcode = data.shop_code;
			if(this.localStorage.getItem(this.shopcode+"_lang") != null){
				this.lang = JSON.parse(this.localStorage.getItem(this.shopcode+"_lang"));
				this.sync.set_thread_id(this.lang);
			}
			this.commonService.getword(this.lang,"hirlevel-feliratkozom-nev-email-sikeresfeliratkozas-close-kotelezo2-hibasmail").subscribe((data: {}) => {
				this.szavak = data;
			});
		});
  	}

  	get f() { return this.hirform.controls; }

  	onSubmit() {
   		this.submitted = true;
    	if (this.hirform.invalid) {
      		return;
    	}
    	else{
			this.commonService.shopcode().subscribe((data: any) => {
				this.shopcode = data.shop_code;
				if(this.localStorage.getItem(this.shopcode+"_lang") != null){
					this.lang = JSON.parse(this.localStorage.getItem(this.shopcode+"_lang"));
					this.sync.set_thread_id(this.lang);
				}
				this.hirlevelService.hir_kuld(this.hirform.value.nev,this.hirform.value.email,this.lang).subscribe((data: {}) => {
					this.dialog.open(DialogComponent, {
						data: {
							title: this.szavak[4].cim,
							gomb: this.szavak[5].cim
						}
					});
				});
			});
    	}
  	}
}