<div id="search">
    <h1 *ngIf="szavak && params" class="s_title cikk_title">{{szavak[2].cim}}: <b>{{params.id}}</b></h1>
    <div class="shop">
        <div class="kat_list">
            <app-keres></app-keres>
            <app-cart></app-cart>
            <app-katlist [parent]="0"></app-katlist>
        </div>
        <div class="term_list">	
            <ul>
                <li class="col-xs-12 col-sm-6 col-md-4" *ngFor="let item of prod">
                    <a routerLink="/{{item.alias}}"> 
                        <div class="prod_img">
                            <div class="hover_top"></div>
                            <div class="img_cont">
                                <picture>
                                    <source type="image/webp" srcset="/vision/cms/uploaded_images/product/{{item.fokep}}.webp">
                                    <source type="image/jpg" srcset="/vision/cms/uploaded_images/product/{{item.fokep}}">
                                    <img width="316" height="368" src="/vision/cms/uploaded_images/product/{{item.fokep}}" alt="{{item.alt}}"/>
                                </picture>
                            </div>
                            <div class="hover_bot"></div>
                        </div>
                        <div class="prod_name"><div><span>{{item.title}}</span></div></div>
                        <div class="prod_ar">
                            <div class="regi_ar" *ngIf="item.akcios_ar > 0">{{item.ara | number:'1.0-3'}} <span *ngIf="szavak">{{szavak[0].cim}}</span></div>
                            <div class="jelen_ar" *ngIf="item.akcios_ar > 0">{{item.ara | number:'1.0-3'}} <span *ngIf="szavak">{{szavak[0].cim}}</span></div>
                            <div class="jelen_ar" *ngIf="item.akcios_ar < 1">{{item.ara | number:'1.0-3'}} <span *ngIf="szavak">{{szavak[0].cim}}</span></div>
                        </div>
                        <div *ngIf="szavak" class="prod_tov"><span>{{szavak[1].cim}}</span></div>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>
