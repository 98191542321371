<form [formGroup]="hirform" class="hir_modul" (ngSubmit)="onSubmit()">
    <span *ngIf="szavak"><b>{{szavak[0].cim}}</b> {{szavak[1].cim}}</span>
    <div class="hir_input">
        <div class="form-group col-xs-12 col-sm-6 col-md-4">
            <div *ngIf="submitted && f.nev.errors" class="invalid-feedback">
                <div *ngIf="f.nev.errors.required">{{szavak[2].cim}} {{szavak[6].cim}}</div>
            </div>
            <input *ngIf="szavak" type="text" formControlName="nev" required placeholder="{{szavak[2].cim}}"/>
            <label *ngIf="szavak">{{szavak[2].cim}}</label>
        </div>
        <div class="form-group col-xs-12 col-sm-6 col-md-4">
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">{{szavak[3].cim}} {{szavak[6].cim}}</div>
                <div *ngIf="f.email.errors.email">{{szavak[7].cim}} {{szavak[3].cim}}</div>
            </div>
            <input *ngIf="szavak" type="email" formControlName="email" required placeholder="{{szavak[3].cim}}"/>
            <label *ngIf="szavak">{{szavak[3].cim}}</label>
        </div>
        <div class="form-group col-xs-12 col-sm-6 col-md-4">
            <button *ngIf="szavak" type="submit"><span>{{szavak[1].cim}}</span></button>
        </div>
    </div>
</form>