<div class="kapcs">
	<h1 *ngIf="kapcs">{{kapcs.title}}</h1>
	<div class="half">
		<div class="cont">
			<dynamic-html *ngIf="cikk" class="text" [content]="cikk.text"></dynamic-html>
		</div>
	</div>
	<div class="half">
		<div class="cont">
			<form [formGroup]="kapcsform" class="kapcs_modul" (ngSubmit)="onSubmit()">
				<dynamic-html *ngIf="left2" class="info" [content]="left2.text"></dynamic-html>
				<div class="form-group col-xs-12 col-sm-12 col-md-12">
					<div *ngIf="submitted && f.nev.errors" class="invalid-feedback">
						<div *ngIf="f.nev.errors.required">{{szavak[2].cim}} {{szavak[6].cim}}</div>
					</div>
					<input *ngIf="szavak" type="text" formControlName="nev" required placeholder="{{szavak[2].cim}}"/>
					<label *ngIf="szavak">{{szavak[2].cim}}</label>
				</div>
				<div class="form-group col-xs-12 col-sm-12 col-md-12">
					<div *ngIf="submitted && f.cegnev.errors" class="invalid-feedback">
						<div *ngIf="f.cegnev.errors.required">{{szavak[11].cim}} {{szavak[6].cim}}</div>
					</div>
					<input *ngIf="szavak" type="text" formControlName="cegnev" placeholder="{{szavak[11].cim}}"/>
					<label *ngIf="szavak && kapcsform.controls.cegnev.value != ''">{{szavak[11].cim}}</label>
				</div>
				<div class="form-group col-xs-12 col-sm-12 col-md-12">
					<div *ngIf="submitted && f.tel.errors" class="invalid-feedback">
						<div *ngIf="f.tel.errors.required">{{szavak[0].cim}} {{szavak[6].cim}}</div>
					</div>
					<input *ngIf="szavak" type="text" formControlName="tel" required placeholder="{{szavak[0].cim}}"/>
					<label *ngIf="szavak">{{szavak[0].cim}}</label>
				</div>
				<div class="form-group col-xs-12 col-sm-12 col-md-12">
					<div *ngIf="submitted && f.email.errors" class="invalid-feedback">
						<div *ngIf="f.email.errors.required">{{szavak[3].cim}} {{szavak[6].cim}}</div>
						<div *ngIf="f.email.errors.email">{{szavak[7].cim}} {{szavak[3].cim}}</div>
					</div>
					<input *ngIf="szavak" type="email" formControlName="email" required placeholder="{{szavak[3].cim}}"/>
					<label *ngIf="szavak">{{szavak[3].cim}}</label>
				</div>
				<div class="form-group textarea-form col-xs-12 col-sm-12 col-md-12">
					<div *ngIf="submitted && f.uzenet.errors" class="invalid-feedback">
						<div *ngIf="f.uzenet.errors.required">{{szavak[1].cim}} {{szavak[6].cim}}</div>
					</div>
					<textarea *ngIf="szavak" placeholder="{{szavak[1].cim}}" formControlName="uzenet"></textarea>
					<label *ngIf="szavak">{{szavak[1].cim}}</label>
				</div>
				<div class="csekk2 checkbox col-xs-12 col-sm-12 col-md-12">
					<div class="check">
						<input required type="checkbox" [ngClass]="{'invalidated': submitted}" required formControlName="aszf" class="radio" value="1">
						<span class="icon-check-1"></span>
					</div>
					<label *ngIf="szavak" class="label-text">{{szavak[9].cim}} <a *ngIf="menu" routerLink="/{{menu[1].alias}}" rel="noopener" target="_blank">{{szavak[10].cim}}</a></label>
				</div>				
				<re-captcha (resolved)="resolved($event)" siteKey="6LdvVcUZAAAAABLCyf43sJvYw4KNrLjg-KdSx5Hq"></re-captcha>
				<button *ngIf="szavak" type="submit" name="kapcs_kuld" class="kapcs_kuld" value="{{szavak[8].cim}}"><span>{{szavak[8].cim}}</span></button>
			</form>
		</div>
	</div>
</div>
<picture class="kapcs_map">
	<source width="1424" height="320" type="image/webp" srcset="vision/cms/uploaded_images/main/map.webp?v=31536000‬">
	<source width="1424" height="320" type="image/png" srcset="vision/cms/uploaded_images/main/map.png?v=31536000‬">
	<img width="1424" height="320" src="vision/cms/uploaded_images/main/map.png?v=31536000‬" alt="Tassi"/>
</picture>