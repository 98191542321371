<div class="m_szuro_open" [class.szuroaktiv]="szuroaktiv" (click)="m_szuro_open()">
	<span>Kategóriák</span>
</div>
<form *ngIf="szuro_kesz && katList" [formGroup]="szuro" class="szuro" (ngSubmit)="onSubmit()">
	<div class="form-group keres_input">
		<input (ngModelChange)="onfilter()" *ngIf="szavak" type="text" formControlName="nev" required placeholder="{{szavak[1].cim}}"/>
	</div>
	<ul *ngIf="katList" class="kat">
		<li *ngFor="let kat of katList">
			<span *ngIf="kat.sub.length > 0 || kat.sub != ''">{{kat.title}}</span>
			<a (click)="removeFilter()" *ngIf="(kat.sub.length === 0 || kat.sub === '') && kat.id != '1'" routerLink="/{{kat.alias}}/abc/1">{{kat.title}}</a>
			<a (click)="removeFilter()" *ngIf="(kat.sub.length === 0 || kat.sub === '') && kat.id == '1'" routerLink="/{{kat.alias}}/legujabb/1">{{kat.title}}</a>
			<input *ngIf="kat.sub.length > 0 || kat.sub != ''" type="checkbox"/>
			<b *ngIf="kat.sub.length > 0 || kat.sub != ''" class="drop_open icon-plus-1"></b>
			<b *ngIf="kat.sub.length > 0 || kat.sub != ''" class="drop_open2 icon-minus-1"></b>
			<ul class="subkat" *ngIf="kat.sub.length > 0 || kat.sub != ''">
				<li *ngFor="let sub of kat.sub">
					<a (click)="removeFilter()" routerLink="/{{sub.alias}}/abc/1">{{sub.title}}</a>
				</li>
			</ul>
		</li>
	</ul>
	<div class="price slider">
		<span *ngIf="szavak">{{szavak[3].cim}}</span>
		<ng5-slider (ngModelChange)="onfilter()" formControlName="ar" [(value)]="price" [(highValue)]="highPrice" [options]="priceOptions"></ng5-slider>
	</div>
	<div class="kisz drop">
		<span *ngIf="szavak">{{szavak[6].cim}}</span>
		<input type="checkbox"/>
		<b class="drop_open icon-plus-1"></b>
		<b class="drop_open2 icon-minus-1"></b>
		<ul *ngIf="szuro.controls.kisz && kiszlist" class="kat">
			<li *ngFor="let kat of szuro.controls.kisz['controls']; let i=index;">
				<div class="check">
					<input class="focheck" id="kisz{{kiszlist[i].id}}" type="checkbox" (ngModelChange)="onfilter()" [formControl]="kat">
					<span class="icon-check-1"></span>
				</div>
				<span>{{kiszlist[i].title}}</span>
			</li>
		</ul>
	</div>
	<div class="ev drop">
		<span *ngIf="szavak">{{szavak[7].cim}}</span>
		<input type="checkbox"/>
		<b class="drop_open icon-plus-1"></b>
		<b class="drop_open2 icon-minus-1"></b>
		<ul *ngIf="szuro.controls.evjarat && evlist" class="kat">
			<li *ngFor="let kat of szuro.controls.evjarat['controls']; let i=index;">
				<div class="check">
					<input class="focheck" id="ev{{evlist[i].id}}" type="checkbox" (ngModelChange)="onfilter()" [formControl]="kat">
					<span class="icon-check-1"></span>
				</div>
				<span>{{evlist[i].title}}</span>
			</li>
		</ul>
	</div>
	<div class="csom drop">
		<span *ngIf="szavak">{{szavak[8].cim}}</span>
		<input type="checkbox"/>
		<b class="drop_open icon-plus-1"></b>
		<b class="drop_open2 icon-minus-1"></b>
		<ul *ngIf="szuro.controls.csom && csomlist" class="kat">
			<li *ngFor="let kat of szuro.controls.csom['controls']; let i=index;">
				<div class="check">
					<input class="focheck" id="csom{{csomlist[i].id}}" type="checkbox" (ngModelChange)="onfilter()" [formControl]="kat">
					<span class="icon-check-1"></span>
				</div>
				<span>{{csomlist[i].title}}</span>
			</li>
		</ul>
	</div>
	<div class="er_ido slider">
		<span *ngIf="szavak">{{szavak[9].cim}}</span>
		<ng5-slider (ngModelChange)="onfilter()" formControlName="er_ido" [(value)]="kor" [(highValue)]="highKor" [options]="korOptions"></ng5-slider>
	</div>
	<div class="fok slider">
		<span *ngIf="szavak">{{szavak[10].cim}}</span>
		<ng5-slider (ngModelChange)="onfilter()" formControlName="alk_fok" [(value)]="fok" [(highValue)]="highFok" [options]="fokOptions"></ng5-slider>
	</div>
	<br/>
	<div class="limit">
		<div class="check">
			<input (ngModelChange)="onfilter()" type="checkbox" formControlName="limit" value="1"/>
			<span class="icon-check-1"></span>
		</div>
		<span *ngIf="szavak">{{szavak[11].cim}}</span>
		<small *ngIf="szavak" (click)="szuro_null()">{{szavak[12].cim}}</small>
	</div>
	<br/>
	<button type="submit" name="szuro_kuld" class="szuro_kuld" value="1"><span *ngIf="szavak">{{szavak[2].cim}}</span></button>
</form>
