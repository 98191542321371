import { Injectable, Directive } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import{Constants } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class GalleryService {
  
  	constructor(private http: HttpClient) {}

  	public extractData(res: Response) {
		let body = res;
		return body || { };
  	}
  
  	public getgallery(id,lang): Observable<any> {
		return this.http.get(Constants.endpoint+'gallery/getgallery/'+id+'/'+lang).pipe(
		map(this.extractData));
	}
}
