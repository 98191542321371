<div class="kapcs">
	<h1 *ngIf="kapcs">{{kapcs.title}}</h1>
	<div id="cikk">
		<dynamic-html *ngIf="cikk" class="cont" [content]="cikk.text"></dynamic-html>
	</div>
	<form [formGroup]="kapcsform" class="kapcs_modul" (ngSubmit)="onSubmit()">
		<div class="form-group col-xs-12 col-sm-12 col-md-12">
			<div *ngIf="submitted && f.nev.errors" class="invalid-feedback">
				<div *ngIf="f.nev.errors.required">{{szavak[2].cim}} {{szavak[6].cim}}</div>
			</div>
			<input *ngIf="szavak" type="text" formControlName="nev" required placeholder="{{szavak[2].cim}}"/>
			<label *ngIf="szavak">{{szavak[2].cim}}</label>
		</div>
		<div class="form-group col-xs-12 col-sm-12 col-md-12">
			<div *ngIf="submitted && f.tel.errors" class="invalid-feedback">
				<div *ngIf="f.tel.errors.required">{{szavak[0].cim}} {{szavak[6].cim}}</div>
			</div>
			<input *ngIf="szavak" type="text" formControlName="tel" required placeholder="{{szavak[0].cim}}"/>
			<label *ngIf="szavak">{{szavak[0].cim}}</label>
		</div>
		<div class="form-group col-xs-12 col-sm-12 col-md-12">
			<div *ngIf="submitted && f.email.errors" class="invalid-feedback">
				<div *ngIf="f.email.errors.required">{{szavak[3].cim}} {{szavak[6].cim}}</div>
				<div *ngIf="f.email.errors.email">{{szavak[7].cim}} {{szavak[3].cim}}</div>
			</div>
			<input *ngIf="szavak" type="email" formControlName="email" required placeholder="{{szavak[3].cim}}"/>
			<label *ngIf="szavak">{{szavak[3].cim}}</label>
		</div>
		<div class="form-group textarea-form col-xs-12 col-sm-12 col-md-12">
			<div *ngIf="submitted && f.uzenet.errors" class="invalid-feedback">
				<div *ngIf="f.uzenet.errors.required">{{szavak[6].cim}}</div>
			</div>
			<textarea required *ngIf="szavak" placeholder="Milyen egyedi ital kérésben segíthetünk?" formControlName="uzenet"></textarea>
			<label *ngIf="szavak">Milyen egyedi ital kérésben segíthetünk?</label>
		</div>
		<div class="csekk2 checkbox col-xs-12 col-sm-12 col-md-12">
			<div class="check">
				<input required type="checkbox" [ngClass]="{'invalidated': submitted}" required formControlName="aszf" class="radio" value="1">
				<span class="icon-check-1"></span>
			</div>
			<label *ngIf="szavak" class="label-text">{{szavak[9].cim}} <a *ngIf="menu" routerLink="/{{menu[1].alias}}" rel="noopener" target="_blank">{{szavak[10].cim}}</a></label>
		</div>				
		<re-captcha (resolved)="resolved($event)" siteKey="6LdvVcUZAAAAABLCyf43sJvYw4KNrLjg-KdSx5Hq"></re-captcha>
		<button *ngIf="szavak" type="submit" name="kapcs_kuld" class="kapcs_kuld" value="{{szavak[8].cim}}"><span>{{szavak[8].cim}}</span></button>
	</form>
</div>
