<li *ngFor="let row of prod" [class.sublist]="sub === 1" class="col-xs-12 col-sm-6 col-md-3">
	<div class="prodcont">
		<a routerLink="/{{row.alias}}"> 
			<div *ngIf="row.black_friday == '1'" class="black_friday">
				<picture>
					<source type="image/webp" srcset="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/main/bf.webp">
					<source type="image/jpg" srcset="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/main/bf.png">
					<img src="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/main/bf.png" alt="black friday"/>
				</picture>
			</div>
			<div *ngIf="user_tipus === 'ceg' && row.arak_count>0 && (row.ara_ceg == '' || row.ara_ceg == 0 ||  row.ara_ceg == '0')" class="menny_kedv">
				<span *ngIf="szavak">{{szavak[2].cim}}</span>
			</div>
			<div *ngIf="user_tipus === 'ceg' && szavak && row.ara_ceg != '' && row.ara_ceg > 0" class="akc_szalag">
				<span *ngIf="szavak">{{szavak[5].cim}}</span>
			</div>
			<div *ngIf="user_tipus === 'privat' && szavak && row.lak_akcio != '' && row.lak_akcio > 0" class="akc_szalag">
				<span *ngIf="szavak">{{szavak[5].cim}}</span>
			</div>
			<div *ngIf="szavak && row.uj === true" class="uj_szalag">
				<span *ngIf="szavak">{{szavak[6].cim}}</span>
			</div>
			<div class="product_image">
				<picture *ngIf="row.fokep">
					<source type="image/webp" srcset="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/product/{{row.fokep}}.webp">
					<source type="image/jpg" srcset="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/product/{{row.fokep}}">
					<img src="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/product/{{row.fokep}}" alt="{{row.title}}"/>
				</picture>
			</div>
			<div *ngIf="row.title" class="term_nev">
				{{row.title}}
			</div>
			<dynamic-html class="term_leir" [content]="row.leir | stripTags: 'pre'"></dynamic-html>
			<div *ngIf="user_tipus === 'privat'" class="ar_cont">
				<div *ngIf="row.lak_akcio != '' || row.lak_akcio > 0" class="akcios">
					<div *ngIf="szavak" class="eredeti_ar">
						{{szavak[3].cim}}: <small>{{row.lak_ar | number:'1.0-3' | numberFormat}} <span *ngIf="szavak">{{szavak[0].cim}}</span></small>
					</div>
					<div class="akcios_ar">
						{{row.lak_akcio | number:'1.0-3' | numberFormat}} <span *ngIf="szavak">{{szavak[0].cim}}</span>
					</div>
				</div>
				<div *ngIf="row.lak_akcio === '' || row.lak_akcio === 0" class="nem_akcios">
					<div *ngIf="szavak" class="eredeti_ar">
						{{szavak[4].cim}}:
					</div>
					<div class="akcios_ar sima_ar">
						{{row.lak_ar | number:'1.0-3' | numberFormat}} <span *ngIf="szavak">{{szavak[0].cim}}</span>
					</div>
				</div>
			</div>
			<div *ngIf="user_tipus === 'ceg'" class="ar_cont">
				<div *ngIf="row.ara_ceg != '' || row.ara_ceg > 0" class="akcios">
					<div *ngIf="szavak" class="eredeti_ar">
						{{szavak[3].cim}}: <small>{{row.ara_magan | number:'1.0-3' | numberFormat}} <span *ngIf="szavak">{{szavak[0].cim}}</span></small>
					</div>
					<div class="akcios_ar">
						{{row.ara_ceg | number:'1.0-3' | numberFormat}} <span *ngIf="szavak">{{szavak[0].cim}}</span>
					</div>
				</div>
				<div *ngIf="row.ara_ceg === '' || row.ara_ceg === 0" class="nem_akcios">
					<div *ngIf="szavak" class="eredeti_ar">
						{{szavak[4].cim}}:
					</div>
					<div class="akcios_ar sima_ar">
						{{row.ara_magan | number:'1.0-3' | numberFormat}} <span *ngIf="szavak">{{szavak[0].cim}}</span>
					</div>
				</div>
			</div>
			<div class="prod_arlista" *ngIf="user_tipus === 'ceg' && row.arak_count>0 && (row.ara_ceg == '' || row.ara_ceg == 0 ||  row.ara_ceg == '0')">
				<div class="cent">
					<span *ngFor="let item2 of row.arak" >
						<i>{{item2.arak_db}}</i> <b *ngIf="szavak">{{szavak[7].cim}}</b> {{item2.arak_magan | number:'1.0-3' | numberFormat}} <b *ngIf="szavak">{{szavak[0].cim}}</b>
					</span>
				</div>
			</div>
		</a>
		<div *ngIf="row.keszlet > 0 && szavak" class="kosarba" (click)="kosarba(row.id, row.title, row.fokep, row.kisz_db, row.cikkszam, row.ara_magan, row.ara_ceg, row.lak_ar, row.lak_akcio)"><span>{{szavak[1].cim}}</span></div>
		<div *ngIf="row.keszlet === 0 && szavak" class="kosarba disabled" ><span>{{szavak[1].cim}}</span></div>
	</div>
</li>
			