import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SyncService {

  	private thread_id = new Subject<number>();
  	thread_id$ = this.thread_id.asObservable();

  	set_thread_id(thread_id: number) {
		this.thread_id.next(thread_id);
	  }
	  
	private szuro_nev = new Subject<number>();
	szuro_nev$ = this.szuro_nev.asObservable();

  	set_szuro_nev(szuro_nev: number) {
		this.szuro_nev.next(szuro_nev);
  	}

  	constructor() { }
}
