import { Component, Input, Output, EventEmitter, OnInit, Renderer2, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ProdService } from '../../services/prod.service';
import { SyncService } from '../../services/sync.service';
import { FormGroup, FormControl} from '@angular/forms';
import { CommonService } from '../../services/common.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	moduleId: module.id,
  	selector: 'pagination',
 	templateUrl: './pagination.component.html',
  	styleUrls: ['./pagination.component.sass'],
  	encapsulation: ViewEncapsulation.None
})

export class PaginationComponent implements OnInit {


	@Input() from: any;
	@Input() url: any;
	@Input() kat: any;
	@Input() order: any;
	@Input() kiem: any;
	@Input() limit: any;
	@Input() szint: any;
	@Input() usertipus: any;
	szuroform = new FormGroup({
		nev: new FormControl('', []),
		kat: new FormControl('', []),
		order: new FormControl('', []),
		ar: new FormControl([0,2999990]),
		kisz: new FormControl('', []),
		evjarat: new FormControl('', []),
		csom: new FormControl('', []),
		er_ido: new FormControl([0,100]),
		alk_fok: new FormControl([0,100]),
		limit: new FormControl('', []),
		prodlength: new FormControl(),
		from: new FormControl(0),
		leng: new FormControl('', []),
		selectedEvjarat: new FormControl('', []),
		selectedCsom: new FormControl('', []),
		selectedKisz: new FormControl('', []),
		activePage: new FormControl('', []),
		last: new FormControl('', []),
		first: new FormControl('', [])
	});
	szuro_json: any;
	prod: any;
	pages: Array<number> = [];
	currentpages: Array<number> = [];
	leng: any = 10;
	maxpages: any = 9;
	activePage: any = 1;
	start: any = false;
	last: any = false;
	first: any = true;
	shopcode: any;
	localStorage: any;
	szuro_store: any;
	UrlWithoutPage: any = "";
	next: any;
	prev: any;
	lang = "hu";
	italok: any;
	
	constructor(private rtr: Router, private renderer: Renderer2, private commonService: CommonService, public sync: SyncService, private prodService: ProdService) {
		this.localStorage = window.localStorage;
		this.sync.thread_id$.subscribe((thread_id:any) => {
			this.szuro_json = thread_id;
			this.first = thread_id.first;
			this.last = thread_id.last;
			this.activePage = Number(this.from);
			this.getProd(this.szuro_json);
			let params = this.url.split("/");
			let newUrl = this.italok;
			//console.log(params);
			for(let item of params){
				if(isNaN(item)){
					newUrl = newUrl+"/"+item;
				}
				//newUrl = newUrl+"/"+item;
			}
			this.UrlWithoutPage = newUrl;
		});
	}

	getPages(current){
		this.currentpages = Array();
		let rem = this.leng - current;
		let bot = 0;
		let top = 0;
		if(current < 6){
			if(this.leng === 1){
				bot = 1;
				top = 1;
			}
			if(this.leng === 2){
				bot = 1;
				top = 2;
			}
			if(this.leng === 3){
				bot = 1;
				top = 3;
			}
			if(this.leng === 4){
				bot = 1;
				top = 4;
			}
			if(this.leng === 5){
				bot = 1;
				top = 5;
			}
			if(this.leng > 5 && current < 5){
				bot = 1;
				top = 5;
			}
			if(this.leng > 5 && current === 5){
				bot = 2;
				top = 6;
			}
		}
		else{
			if(rem >= 2){
				bot = current-2;
				top = current+2;
			}
			if(rem === 0){
				top = this.leng;
				bot = current - 5;
				this.last = true;
			}
			if(rem === 1){
				top = current+1;
				bot = current - 3;
			}
			if(rem > 1 || rem === 1){
				top = this.leng;
				bot = current-4;
			}
		}
		if(rem > 1 || rem === 1){
			this.last = true;
		}
		else{
			this.last = false;
		}
		if(current === 1 || current < 1){
			this.first = false;
		}
		for(let i = bot ;i <= top;i++){
			this.currentpages.push(i);
		}
	}

	sortpage(a, b) {
		return a > b ? 1 : b > a ? -1 : 0;
	}

	getProd(szuro){
		let limit_kuld = '';
		if(this.kiem === 1){
			limit_kuld = "fasz";
		}
		else{
			limit_kuld = szuro.limit;
		}
		this.szuroform.patchValue({
			nev: szuro.nev,
			ar: szuro.ar,
			kisz: szuro.kisz,
			evjarat: szuro.evjarat,
			csom: szuro.csom,
			er_ido: szuro.er_ido,
			alk_fok: szuro.alk_fok,
			limit: limit_kuld,
			kat: this.kat,
			from: Number(this.from),
			order: this.order,
			selectedEvjarat: szuro.selectedEvjarat,
			selectedCsom: szuro.selectedCsom,
			selectedKisz: szuro.selectedKisz
		});
		let szuro_string = btoa(unescape(encodeURIComponent(JSON.stringify(this.szuroform.value))));
		let n = 0;
		if(Number(this.from) != 1){
			n = Number(this.from);
		}
		this.prodService.prodszuro(n,this.limit,szuro_string,this.usertipus).subscribe((data: {}) => {
			this.szuroform.patchValue({
				prodlength: data[1]
			});
			this.prod = data[0];
			this.leng = Math.ceil(this.szuroform.controls.prodlength.value / 9);
			this.szuroform.patchValue({
				leng: this.leng
			});
			if(this.start === false){
				for(let i = 0;i<this.leng;i++){
					this.pages.push(i);
				}
				if(this.leng>6){
					for(let i = 1;i<6;i++){
						this.currentpages.push(i);
					}
				}
				else{
					for(let i = 1;i<this.leng;i++){
						this.currentpages.push(i);
					}
				}
			}
			if(this.leng < 1 || this.from > 1){
				this.renderer.addClass(document.body, 'no-prod');
			}
			else{
				this.renderer.removeClass(document.body, 'no-prod');
			}
			this.start = true;
			this.getPages(Number(this.from));
		});
	}
	
	ngOnInit(): void {
		this.rtr.routeReuseStrategy.shouldReuseRoute = () => false;
		let params = this.url.split("/").slice(0,-1);
		params.shift();
		this.activePage = Number(this.from);
		this.next = Number(this.from)+1;
		this.prev = Number(this.from)-1;
		this.commonService.menuroww("17","hu").subscribe((data: {}) => {
			this.italok = data[0].alias
			let newUrl = data[0].alias;
			for(let item of params){
				if(isNaN(item)){
					newUrl = newUrl+"/"+item;
				}
				//newUrl = newUrl+"/"+item;
			}
			this.UrlWithoutPage = newUrl;
		});
		this.commonService.shopcode().subscribe((data: any) => {
			this.shopcode = data.shop_code;
			if(this.localStorage.getItem(this.shopcode+"_szuro") != null){
				this.szuro_store = JSON.parse(this.localStorage.getItem(this.shopcode+"_szuro"));
				this.getProd(this.szuro_store);
			}
			else{
				this.getProd(this.szuroform.value);
			}
		});
  	}
}
