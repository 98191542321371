import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BlogService } from '../../services/blog.service';
import { CommonService } from '../../services/common.service';
import { Meta, Title } from '@angular/platform-browser';
import { SyncService } from '../../services/sync.service';

@Component({
  selector: 'app-blogcikk',
  templateUrl: './blogcikk.component.html',
  styleUrls: ['./blogcikk.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class BlogcikkComponent implements OnInit {

  	params: any;
  	cikk: any;
  	cimke: any;
  	szavak: any;
  	siteurl: any;
	menu: any;
	localStorage: Storage;
	lang: any = "hu";
	shopcode: any;
	seotitle: any;
  
  	constructor(private route: Router, public sync: SyncService,private title: Title, private meta: Meta, private router: ActivatedRoute, private blogService: BlogService, private commonService: CommonService) {
    	this.router.paramMap.subscribe(params => {
      		this.getData();
		});
		this.localStorage = window.localStorage;
	}

	get isLocalStorageSupported(): boolean {
		return !!this.localStorage
	}
	

  	ngOnInit(): void {
    	this.commonService.getword(this.lang,"blogback").subscribe((data: {}) => {
      		this.szavak = data;
		});
		this.commonService.siteurl().subscribe((data: any) => {
			this.siteurl = data.siteurl;
		});
		this.commonService.seotitle().subscribe((data: any) => {
			this.seotitle = data.title;
		});
  	}

  	getData() {
    	this.router.paramMap.subscribe((params:any) => {
      		this.params = params.params;
		});
		this.blogService.getblogcikk(this.params.id,this.lang).subscribe((data: []) => {
			this.cikk = data;
			if(this.cikk.letezik === "true"){
				this.commonService.menuroww("53",this.lang).subscribe((data: {}) => {
					this.menu = data[0];
					let url = this.siteurl+this.menu.alias+"/"+this.cikk.alias;
					let kepnev = this.siteurl+"vision/cms/uploaded_images/blog/"+this.cikk.kep;
					this.commonService.seo(this.seotitle,'article',this.cikk.title,this.cikk.desc,url,kepnev);
					this.commonService.schema("blogcikk","hu",this.params.id).subscribe((data2: any) => {
						this.commonService.insertSchema(data2);
					});
				});
			}
			else{
				//this.route.navigate(["/404"]);
			}
		});
    	this.blogService.cimke(this.params.id,this.lang).subscribe((data: []) => {
			this.cimke = data;
		});
  	}
}
