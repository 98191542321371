<div class="shop kat_shop">
	<div class="cont">
		<div class="top" *ngIf="katmenu">
			<div>Italshop</div>
			<div class="main_kat" *ngIf="fokat">
				<i class="sep icon-right-open-mini"></i>
				<a routerLink="/{{katmenu.alias}}/{{fokat.alias}}/abc/1">{{fokat.title}}</a>
			</div>
			<div class="main_kat" *ngIf="fokat && alkat && alkat.length > 0">
				<i class="sep icon-right-open-mini"></i>
				<a routerLink="/{{katmenu.alias}}/{{fokat.alias}}/{{alkat[0].alias}}/{{order}}/{{lap}}">{{alkat[0].title}}</a>
			</div>
		</div>
		
		<div class="szuro_kat">
			<app-szuro></app-szuro>
			<!--nincs se kategoria se szuro-->
			<app-katlist *ngIf="alkat === false && fokat === false && !szuro_check" [parent]="0" ></app-katlist>
			<!--alkategóriak-->
			<app-katlist *ngIf="alkat === false && fokat != false" [parent]="fokat.termek_kategoriak_id" ></app-katlist>
			<!--termek lista kategoriaval-->
			<div *ngIf="fokat && alkat" #cont class="termlist">
				<div class="pag pagtop">
					<div class="cont">
						<div class="rend icon-down-dir">
							<input (click)="openRend()"type="checkbox" />
							<span>Rendezés</span>
							<ul *ngIf="katmenu" [class.closed]="closed === true">
								<li><a routerLink="/{{katmenu.alias}}/{{url}}/abc/{{lap}}">ABC</a></li>
								<li><a routerLink="/{{katmenu.alias}}/{{url}}/ar-csokkeno/{{lap}}">Ár csökkenő</a></li>
								<li><a routerLink="/{{katmenu.alias}}/{{url}}/ar-novekvo/{{lap}}">Ár növekvő</a></li>
								<li><a routerLink="/{{katmenu.alias}}/{{url}}/legujabb/{{lap}}">Legújabb</a></li>
							</ul>
						</div>
						<pagination *ngIf="currentKat != '' && order != ''" [usertipus]="user_tipus" [order]="order" [kat]="currentKat" [url]="fullUrl" [from]="lap" [kiem]="0" [limit]="0" [szint]="0"></pagination>
					</div>
				</div>
				<app-prodlist *ngIf="moreplus != true && currentKat != '' && order != ''" [order]="order" [sub]="1" [from]="lap" [to]="9" [szuro]="szurostring" [kat]="currentKat" [kiem]="0" [limit]="9" [szint]="0"></app-prodlist>
				<li *ngFor="let row of prod" class="sublist col-xs-12 col-sm-6 col-md-3">
					<div class="prodcont">
						<a routerLink="/{{row.alias}}"> 
							<div *ngIf="row.black_friday == '1'" class="black_friday">
								<picture>
									<source type="image/webp" srcset="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/main/bf.webp">
									<source type="image/jpg" srcset="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/main/bf.png">
									<img src="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/main/bf.png" alt="black friday"/>
								</picture>
							</div>
							<div *ngIf="user_tipus === 'ceg' && row.arak_count>0 && (row.ara_ceg == '' || row.ara_ceg == 0 ||  row.ara_ceg == '0')" class="menny_kedv">
								<span *ngIf="szavak2">{{szavak2[2].cim}}</span>
							</div>
							<div *ngIf="user_tipus === 'ceg' && szavak2 && row.ara_ceg != '' && row.ara_ceg > 0" class="akc_szalag">
								<span *ngIf="szavak">{{szavak2[5].cim}}</span>
							</div>
							<div *ngIf="user_tipus === 'privat' && szavak2 && row.lak_akcio != '' && row.lak_akcio > 0" class="akc_szalag">
								<span *ngIf="szavak">{{szavak2[5].cim}}</span>
							</div>
							<div *ngIf="szavak2 && row.uj === true" class="uj_szalag">
								<span *ngIf="szavak2">{{szavak2[6].cim}}</span>
							</div>
							<div class="product_image">
								<picture *ngIf="row.fokep">
									<source type="image/webp" srcset="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/product/{{row.fokep}}.webp">
									<source type="image/jpg" srcset="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/product/{{row.fokep}}">
									<img src="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/product/{{row.fokep}}" alt="{{row.title}}"/>
								</picture>
							</div>
							<div *ngIf="row.title" class="term_nev">
								{{row.title}}
							</div>
							<dynamic-html class="term_leir" [content]="row.leir | stripTags: 'pre'"></dynamic-html>
							<div *ngIf="user_tipus === 'privat'" class="ar_cont">
								<div *ngIf="row.lak_akcio != '' || row.lak_akcio > 0" class="akcios">
									<div *ngIf="szavak2" class="eredeti_ar">
										{{szavak2[3].cim}}: <small>{{row.lak_ar | number:'1.0-3' | numberFormat}} <span *ngIf="szavak2">{{szavak2[0].cim}}</span></small>
									</div>
									<div class="akcios_ar">
										{{row.lak_akcio | number:'1.0-3' | numberFormat}} <span *ngIf="szavak2">{{szavak2[0].cim}}</span>
									</div>
								</div>
								<div *ngIf="row.lak_akcio === '' || row.lak_akcio === 0" class="nem_akcios">
									<div *ngIf="szavak2" class="eredeti_ar">
										{{szavak2[4].cim}}:
									</div>
									<div class="akcios_ar sima_ar">
										{{row.lak_ar | number:'1.0-3' | numberFormat}} <span *ngIf="szavak2">{{szavak2[0].cim}}</span>
									</div>
								</div>
							</div>
							<div *ngIf="user_tipus === 'ceg'" class="ar_cont">
								<div *ngIf="row.ara_ceg != '' || row.ara_ceg > 0" class="akcios">
									<div *ngIf="szavak2" class="eredeti_ar">
										{{szavak2[3].cim}}: <small>{{row.ara_magan | number:'1.0-3' | numberFormat}} <span *ngIf="szavak2">{{szavak2[0].cim}}</span></small>
									</div>
									<div class="akcios_ar">
										{{row.ara_ceg | number:'1.0-3' | numberFormat}} <span *ngIf="szavak2">{{szavak[0].cim}}</span>
									</div>
								</div>
								<div *ngIf="row.ara_ceg === '' || row.ara_ceg === 0" class="nem_akcios">
									<div *ngIf="szavak2" class="eredeti_ar">
										{{szavak2[4].cim}}:
									</div>
									<div class="akcios_ar sima_ar">
										{{row.ara_magan | number:'1.0-3' | numberFormat}} <span *ngIf="szavak2">{{szavak[0].cim}}</span>
									</div>
								</div>
							</div>
							<div class="prod_arlista" *ngIf="user_tipus === 'ceg' && row.arak_count>0 && (row.ara_ceg == '' || row.ara_ceg == 0 ||  row.ara_ceg == '0')">
								<div class="cent">
									<span *ngFor="let item2 of row.arak" >
										<i>{{item2.arak_db}}</i> <b *ngIf="szavak2">{{szavak2[7].cim}}</b> {{item2.arak_magan | number:'1.0-3' | numberFormat}} <b *ngIf="szavak2">{{szavak2[0].cim}}</b>
									</span>
								</div>
							</div>
						</a>
						<div *ngIf="row.keszlet > 0 && szavak2" class="kosarba" (click)="kosarba(row.id, row.title, row.fokep, row.kisz_db, row.cikkszam, row.ara_magan, row.ara_ceg, row.lak_ar, row.lak_akcio)"><span>{{szavak2[1].cim}}</span></div>
						<div *ngIf="row.keszlet === 0 && szavak2" class="kosarba disabled" ><span>{{szavak2[1].cim}}</span></div>
					</div>
				</li>				
				<div id="scrollMe" #scrollMe class="pag pagbot">
					<div class="next_list" *ngIf="van_tovabb === true" (click)="more()">Továbbiak megtekintése</div>
					<pagination *ngIf="currentKat != '' && order != ''" [order]="order" [kat]="currentKat" [url]="fullUrl" [from]="lap" [kiem]="0" [limit]="0" [szint]="0"></pagination>
				</div>
				<div id="cikk">
					<dynamic-html *ngIf="katleir" class="cont" [content]="katleir"></dynamic-html>
				</div>
			</div>
			<!--termek lista kategoria nelkul szurovel-->
			<div *ngIf="fokat === false && alkat === false && szuro_check" class="termlist">
				<div class="pag pagtop">
					<div class="cont">
						<div class="rend icon-down-dir">
							<input (click)="openRend()"type="checkbox" />
							<span>Rendezés</span>
							<ul *ngIf="katmenu" [class.closed]="closed === true">
								<li><a routerLink="/{{katmenu.alias}}/{{url}}/abc/{{lap}}">ABC</a></li>
								<li><a routerLink="/{{katmenu.alias}}/{{url}}/ar-csokkeno/{{lap}}">Ár csökkenő</a></li>
								<li><a routerLink="/{{katmenu.alias}}/{{url}}/ar-novekvo/{{lap}}">Ár növekvő</a></li>
								<li><a routerLink="/{{katmenu.alias}}/{{url}}/legujabb/{{lap}}">Legújabb</a></li>
							</ul>
						</div>
						<pagination *ngIf="currentKat != '' && order != ''" [order]="order" [kat]="currentKat" [url]="fullUrl" [from]="lap" [kiem]="0" [limit]="0" [szint]="0"></pagination>
					</div>
				</div>
				<app-prodlist *ngIf="moreplus != true && currentKat != '' && order != ''" [order]="order" [sub]="1" [from]="lap" [to]="9" [szuro]="szurostring" [kat]="currentKat" [kiem]="0" [limit]="9" [szint]="0"></app-prodlist>
				<li *ngFor="let row of prod" class="sublist col-xs-12 col-sm-6 col-md-3">
					<div class="prodcont">
						<a routerLink="/{{row.alias}}"> 
							<div *ngIf="row.black_friday == '1'" class="black_friday">
								<picture>
									<source type="image/webp" srcset="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/main/bf.webp">
									<source type="image/jpg" srcset="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/main/bf.png">
									<img src="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/main/bf.png" alt="black friday"/>
								</picture>
							</div>
							<div *ngIf="user_tipus === 'ceg' && row.arak_count>0 && (row.ara_ceg == '' || row.ara_ceg == 0 ||  row.ara_ceg == '0')" class="menny_kedv">
								<span *ngIf="szavak2">{{szavak2[2].cim}}</span>
							</div>
							<div *ngIf="user_tipus === 'ceg' && szavak2 && row.ara_ceg != '' && row.ara_ceg > 0" class="akc_szalag">
								<span *ngIf="szavak2">{{szavak2[5].cim}}</span>
							</div>
							<div *ngIf="user_tipus === 'privat' && szavak2 && row.lak_akcio != '' && row.lak_akcio > 0" class="akc_szalag">
								<span *ngIf="szavak2">{{szavak2[5].cim}}</span>
							</div>
							<div *ngIf="szavak2 && row.uj === true" class="uj_szalag">
								<span *ngIf="szavak2">{{szavak2[6].cim}}</span>
							</div>
							<div class="product_image">
								<picture *ngIf="row.fokep">
									<source type="image/webp" srcset="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/product/{{row.fokep}}.webp">
									<source type="image/jpg" srcset="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/product/{{row.fokep}}">
									<img src="https://tassilive-16e91.kxcdn.com/vision/cms/uploaded_images/product/{{row.fokep}}" alt="{{row.title}}"/>
								</picture>
							</div>
							<div *ngIf="row.title" class="term_nev">
								{{row.title}}
							</div>
							<dynamic-html class="term_leir" [content]="row.leir | stripTags: 'pre'"></dynamic-html>
							<div *ngIf="user_tipus === 'privat'" class="ar_cont">
								<div *ngIf="row.lak_akcio != '' || row.lak_akcio > 0" class="akcios">
									<div *ngIf="szavak2" class="eredeti_ar">
										{{szavak2[3].cim}}: <small>{{row.lak_ar | number:'1.0-3' | numberFormat}} <span *ngIf="szavak2">{{szavak2[0].cim}}</span></small>
									</div>
									<div class="akcios_ar">
										{{row.lak_akcio | number:'1.0-3' | numberFormat}} <span *ngIf="szavak2">{{szavak2[0].cim}}</span>
									</div>
								</div>
								<div *ngIf="row.lak_akcio === '' || row.lak_akcio === 0" class="nem_akcios">
									<div *ngIf="szavak2" class="eredeti_ar">
										{{szavak2[4].cim}}:
									</div>
									<div class="akcios_ar sima_ar">
										{{row.lak_ar | number:'1.0-3' | numberFormat}} <span *ngIf="szavak2">{{szavak2[0].cim}}</span>
									</div>
								</div>
							</div>
							<div *ngIf="user_tipus === 'ceg'" class="ar_cont">
								<div *ngIf="row.ara_ceg != '' || row.ara_ceg > 0" class="akcios">
									<div *ngIf="szavak2" class="eredeti_ar">
										{{szavak2[3].cim}}: <small>{{row.ara_magan | number:'1.0-3' | numberFormat}} <span *ngIf="szavak2">{{szavak2[0].cim}}</span></small>
									</div>
									<div class="akcios_ar">
										{{row.ara_ceg | number:'1.0-3' | numberFormat}} <span *ngIf="szavak2">{{szavak2[0].cim}}</span>
									</div>
								</div>
								<div *ngIf="row.ara_ceg === '' || row.ara_ceg === 0" class="nem_akcios">
									<div *ngIf="szavak2" class="eredeti_ar">
										{{szavak2[4].cim}}:
									</div>
									<div class="akcios_ar sima_ar">
										{{row.ara_magan | number:'1.0-3' | numberFormat}} <span *ngIf="szavak2">{{szavak2[0].cim}}</span>
									</div>
								</div>
							</div>
							<div class="prod_arlista" *ngIf="user_tipus === 'ceg' && row.arak_count>0 && (row.ara_ceg == '' || row.ara_ceg == 0 ||  row.ara_ceg == '0')">
								<div class="cent">
									<span *ngFor="let item2 of row.arak" >
										<i>{{item2.arak_db}}</i> <b *ngIf="szavak2">{{szavak2[7].cim}}</b> {{item2.arak_magan | number:'1.0-3' | numberFormat}} <b *ngIf="szavak2">{{szavak2[0].cim}}</b>
									</span>
								</div>
							</div>
						</a>
						<div *ngIf="row.keszlet > 0 && szavak2" class="kosarba" (click)="kosarba(row.id, row.title, row.fokep, row.kisz_db, row.cikkszam, row.ara_magan, row.ara_ceg, row.lak_ar, row.lak_akcio)"><span>{{szavak2[1].cim}}</span></div>
						<div *ngIf="row.keszlet === 0 && szavak2" class="kosarba disabled" ><span>{{szavak2[1].cim}}</span></div>
					</div>
				</li>				
				<div id="scrollMe" #scrollMe class="pag pagbot">
					<div class="next_list" *ngIf="van_tovabb === true" (click)="more()">Továbbiak megtekintése</div>
					<pagination *ngIf="currentKat != '' && order != ''" [order]="order" [usertipus]="user_tipus" [kat]="currentKat" [url]="fullUrl" [from]="lap" [kiem]="0" [limit]="0" [szint]="0"></pagination>
				</div>
				<div id="cikk">
					<dynamic-html *ngIf="katleir" class="cont" [content]="katleir"></dynamic-html>
				</div>
			</div>
		</div>
	</div>
</div>