<div *ngIf="ossz_db > 0" class="cart">
    <div>
        <span *ngIf="szavak">{{szavak[0].cim}}</span>
        {{ossz_db}} <b *ngIf="szavak">{{szavak[1].cim}}</b>
        <br/>
        <i *ngIf="vegossz">
            {{vegossz | number:'1.0-3'}}<small *ngIf="szavak"> {{szavak[4].cim}}</small>
        </i><b *ngIf="szavak"> {{szavak[2].cim}}</b>
    </div>
    <a *ngIf="penztar" routerLink="/{{penztar.alias}}"><span *ngIf="szavak">{{szavak[3].cim}}</span></a>
</div>