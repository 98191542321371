import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { ProdService } from '../../services/prod.service';
import { CommonService } from '../../services/common.service';
import { KatService } from '../../services/kat.service';
import { SyncService } from '../../services/sync.service';
import { FormGroup, FormControl} from '@angular/forms';
import { ActivatedRoute, Router, NavigationStart, NavigationExtras } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { KosarbaComponent } from '../../components/kosarba/kosarba.component';
import { NumberFormatPipe } from '../../number.pipe';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-prodlist',
  templateUrl: './prodlist.component.html',
  styleUrls: ['./prodlist.component.sass'],
  encapsulation: ViewEncapsulation.None,
  providers: [NumberFormatPipe]
})

export class ProdlistComponent implements OnInit {

  	prod:any;
  	szavak: any;
  	@Input() kat: any;
  	@Input() kiem: any;
  	@Input() limit: any;
	@Input() szint: any;
	@Input() szuro: any;
	@Input() from: any;
	@Input() to: any;
	@Input() sub: any;
	@Input() order: any;
	lang: any = "hu";
	szuro_json: any;
	localStorage: any;
	shopcode: any;
	szuro_store: any;
	szuro_set: any = false;
	userInfo: any;
	user_tipus: any;

	szuroform = new FormGroup({
		nev: new FormControl('', []),
		kat: new FormControl('', []),
		ar: new FormControl([0,2999990]),
		kisz: new FormControl('', []),
		evjarat: new FormControl('', []),
		csom: new FormControl('', []),
		er_ido: new FormControl([0,100]),
		alk_fok: new FormControl([0,100]),
		limit: new FormControl('', []),
		from: new FormControl(0),
		leng: new FormControl('', []),
		order: new FormControl('', []),
		selectedEvjarat: new FormControl('', []),
		selectedCsom: new FormControl('', []),
		selectedKisz: new FormControl('', [])
	});

	params: any;
	katmenu: any;
	fokat:any;
	alkat:any;


	getParams(params){
		this.params = params.params;
		this.commonService.menuroww("17",this.lang).subscribe((data: {}) => {
			this.katmenu = data[0];
			if(this.params.fokat != undefined){
				this.katService.katrow(this.params.fokat,0,this.lang).subscribe((data: {}) => {
					this.fokat = data[0];
					if(this.fokat != undefined){
						if(this.params.alkat != undefined){
							this.katService.katrow(this.params.alkat,this.fokat.termek_kategoriak_id,this.lang).subscribe((data2: any) => {
								this.alkat = data2;
								if(data2.length > 0){
									this.kat = this.alkat[0].termek_kategoriak_id;
									this.ngOnInit();
								}
								else{
									this.kat = this.fokat.termek_kategoriak_id;
									this.ngOnInit();
								}
							});
						}
						else{
							
						}
					}
					else{
						this.kat = "0";
						this.ngOnInit();
					}
				});
			}
			else{
				this.kat = "0";
				this.ngOnInit();
			}
		});
	}
	
  	constructor(public user: UserService, private router: ActivatedRoute, private formatPipe: NumberFormatPipe, public dialog: MatDialog,private route: Router, public sync: SyncService, private katService: KatService, private prodService: ProdService, private commonService: CommonService) {
		this.localStorage = window.localStorage;
		this.router.paramMap.subscribe((params:any) => {
			this.getParams(params);
			//this.ngOnInit();
		});
		this.sync.thread_id$.subscribe((thread_id:any) => {
			thread_id.kat = this.kat;
			thread_id.order = this.order;
			this.szuro_json = thread_id;

			this.getProd(this.szuro_json);
		});
		this.user.userInfo$.subscribe((userInfo:any) => {
			this.userInfo = userInfo;
			this.user_tipus = this.userInfo.users_tipus;
		});
	}

	kosarba(id,title,img,db,cikkszam,ara_magan,ara_ceg,lak_ar,lak_akcio){
		this.dialog.open(KosarbaComponent, {
			data: {
				title: title,
				img: img,
				id: id,
				darab: db,
				users_tipus: this.user_tipus,
				cikkszam: cikkszam,
				ara_magan: ara_magan,
				ara_ceg: ara_ceg,
				lak_ar: lak_ar,
				lak_akcio: lak_akcio
			}
		});
	}

	getProd(szuro){
		let limit_kuld = '';
		if(this.kiem === 1){
			limit_kuld = "fasz";
		}
		else{
			limit_kuld = szuro.limit;
		}
		this.szuroform.patchValue({
			nev: szuro.nev,
			kat: this.kat,
			ar: szuro.ar,
			kisz: szuro.kisz,
			evjarat: szuro.evjarat,
			csom: szuro.csom,
			er_ido: szuro.er_ido,
			alk_fok: szuro.alk_fok,
			limit: limit_kuld,
			from: Number(this.from),
			leng: szuro.leng,
			order: this.order,
			selectedEvjarat: szuro.selectedEvjarat,
			selectedCsom: szuro.selectedCsom,
			selectedKisz: szuro.selectedKisz
		});
		let szuro_string = btoa(unescape(encodeURIComponent(JSON.stringify(this.szuroform.value))));
		let n = 0;
		if(Number(this.from) != 1){
			n = Number(this.from);
		}
		this.prodService.prodszuro(n,this.limit,szuro_string, this.user_tipus).subscribe((data: {}) => {
			this.prod = data[0];
			for(let item of this.prod){
				if(item.kisz_db === 0){
					item.kisz_db = 1;
				}
			}
		});
	}

  	ngOnInit(): void {
		this.commonService.shopcode().subscribe((data: any) => {
			if(this.localStorage.getItem(this.shopcode+"_u") != null){
				this.userInfo = JSON.parse(this.localStorage.getItem(this.shopcode+"_u"));
				this.user_tipus = this.userInfo.users_tipus;
			}
			else{
				this.user_tipus = 'privat';
			}
			this.shopcode = data.shop_code;
			if(this.localStorage.getItem(this.shopcode+"_szuro") != null){
				this.szuro_store = JSON.parse(this.localStorage.getItem(this.shopcode+"_szuro"));
				this.getProd(this.szuro_store);
			}
			else{
				if(this.from > 1){
					this.limit = this.from+"_paged";
				}
				this.prodService.prodlist(this.kat,this.kiem,this.limit,this.order,this.lang, this.user_tipus).subscribe((data: {}) => {
					//console.log(data);
					this.prod = data[0];
					for(let item of this.prod){
						if(item.kisz_db === 0){
							item.kisz_db = 1;
						}
					}
					//this.sync.set_thread_id(this.szuroform.value);
				});
			}
		});
		this.commonService.getword(this.lang,"ft-kosarba-menny_kedv-eredetiar-ar-akcios_szalag-uj_szalag-uvegtol").subscribe((data: {}) => {
			this.szavak = data;
	  	});
  	}
}
