import { Component, OnInit, ViewEncapsulation, HostListener, Inject } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { MenuService } from '../../services/menu.service';
import { SyncService } from '../../services/sync.service';
import { BlogService } from '../../services/blog.service';
import { KatService } from '../../services/kat.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements OnInit {

  	szavak: any;
	localStorage: Storage;
	lang: any = "hu";
	shopcode: any;
	face: any;
	insta: any;
	menu: any;
	footer1: any;
	telep: any;
	scrolled: boolean = false;
	
  	constructor(@Inject(DOCUMENT) private document: Document, private katService: KatService, private blogService: BlogService, public sync: SyncService, private menuService: MenuService, private commonService: CommonService) {
		this.localStorage = window.localStorage;
	}

	@HostListener('window:scroll', [])
  	onWindowScroll() {
    	if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
      		this.scrolled = true;
    	}
		else{
			this.scrolled = false;
		}
  	}

	get isLocalStorageSupported(): boolean {
		return !!this.localStorage
	}

	toTop(){
		(function smoothscroll() {
            var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
            if (currentScroll > 0) {
                window.scrollTo(0, 0);
            }
        })();
	}

  	ngOnInit(): void {
		this.commonService.menuroww("19",this.lang).subscribe((data: {}) => {
			this.telep = data[0];
		});
		this.commonService.getword(this.lang,"tassicopy").subscribe((data: {}) => {
			this.szavak = data;
		});
		this.commonService.face().subscribe((data: {}) => {
			this.face = data;
		});
		this.commonService.insta().subscribe((data: {}) => {
			this.insta = data;
		});
		this.menuService.getmenu(this.lang,"14","0").subscribe((data: {}) => {
			this.menu = data;
		});
		this.commonService.getmodul(this.lang,"footer1").subscribe((data: {}) => {
			this.footer1 = data[0];
		});
 	}

}
