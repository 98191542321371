import { Component, OnInit, ViewEncapsulation, Input} from '@angular/core';
import { KatService } from '../../services/kat.service';
import { SyncService } from '../../services/sync.service';
import { CommonService } from '../../services/common.service';
import { FormGroup, FormControl} from '@angular/forms';
import { Router} from '@angular/router';


@Component({
  selector: 'app-katlist',
  templateUrl: './katlist.component.html',
  styleUrls: ['./katlist.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class KatlistComponent implements OnInit {

	@Input() parent: any;
	@Input() sub: any;
	katList: any;
	localStorage: Storage;
	lang: any = "hu";
	shopcode: any;
	termek: any;

	szuro = new FormGroup({
		nev: new FormControl('', []),
		kat: new FormControl('',[]),
		ar: new FormControl([16450,2999990]),
		evjarat: new FormControl('',[]),
		csom: new FormControl('',[]),
		kisz: new FormControl('',[]),
		er_ido: new FormControl([0,100]),
		alk_fok: new FormControl([0,100]),
		limit: new FormControl('', []),
		prodlength: new FormControl('',[]),
		from: new FormControl([0]),
		order: new FormControl([1]),
		selectedKat: new FormControl('',[]),
		selectedCsom: new FormControl('',[]),
		selectedKisz: new FormControl('',[]),
		selectedEvjarat: new FormControl('',[])
	});

  	constructor(private router:Router, private commonService: CommonService, public sync: SyncService, private katService: KatService) {
		this.localStorage = window.localStorage;
		this.sync.thread_id$.subscribe((thread_id:any) => {
			this.katService.katlist(this.parent,this.lang).subscribe((data: {}) => {
				this.katList = data;
			});
		});
	}

	get isLocalStorageSupported(): boolean {
		return !!this.localStorage
	}

  	ngOnInit(): void {
		this.commonService.menuroww("17",this.lang).subscribe((data: {}) => {
			this.termek = data[0];
		});
		this.katService.katlist(this.parent,this.lang).subscribe((data: {}) => {
			this.katList = data;
		});
  	}

}
