import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import { CommonService } from '../../services/common.service';
import { Meta, Title } from '@angular/platform-browser';
import { SyncService } from '../../services/sync.service';
import { CikkService } from '../../services/cikk.service';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogComponent } from '../../components/dialog/dialog.component';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { UserService } from '../../services/user.service';
import { PasswordStrengthValidator } from '../../password.validator';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { Constants } from '../../constants';
import { HttpClient } from '@angular/common/http';
import { NgModel } from '@angular/forms';
import { MatSelect } from '@angular/material/select';


@Component({
 	selector: 'app-newpw',
  	templateUrl: './newpw.component.html',
  	styleUrls: ['./newpw.component.sass', '../reg/reg.component.sass'],
	encapsulation: ViewEncapsulation.None
})
export class NewpwComponent implements OnInit {

  	szavak: any;
  	siteurl: any;
	localStorage: Storage;
	lang: any = "hu";
	shopcode: any;
	reg: any;
	error: any = true;
	user: any;
	hiba: any = "";
	validpw: any = false;
	submitted: any = false;
	loading: any = false;
	uid: any;
	userInfo: any;
	newpw: any;

	get isLocalStorageSupported(): boolean {
		return !!this.localStorage
	}
	
	regform = new FormGroup({
		pw: new FormControl('',[
			Validators.required,
			Validators.minLength(6),
			PasswordStrengthValidator
		]),
		pw2: new FormControl('',[
			Validators.required,
			Validators.minLength(6),
			PasswordStrengthValidator
		])
	});

	mustMatch() {
		if(this.regform.controls.pw.value != '' && this.regform.controls.pw2.value != ''){
			if(this.regform.controls.pw2.value === this.regform.controls.pw.value){
				this.validpw = true;
				return true;
			}
			else{
				this.validpw = false;
				return false;
			}
		}
		else{
			this.validpw = false;
			return false;
		}
	}

	onSubmitPW() {
		console.log(this.regform.value);
		this.error = true;
		this.submitted = true;
		Object.keys(this.regform.controls).forEach(key => {
			if(this.regform.controls[key].errors != null){
				this.error = false;
			}
		});
		if (this.error === false) {
			return;
		}
		else{
			this.loading = true;
			this.userService.pwmod(btoa(unescape(encodeURIComponent(JSON.stringify(this.regform.value)))),this.uid).subscribe((data: any) => {
				this.commonService.shopcode().subscribe((data: any) => {
					this.shopcode = data.shop_code;
					this.userService.getuser(this.uid).subscribe((data: any) => {
						this.user = data[0];
						this.localStorage.setItem(this.shopcode+"_u", JSON.stringify(this.user));
						this.userService.set_userInfo(this.user);
						this.submitted = false;
						this.regform.reset();
						this.loading = false;
						this.route.navigate(["sikeres-adatmodositas"]);
					});
				});
			});
		}
	}

	constructor(private userService: UserService, public dialog: MatDialog, private route: Router, private title: Title, private meta: Meta, private commonService: CommonService) {
		this.localStorage = window.localStorage;
	}

	get f() { return this.regform.controls; }

  	ngOnInit(): void {
		this.commonService.shopcode().subscribe((data: any) => {
			this.shopcode = data.shop_code;
			if(this.localStorage.getItem(this.shopcode+"_u") != null){
				this.userInfo = JSON.parse(this.localStorage.getItem(this.shopcode+"_u"));
				this.uid = this.userInfo.users_id;
				console.log(this.uid);
			}
		});
		this.commonService.getword(this.lang,"magan-viszont-kotelezo-veznev-kernev-cegnev-kapcsnev-tel-email-pw-pw2-hibas-minkar-nagysz-ketj-adoszam-muke-tallozas-szamlazasi_cim-irsz-varos-utcahsz-szallitasi_cim-szallitasi_azonos_szamlazasival-cim-elfogadom-reg").subscribe((data: {}) => {
			this.szavak = data;
		});
		this.commonService.menuroww("79-41",this.lang).subscribe((data: {}) => {
			this.newpw = data[0];
			this.reg = data[1];
			this.commonService.siteurl().subscribe((data: any) => {
			  this.siteurl = data;
			  this.title.setTitle(this.newpw.title);
			  this.meta.updateTag(
				  { name: 'description', content: this.newpw.desc }
			  );
			  this.meta.updateTag(
				  { name: 'og:title', content: this.newpw.seotitle }
			  );
			  this.meta.updateTag(
				  { name: 'og:description', content: this.newpw.desc }
			  );
			  this.meta.updateTag(
				  { name: 'dc.publisher', content: this.newpw.seotitle }
			  );
			  this.meta.updateTag(
				  { name: 'dc.title', content: this.newpw.seotitle }
			  );
			  this.meta.updateTag(
				  { name: 'dc.description', content: this.newpw.desc }
			  );
			  this.meta.updateTag(
				  { name: 'og:url', content: this.siteurl.siteurl+this.newpw.alias }
			  );
			});
	  	});
	}

}
