import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router} from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { CommonService } from '../../services/common.service';
import { SyncService } from '../../services/sync.service';
import { CikkService } from '../../services/cikk.service';
import { UserService } from '../../services/user.service';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogComponent } from '../../components/dialog/dialog.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {

  	szavak: any;
  	siteurl: any;
	localStorage: Storage;
	lang: any = "hu";
	alias: any;
	login: any;
	cikk: any; 
	submitted = false;
	token: string = "false";
	shopcode: any;
	error: any = true;
	user: any;
	hiba: any = "";
	forgot: any;
	reg: any;
	seotitle: any;
	favicon: any;
	
	loginform = new FormGroup({
    	email: new FormControl('', [Validators.email]),
		pw: new FormControl('', [Validators.required]),
	});
	
	constructor(public dialog: MatDialog, private router:Router, private userService: UserService, private cikkService: CikkService, public sync: SyncService,private title: Title, private meta: Meta, private commonService: CommonService) {
		this.localStorage = window.localStorage;
	}

	get isLocalStorageSupported(): boolean {
		return !!this.localStorage
	}

	get f() { return this.loginform.controls; }

  	resolved(captchaResponse: string) {
    	this.token = captchaResponse;
	}

	onSubmit() {
		this.submitted = true;
		this.hiba = "";
		this.error = true;
    	Object.keys(this.loginform.controls).forEach(key => {
			if(this.loginform.controls[key].errors != null){
				this.error = false;
				if(key == "email"){
					if(this.loginform.controls[key].errors.required == true){
						//this.hiba = this.hiba + "E-mail cím kitöltése kötelező "
					}
					if(this.loginform.controls[key].errors.email == true){
						//this.hiba = this.hiba + "Hibás e-mail cím formátum "
					}
				}
				if(key == "pw"){
					if(this.loginform.controls[key].errors.required == true){
						//this.hiba = this.hiba + "Jelszó kitöltése kötelező "
					}
				}	
			}
		});
    	if (this.error === false) {
			/*this.dialog.open(DialogComponent, {
				data: {
					title: 'Hiba',
					text: this.hiba,
					gomb: 'bezárás'
				}
			});*/
			return;
    	}
    	else{
			this.commonService.shopcode().subscribe((data: any) => {
				this.shopcode = data.shop_code;
      			this.userService.login(this.token, btoa(unescape(encodeURIComponent(JSON.stringify(this.loginform.value)))),this.lang).subscribe((data: any) => {
					console.log(data[0]);
					if(data['hiba'].length === 0){
						this.user = data[0];
						this.user.users_tel1 = this.user.users_tel1.substring(1, this.user.users_tel1.length-1);
						this.localStorage.setItem(this.shopcode+"_u", JSON.stringify(this.user));
						this.userService.set_userInfo(this.user);
						this.router.navigate(["/"]);
					}
					else{
						data['hiba'].forEach((item: any) =>{
							this.hiba = this.hiba + item
						});
						this.dialog.open(DialogComponent, {
							data: {
								title: this.hiba,
								gomb: 'bezárás'
							}
						});  
						return;
					}
				});
			});
    	}
	}

  	ngOnInit(): void {
		this.cikkService.getcikk(this.router.url.substr(1),this.lang).subscribe((data: {}) => {
			this.cikk = data;
		});
		this.commonService.getword(this.lang,"nem_vasar").subscribe((data: {}) => {
			this.szavak = data;
		});
		this.commonService.favicon().subscribe((data: any) => {
			this.favicon = data.favicon;
		});
		this.commonService.seotitle().subscribe((data: any) => {
			this.seotitle = data.title;
		});
		this.commonService.siteurl().subscribe((data: any) => {
			this.siteurl = data.siteurl;
		});
		this.commonService.menuroww("40-54-41",this.lang).subscribe((data: {}) => {
			this.login = data[0];
			let kepnev = this.favicon;
			let url = this.siteurl+this.login.alias;
			this.commonService.seo(this.seotitle,'website',this.login.seotitle,this.login.desc,url,kepnev);
			this.forgot = data[1];
			this.reg = data[2];
	  	});
  	}

}
