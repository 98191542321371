<div class="keres_form">
    <input id="search_word" type="text" placeholder="Keresett kifejezés" [(ngModel)]="term" (ngModelChange)="changefilter()">
    <button class="icon-search-8" type="submit" value="" name="search"></button>
</div>
<div *ngIf="term" class="s_pop">
    <div class='kat_s'>
        <span *ngIf="kat">{{szavak[0].cim}} <b>{{(kat | filter:term).length}}</b></span>
        <ul class='s_kat_list'>
            <li *ngFor="let item of kat | filter:term">
                <a routerLink="/{{item.alias}}">{{item.title}}</a>
            </li>
        </ul>
    </div>
    <div class='termek_s'>
        <span *ngIf="filterData">{{szavak[1].cim}} <b>{{(filterData | filter:term).length}}</b></span>
        <ul class='s_term_list'>
            <li *ngFor="let item of filterData | filter:term | slice:0:20">
                <a routerLink="/{{item.alias}}"> 
                    <img width='58' height='68' src='vision/cms/uploaded_images/product/{{item.fokep}}'/>
                    <div>
                        <span>{{item.title}}</span>
                    </div>
                    <b *ngIf="item.akcios_ar != '' || item.akcios_ar > 0">{{item.akcios_ar}} Ft</b>
                    <b *ngIf="item.akcios_ar === '' || item.akcios_ar < 1">{{item.ara}} Ft</b>
                </a>
            </li>
        </ul>
</div>
