<div *ngIf="loading" class="load">
	<img width="80" src="vision/cms/uploaded_images/main/loading.gif?v=31536000‬" alt="Tassi és Társa Kft. - Ital Kis- és Nagykereskedés megrendelés küldés"/>
</div>
<form [formGroup]="regform" class="reg csakreg" *ngIf="szavak" (ngSubmit)="onSubmit()">
	<h1 *ngIf="alias === false && reg">{{this.reg.title}}</h1>
	<div class="reg_sep" *ngIf="alias === false">
		<dynamic-html *ngIf="cikk" class="cont" [content]="cikk.text"></dynamic-html>
		<div class="list">
			<a *ngIf="szavak && magan && reg" routerLink="/{{reg.alias}}/{{magan.alias}}"><span>{{szavak[0].cim}}</span></a>
			<a *ngIf="szavak && viszont && reg" routerLink="/{{reg.alias}}/{{viszont.alias}}"><span>{{szavak[1].cim}}</span></a>
		</div>
	</div>
	<div class="reg_cont" *ngIf="alias != false">
		<h1 *ngIf="alias != false && reg && regtype">{{this.reg.title}} {{regtype.cim}}</h1>
		<dynamic-html *ngIf="cikk" class="cont" [content]="cikk.text"></dynamic-html>
		<div class="input_cont">
			<div *ngIf="alias === 'maganszemely'" class="form-group col-xs-12 col-sm-12 col-md-12">
				<div *ngIf="submitted && f.veznev.errors" class="invalid-feedback">
					<div *ngIf="f.veznev.errors.required">{{szavak[3].cim}} {{szavak[2].cim}}</div>
				</div>
				<input *ngIf="szavak" type="text" formControlName="veznev" required placeholder="{{szavak[3].cim}}*"/>
				<label *ngIf="szavak">{{szavak[3].cim}}*</label>
			</div>
			<div *ngIf="alias === 'maganszemely'" class="form-group col-xs-12 col-sm-12 col-md-12">
				<div *ngIf="submitted && f.kernev.errors" class="invalid-feedback">
					<div *ngIf="f.kernev.errors.required">{{szavak[4].cim}} {{szavak[2].cim}}</div>
				</div>
				<input *ngIf="szavak" type="text" formControlName="kernev" required placeholder="{{szavak[4].cim}}*"/>
				<label *ngIf="szavak">{{szavak[4].cim}}*</label>
			</div>
			<div *ngIf="alias === 'viszontelado'" class="form-group col-xs-12 col-sm-12 col-md-12">
				<div *ngIf="submitted && f.cegnev.errors" class="invalid-feedback">
					<div *ngIf="f.cegnev.errors.required">{{szavak[5].cim}} {{szavak[2].cim}}</div>
				</div>
				<input *ngIf="szavak" type="text" formControlName="cegnev" required placeholder="{{szavak[5].cim}}*"/>
				<label *ngIf="szavak">{{szavak[5].cim}}*</label>
			</div>
			<div *ngIf="alias === 'viszontelado'" class="form-group col-xs-12 col-sm-12 col-md-12">
				<div *ngIf="submitted && f.kapcs.errors" class="invalid-feedback">
					<div *ngIf="f.kapcs.errors.required">{{szavak[6].cim}} {{szavak[2].cim}}</div>
				</div>
				<input *ngIf="szavak" type="text" formControlName="kapcs" required placeholder="{{szavak[6].cim}}*"/>
				<label *ngIf="szavak">{{szavak[6].cim}}*</label>
			</div>
			<div class="form-group col-xs-12 col-sm-12 col-md-12">
				<div *ngIf="submitted && f.tel.errors" class="invalid-feedback">
					<div *ngIf="f.tel.errors.required">{{szavak[7].cim}} {{szavak[2].cim}}</div>
				</div>
				<input *ngIf="szavak" type="text" formControlName="tel" required placeholder="{{szavak[7].cim}}*"/>
				<label *ngIf="szavak">{{szavak[7].cim}}*</label>
			</div>

			<div class="form-group col-xs-12 col-sm-12 col-md-12">
				<div *ngIf="submitted && f.email.errors" class="invalid-feedback">
					<div *ngIf="f.email.errors.required">{{szavak[2].cim}}</div>
					<div *ngIf="f.email.errors.email">{{szavak[11].cim}} {{szavak[8].cim}}</div>
				</div>
				<div *ngIf="!submitted && f.email.errors && f.email.touched" class="invalid-feedback">
					<div *ngIf="f.email.errors.required">{{szavak[2].cim}}</div>
					<div *ngIf="f.email.errors.email">{{szavak[11].cim}} {{szavak[8].cim}}</div>
				</div>
				<input *ngIf="szavak" type="email" formControlName="email" required placeholder="{{szavak[8].cim}}*"/>
				<label *ngIf="szavak">{{szavak[8].cim}}*</label>
			</div>
			<div class="form-group col-xs-12 col-sm-12 col-md-12">
				<div *ngIf="submitted && f.pw.errors" class="invalid-feedback">
					<div *ngIf="f.pw.errors.required">{{szavak[2].cim}} <span *ngIf="f.pw.errors.passwordStrength">!</span></div>
					<div *ngIf="f.pw.errors.minlength" class="minpw">{{szavak[12].cim}} <span *ngIf="f.pw.errors.passwordStrength">!</span></div>
					<div *ngIf="f.pw.errors.passwordStrength">{{szavak[13].cim}}!</div>
				</div>
				<input *ngIf="szavak" autocomplete="none" type="password" formControlName="pw" required placeholder="{{szavak[9].cim}}*"/>
				<label *ngIf="szavak">{{szavak[9].cim}}*</label>
			</div>
			<div class="form-group col-xs-12 col-sm-12 col-md-12">
				<div *ngIf="submitted && f.pw2.errors" class="invalid-feedback">
					<div *ngIf="f.pw2.errors.required">{{szavak[2].cim}} <span *ngIf="f.pw2.errors.passwordStrength">!</span></div>
					<div class="minpw" *ngIf="f.pw2.errors.minlength">{{szavak[12].cim}} <span *ngIf="f.pw2.errors.passwordStrength">!</span></div>
					<div *ngIf="f.pw2.errors.passwordStrength">{{szavak[13].cim}} <span *ngIf="f.pw2.errors.passwordStrength">!</span></div>
					<div  *ngIf="submitted && mustMatch() === false">{{szavak[14].cim}}!</div>
				</div>
				<input *ngIf="szavak" autocomplete="none" type="password" formControlName="pw2" required placeholder="{{szavak[10].cim}}*"/>
				<label *ngIf="szavak">{{szavak[10].cim}}*</label>
			</div>
			<div *ngIf="alias === 'viszontelado'" class="form-group col-xs-12 col-sm-12 col-md-12">
				<div *ngIf="submitted && f.adoszam.errors" class="invalid-feedback">
					<div *ngIf="f.adoszam.errors.required">{{szavak[15].cim}} {{szavak[2].cim}}</div>
				</div>
				<input mask="00000000-0-00" *ngIf="szavak" type="text" formControlName="adoszam" class="ado_input" required placeholder="{{szavak[15].cim}}*"/>
				<label *ngIf="szavak">{{szavak[15].cim}}*</label>
			</div>
			<div *ngIf="alias === 'viszontelado'" class="file col-xs-12 col-sm-12 col-md-12">
				<small *ngIf="szavak">(kizárólag <b>PDF formátumban</b> tölthető fel!)</small>
				<div class="cont">
					<input type="file" [class.subbedfalse]="submitted && f.szall_0_mukodes.errors" formControlName="szall_0_mukodes" required (change)="onFileChange($event)" />
					<label *ngIf="!regform.controls.szall_0_mukodes_src.value">{{szavak[16].cim}}*</label>
					<label *ngIf="regform.controls.szall_0_mukodes_src.value">{{regform.controls.szall_0_mukodes_src.value.name}}</label>
					<span class="up">{{szavak[17].cim}}</span>
				</div>
			</div>
			<br/><br/>
			<h2 *ngIf="szavak">{{szavak[18].cim}}</h2>
			<div *ngIf="alias === 'maganszemely'" class="form-group col-xs-12 col-sm-12 col-md-12">
				<div *ngIf="submitted && f.szam_veznev.errors" class="invalid-feedback">
					<div *ngIf="f.szam_veznev.errors.required">{{szavak[3].cim}} {{szavak[2].cim}}</div>
				</div>
				<input *ngIf="szavak" type="text" formControlName="szam_veznev" required placeholder="{{szavak[3].cim}}*"/>
				<label *ngIf="szavak">{{szavak[3].cim}}*</label>
			</div>
			<div *ngIf="alias === 'maganszemely'" class="form-group col-xs-12 col-sm-12 col-md-12">
				<div *ngIf="submitted && f.szam_kernev.errors" class="invalid-feedback">
					<div *ngIf="f.szam_kernev.errors.required">{{szavak[4].cim}} {{szavak[2].cim}}</div>
				</div>
				<input *ngIf="szavak" type="text" formControlName="szam_kernev" required placeholder="{{szavak[4].cim}}*"/>
				<label *ngIf="szavak">{{szavak[4].cim}}*</label>
			</div>
			<div *ngIf="alias === 'viszontelado'" class="form-group col-xs-12 col-sm-12 col-md-12">
				<div *ngIf="submitted && f.szam_cegnev.errors" class="invalid-feedback">
					<div *ngIf="f.szam_cegnev.errors.required">{{szavak[5].cim}} {{szavak[2].cim}}</div>
				</div>
				<input *ngIf="szavak" type="text" formControlName="szam_cegnev" required placeholder="{{szavak[5].cim}}*"/>
				<label *ngIf="szavak">{{szavak[5].cim}}*</label>
			</div>
			<div *ngIf="alias === 'viszontelado'" class="form-group col-xs-12 col-sm-12 col-md-12">
				<div *ngIf="submitted && f.szam_ado.errors" class="invalid-feedback">
					<div *ngIf="f.szam_ado.errors.required">{{szavak[15].cim}} {{szavak[2].cim}}</div>
				</div>
				<input mask="00000000-0-00" *ngIf="szavak" type="text" formControlName="szam_ado" class="ado_input" required placeholder="{{szavak[15].cim}}*"/>
				<label *ngIf="szavak">{{szavak[15].cim}}*</label>
			</div>
			<div class="cim form-group col-xs-12 col-sm-12 col-md-12">
				<div class="form-group col-xs-12 col-sm-4 col-md-4">
					<div *ngIf="submitted && f.szam_irsz.errors" class="invalid-feedback">
						<div *ngIf="f.szam_irsz.errors.required">{{szavak[19].cim}} {{szavak[2].cim}}</div>
					</div>
					<input *ngIf="szavak" type="text" formControlName="szam_irsz" mask="0000" class="irsz_input" required placeholder="{{szavak[19].cim}}*"/>
					<label *ngIf="szavak">{{szavak[19].cim}}*</label>
				</div>
				<div class="form-group col-xs-12 col-sm-8 col-md-8">
					<div *ngIf="submitted && f.szam_varos.errors" class="invalid-feedback">
						<div *ngIf="f.szam_varos.errors.required">{{szavak[20].cim}} {{szavak[2].cim}}</div>
					</div>
					<input *ngIf="szavak" type="text" formControlName="szam_varos" required placeholder="{{szavak[20].cim}}*"/>
					<label *ngIf="szavak">{{szavak[20].cim}}*</label>
				</div>
			</div>
			<div class="cim form-group col-xs-12 col-sm-12 col-md-12">
				<div *ngIf="submitted && f.szam_utca.errors" class="invalid-feedback">
					<div *ngIf="f.szam_utca.errors.required">{{szavak[21].cim}} {{szavak[2].cim}}</div>
				</div>
				<input *ngIf="szavak" type="text" formControlName="szam_utca" required placeholder="{{szavak[21].cim}}*"/>
				<label *ngIf="szavak">{{szavak[21].cim}}*</label>
			</div>
			<br/><br/>
			<h2 *ngIf="szavak">{{szavak[22].cim}}</h2>
			<div *ngIf="alias === 'maganszemely'" class="magan_szall_cont">
				<div class="checkbox">
					<div class="check"> 
						<input type="checkbox" (click)="egyezik()"/>
						<span class="icon-check-1"></span>
					</div>
					<label *ngIf="szavak">Megegyezik a számlázási adatokkal</label>
				</div>
				<div class="form-group col-xs-12 col-sm-12 col-md-12">
					<div *ngIf="submitted && f.szall_veznev.errors" class="invalid-feedback">
						<div *ngIf="f.szall_veznev.errors.required">{{szavak[3].cim}} {{szavak[2].cim}}</div>
					</div>
					<input *ngIf="szavak" type="text" formControlName="szall_veznev" required placeholder="{{szavak[3].cim}}*"/>
					<label *ngIf="szavak">{{szavak[3].cim}}*</label>
				</div>
				<div class="form-group col-xs-12 col-sm-12 col-md-12">
					<div *ngIf="submitted && f.szall_kernev.errors" class="invalid-feedback">
						<div *ngIf="f.szall_kernev.errors.required">{{szavak[4].cim}} {{szavak[2].cim}}</div>
					</div>
					<input *ngIf="szavak" type="text" formControlName="szall_kernev" required placeholder="{{szavak[4].cim}}*"/>
					<label *ngIf="szavak">{{szavak[4].cim}}*</label>
				</div>
				<div class="cim form-group col-xs-12 col-sm-12 col-md-12">
					<div class="form-group col-xs-12 col-sm-4 col-md-4">
						<div *ngIf="submitted && f.szall_irsz.errors" class="invalid-feedback">
							<div *ngIf="f.szall_irsz.errors.required">{{szavak[19].cim}} {{szavak[2].cim}}</div>
						</div>
						<input *ngIf="szavak" type="text" formControlName="szall_irsz" mask="0000" class="irsz_input" required placeholder="{{szavak[19].cim}}*"/>
						<label *ngIf="szavak">{{szavak[19].cim}}*</label>
					</div>
					<div class="form-group col-xs-12 col-sm-8 col-md-8">
						<div *ngIf="submitted && f.szall_varos.errors" class="invalid-feedback">
							<div *ngIf="f.szall_varos.errors.required">{{szavak[20].cim}} {{szavak[2].cim}}</div>
						</div>
						<input *ngIf="szavak" type="text" formControlName="szall_varos" required placeholder="{{szavak[20].cim}}*"/>
						<label *ngIf="szavak">{{szavak[20].cim}}*</label>
					</div>
				</div>
				<div class="cim form-group col-xs-12 col-sm-12 col-md-12">
					<div *ngIf="submitted && f.szall_utca.errors" class="invalid-feedback">
						<div *ngIf="f.szall_utca.errors.required">{{szavak[21].cim}} {{szavak[2].cim}}</div>
					</div>
					<input *ngIf="szavak" type="text" formControlName="szall_utca" required placeholder="{{szavak[21].cim}}*"/>
					<label *ngIf="szavak">{{szavak[21].cim}}*</label>
				</div>
			</div>
			<div *ngIf="alias === 'viszontelado'" class="ceg_szall_cont">
				<div class="checkbox">
					<div class="check"> 
						<input type="checkbox" (click)="egyezik()"/>
						<span class="icon-check-1"></span>
					</div>
					<label *ngIf="szavak">Megegyezik a számlázási adatokkal</label>
				</div>
				<h3 *ngIf="szavak" [class.open]="szall_1_show === true" (click)="openCim(1)">
					1. {{szavak[24].cim}}
				</h3>
				<div class="szall_cim_cont" >
					<div class="form-group col-xs-12 col-sm-12 col-md-12">
						<div *ngIf="submitted && f.szall_1_cegnev.errors" class="invalid-feedback">
							<div *ngIf="f.szall_1_cegnev.errors.required">{{szavak[5].cim}} {{szavak[2].cim}}</div>
						</div>
						<input required *ngIf="szavak" type="text" formControlName="szall_1_cegnev"  placeholder="{{szavak[5].cim}}"/>
						<label *ngIf="szavak && regform.controls.szall_1_cegnev.value != ''">{{szavak[5].cim}}</label>
					</div>
					<div class="cim form-group col-xs-12 col-sm-12 col-md-12">
						<div class="form-group col-xs-12 col-sm-4 col-md-4">
							<div *ngIf="submitted && f.szall_1_irsz.errors" class="invalid-feedback">
								<div *ngIf="f.szall_1_irsz.errors.required">{{szavak[19].cim}} {{szavak[2].cim}}</div>
							</div>
							<input required *ngIf="szavak" type="text" formControlName="szall_1_irsz"  mask="0000" class="irsz_input" placeholder="{{szavak[19].cim}}"/>
							<label *ngIf="szavak && regform.controls.szall_1_irsz.value != ''">{{szavak[19].cim}}</label>
						</div>
						<div class="form-group col-xs-12 col-sm-8 col-md-8">
							<div *ngIf="submitted && f.szall_1_varos.errors" class="invalid-feedback">
								<div *ngIf="f.szall_1_varos.errors.required">{{szavak[20].cim}} {{szavak[2].cim}}</div>
							</div>
							<input required *ngIf="szavak" type="text" formControlName="szall_1_varos"  placeholder="{{szavak[20].cim}}"/>
							<label *ngIf="szavak && regform.controls.szall_1_varos.value != ''">{{szavak[20].cim}}</label>
						</div>
					</div>
					<div class="cim form-group col-xs-12 col-sm-12 col-md-12">
						<div *ngIf="submitted && f.szall_1_utca.errors" class="invalid-feedback">
							<div *ngIf="f.szall_1_utca.errors.required">{{szavak[21].cim}} {{szavak[2].cim}}</div>
						</div>
						<input required *ngIf="szavak" type="text" formControlName="szall_1_utca"  placeholder="{{szavak[21].cim}}"/>
						<label *ngIf="szavak && regform.controls.szall_1_utca.value != ''">{{szavak[21].cim}}</label>
					</div>
					<div *ngIf="alias === 'viszontelado'" class="file col-xs-12 col-sm-12 col-md-12">
						<small *ngIf="szavak">(kizárólag <b>PDF formátumban</b> tölthető fel!)</small>
						<div class="cont">
							<input type="file" formControlName="szall_1_mukodes" (change)="onFileChange($event)" />
							<label *ngIf="!regform.controls.szall_1_mukodes_src.value">{{szavak[16].cim}}*</label>
							<label *ngIf="regform.controls.szall_1_mukodes_src.value">{{regform.controls.szall_1_mukodes_src.value.name}}</label>
							<span class="up">{{szavak[17].cim}}</span>
						</div>
					</div>
				</div>
				<h3 *ngIf="szavak" [class.open]="szall_2_show === true" (click)="openCim(2)">
					2. {{szavak[24].cim}}
				</h3>
				<div class="szall_cim_cont" >
					<div class="form-group col-xs-12 col-sm-12 col-md-12">
						<input *ngIf="szavak" type="text" formControlName="szall_2_cegnev"  placeholder="{{szavak[5].cim}}"/>
						<label *ngIf="szavak && regform.controls.szall_2_cegnev.value != ''">{{szavak[5].cim}}</label>
					</div>
					<div class="cim form-group col-xs-12 col-sm-12 col-md-12">
						<div class="form-group col-xs-12 col-sm-4 col-md-4">
							<input *ngIf="szavak" type="text" formControlName="szall_2_irsz"  mask="0000" class="irsz_input" placeholder="{{szavak[19].cim}}"/>
							<label *ngIf="szavak && regform.controls.szall_2_irsz.value != ''">{{szavak[19].cim}}</label>
						</div>
						<div class="form-group col-xs-12 col-sm-8 col-md-8">
							<input *ngIf="szavak" type="text" formControlName="szall_2_varos"  placeholder="{{szavak[20].cim}}"/>
							<label *ngIf="szavak && regform.controls.szall_2_varos.value != ''">{{szavak[20].cim}}</label>
						</div>
					</div>
					<div class="cim form-group col-xs-12 col-sm-12 col-md-12">
						<input *ngIf="szavak" type="text" formControlName="szall_2_utca"  placeholder="{{szavak[21].cim}}"/>
						<label *ngIf="szavak && regform.controls.szall_2_utca.value != ''">{{szavak[21].cim}}</label>
					</div>
					<div *ngIf="alias === 'viszontelado'" class="file col-xs-12 col-sm-12 col-md-12">
						<small *ngIf="szavak">(kizárólag <b>PDF formátumban</b> tölthető fel!)</small>
						<div class="cont">
							<input type="file" formControlName="szall_2_mukodes" (change)="onFileChange($event)" />
							<label *ngIf="!regform.controls.szall_2_mukodes_src.value">{{szavak[16].cim}}*</label>
							<label *ngIf="regform.controls.szall_2_mukodes_src.value">{{regform.controls.szall_2_mukodes_src.value.name}}</label>
							<span class="up">{{szavak[17].cim}}</span>
						</div>
					</div>
				</div>
				<h3 *ngIf="szavak" [class.open]="szall_3_show === true" (click)="openCim(3)">
					3. {{szavak[24].cim}}
				</h3>
				<div class="szall_cim_cont" >
					<div class="form-group col-xs-12 col-sm-12 col-md-12">
						<input *ngIf="szavak" type="text" formControlName="szall_3_cegnev"  placeholder="{{szavak[5].cim}}"/>
						<label *ngIf="szavak && regform.controls.szall_3_cegnev.value != ''">{{szavak[5].cim}}</label>
					</div>
					<div class="cim form-group col-xs-12 col-sm-12 col-md-12">
						<div class="form-group col-xs-12 col-sm-4 col-md-4">
							<input *ngIf="szavak" type="text" formControlName="szall_3_irsz"  mask="0000" class="irsz_input" placeholder="{{szavak[19].cim}}"/>
							<label *ngIf="szavak && regform.controls.szall_3_irsz.value != ''">{{szavak[19].cim}}</label>
						</div>
						<div class="form-group col-xs-12 col-sm-8 col-md-8">
							<input *ngIf="szavak" type="text" formControlName="szall_3_varos"  placeholder="{{szavak[20].cim}}"/>
							<label *ngIf="szavak && regform.controls.szall_3_varos.value != ''">{{szavak[20].cim}}</label>
						</div>
					</div>
					<div class="cim form-group col-xs-12 col-sm-12 col-md-12">

						<input *ngIf="szavak" type="text" formControlName="szall_3_utca"  placeholder="{{szavak[21].cim}}"/>
						<label *ngIf="szavak && regform.controls.szall_3_utca.value != ''">{{szavak[21].cim}}</label>
					</div>
					<div *ngIf="alias === 'viszontelado'" class="file col-xs-12 col-sm-12 col-md-12">
						<small *ngIf="szavak">(kizárólag <b>PDF formátumban</b> tölthető fel!)</small>
						<div class="cont">
							<input type="file" formControlName="szall_3_mukodes" (change)="onFileChange($event)" />
							<label *ngIf="!regform.controls.szall_3_mukodes_src.value">{{szavak[16].cim}}*</label>
							<label *ngIf="regform.controls.szall_3_mukodes_src.value">{{regform.controls.szall_3_mukodes_src.value.name}}</label>
							<span class="up">{{szavak[17].cim}}</span>
						</div>
					</div>
				</div>
				<h3 *ngIf="szavak" [class.open]="szall_4_show === true" (click)="openCim(4)">
					4. {{szavak[24].cim}}
				</h3>
				<div class="szall_cim_cont" >
					<div class="form-group col-xs-12 col-sm-12 col-md-12">
						<input *ngIf="szavak" type="text" formControlName="szall_4_cegnev"  placeholder="{{szavak[5].cim}}"/>
						<label *ngIf="szavak && regform.controls.szall_4_cegnev.value != ''">{{szavak[5].cim}}</label>
					</div>
					<div class="cim form-group col-xs-12 col-sm-12 col-md-12">
						<div class="form-group col-xs-12 col-sm-4 col-md-4">
							<input *ngIf="szavak" type="text" formControlName="szall_4_irsz" mask="0000" class="irsz_input" placeholder="{{szavak[19].cim}}"/>
							<label *ngIf="szavak && regform.controls.szall_4_irsz.value != ''">{{szavak[19].cim}}</label>
						</div>
						<div class="form-group col-xs-12 col-sm-8 col-md-8">
							<input *ngIf="szavak" type="text" formControlName="szall_4_varos"  placeholder="{{szavak[20].cim}}"/>
							<label *ngIf="szavak && regform.controls.szall_4_varos.value != ''">{{szavak[20].cim}}</label>
						</div>
					</div>
					<div class="cim form-group col-xs-12 col-sm-12 col-md-12">
						<input *ngIf="szavak" type="text" formControlName="szall_4_utca"  placeholder="{{szavak[21].cim}}"/>
						<label *ngIf="szavak && regform.controls.szall_4_utca.value != ''">{{szavak[21].cim}}</label>
					</div>
					<div *ngIf="alias === 'viszontelado'" class="file col-xs-12 col-sm-12 col-md-12">
						<small *ngIf="szavak">(kizárólag <b>PDF formátumban</b> tölthető fel!)</small>
						<div class="cont">
							<input type="file" formControlName="szall_4_mukodes" (change)="onFileChange($event)" />
							<label *ngIf="!regform.controls.szall_4_mukodes_src.value">{{szavak[16].cim}}*</label>
							<label *ngIf="regform.controls.szall_4_mukodes_src.value">{{regform.controls.szall_4_mukodes_src.value.name}}</label>
							<span class="up">{{szavak[17].cim}}</span>
						</div>
					</div>
				</div>
				<h3 *ngIf="szavak" [class.open]="szall_5_show === true" (click)="openCim(5)">
					5. {{szavak[24].cim}}
				</h3>
				<div class="szall_cim_cont" >
					<div class="form-group col-xs-12 col-sm-12 col-md-12">
						<input *ngIf="szavak" type="text" formControlName="szall_5_cegnev"  placeholder="{{szavak[5].cim}}"/>
						<label *ngIf="szavak && regform.controls.szall_5_cegnev.value != ''">{{szavak[5].cim}}</label>
					</div>
					<div class="cim form-group col-xs-12 col-sm-12 col-md-12">
						<div class="form-group col-xs-12 col-sm-4 col-md-4">
							<input *ngIf="szavak" type="text" formControlName="szall_5_irsz" mask="0000" class="irsz_input" placeholder="{{szavak[19].cim}}"/>
							<label *ngIf="szavak && regform.controls.szall_5_irsz.value != ''">{{szavak[19].cim}}</label>
						</div>
						<div class="form-group col-xs-12 col-sm-8 col-md-8">
							<input *ngIf="szavak" type="text" formControlName="szall_5_varos"  placeholder="{{szavak[20].cim}}"/>
							<label *ngIf="szavak && regform.controls.szall_5_varos.value != ''">{{szavak[20].cim}}</label>
						</div>
					</div>
					<div class="cim form-group col-xs-12 col-sm-12 col-md-12">
						<input *ngIf="szavak" type="text" formControlName="szall_5_utca"  placeholder="{{szavak[21].cim}}"/>
						<label *ngIf="szavak && regform.controls.szall_5_utca.value != ''">{{szavak[21].cim}}</label>
					</div>
					<div *ngIf="alias === 'viszontelado'" class="file col-xs-12 col-sm-12 col-md-12">
						<small *ngIf="szavak">(kizárólag <b>PDF formátumban</b> tölthető fel!)</small>
						<div class="cont">
							<input type="file" formControlName="szall_5_mukodes" (change)="onFileChange($event)" />
							<label *ngIf="!regform.controls.szall_5_mukodes_src.value">{{szavak[16].cim}}*</label>
							<label *ngIf="regform.controls.szall_5_mukodes_src.value">{{regform.controls.szall_5_mukodes_src.value.name}}</label>
							<span class="up">{{szavak[17].cim}}</span>
						</div>
					</div>
				</div>
			</div>
			<br/>
			<div class="aszf_group">
				<div class="checkbox col-xs-12 col-sm-12 col-md-12">
					<div class="check">
						<input required type="checkbox" [ngClass]="{'invalidated': submitted}" required formControlName="any" class="radio" value="1">
						<span class="icon-check-1"></span>
					</div>
					<label *ngIf="szavak && adat_ny" class="label-text">{{szavak[25].cim}} <a target="_blank" routerLink="/{{adat_ny.alias}}">{{adat_ny.title}}ot*</a></label>
				</div>
				<div class="checkbox col-xs-12 col-sm-12 col-md-12">
					<div class="check">
						<input required type="checkbox" [ngClass]="{'invalidated': submitted}" required formControlName="aszf" class="radio" value="1">
						<span class="icon-check-1"></span>
					</div>
					<label *ngIf="szavak && aszf" class="label-text">{{szavak[25].cim}} <a target="_blank" routerLink="/{{aszf.alias}}">{{aszf.title}}et*</a></label>
				</div>
				<div class="checkbox col-xs-12 col-sm-12 col-md-12">
					<div class="check">
						<input type="checkbox" [ngClass]="{'invalidated': submitted}" formControlName="hozzaj" class="radio" value="1">
						<span class="icon-check-1"></span>
					</div>
					<dynamic-html *ngIf="hozza" class="label-text hozza_text" [content]="hozza.text"></dynamic-html>
				</div>
			</div>
			<button type="submit" name="kapcs_kuld" *ngIf="szavak && loading == false" class="kapcs_kuld" value="{{szavak[25].cim}}"><span>{{szavak[26].cim}}</span></button>
			<br/><br/><br/>
		</div>
	</div>
</form>
